import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ReactSVG } from 'react-svg';
import classNames from 'classnames';
import * as api from '../../helpers/api';
import { RegisterLayout } from '../../components/Layouts';
import Form from '../../components/Form';
import Input from '../../components/fields/Input';
import Select from '../../components/fields/Select';
import Switch from '../../components/fields/Switch';
import Button from '../../components/Button';
import DateInput from '../../components/fields/DateInput';
import BackButton from '../../components/Block/BackButton';
import { setItem } from '../../store/app/actions';
import { selectItem } from '../../store/app/getters';

import 'react-datepicker/dist/react-datepicker.css';
import styles from '../Account/account.module.css';

import { genders } from '../../data/mock';

const CreatePlayer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [ isSubmitActive, setSubmitActive ] = useState(false);

  const user = useSelector(selectItem(['user']));
  const gender = user?.gender;

  let locationOpts = [];
  if (user.location) {
    locationOpts.push({
      label: user.location,
      value: user.location
    });
  }

  const minDateBirth = new Date();
  minDateBirth.setFullYear(minDateBirth.getFullYear() - 13);

  const maxDateBirth = new Date();
  maxDateBirth.setFullYear(maxDateBirth.getFullYear() - 6);

  const mapMentoringStructure = (data) => {
    if (gender) {
      return data.for_sex[gender].map(({ name, uid }) => ({ label: name, value: uid }));
    } else {
      return Object.values(data.for_uid).map(({ name, uid }) => ({ label: name, value: uid }));
    }
  }

  const inputs = [
    { id: 'name', name: 'name', component: Input, validation: 'string',
      props: { title: "Данные ребенка", label: 'Имя ребенка', placeholder: 'Например, Иван', fullWidth: true }
    },
    { id: 'birth_date', name: 'birth_date', component: DateInput, validation: 'date',
      props: { type: 'date', /*title: 'Дата и время рождения',*/ label: 'Дата рождения', placeholder: '5 июня 2006 года',
        fullWidth: true, icon: <ReactSVG src="/images/icon-calendar.svg" />, minDate: minDateBirth, maxDate: maxDateBirth
      }
    },
    { id: 'location', name: 'location', component: Select, validation: 'select',
      props: {
        label: 'Город проживания', value: user.location, query: user.location, options: locationOpts,
        placeholder: 'Выберите город', fullWidth: true, isSearchable: true, searchPlaceholder: 'Например, "Новоуральск',
        loadUrl: '/getcity', mapper: (data) => data.map(({ city }) => ({ label: city, value: city }))
      }
    },
    { id: 'gender', name: 'gender', component: Switch, validation: 'select',
      props: { id: 'gender', title: 'Пол игрока', options: genders, autoFocus: true }
    },
    { id: 'relation', name: 'relation', component: Select, validation: 'select',
      props: {
        title: 'Вы для ребенка', placeholder: 'Выберите тип родства', fullWidth: true,
        loadUrl: `/getmentoringstructure`, mapper: (data) => mapMentoringStructure(data)
      }
    }
  ];

  const onFormSubmit = (data) => {
    api
      .get(`/createMentoringForChild/new_player_create/9999/`)
      .then(response => {
        const result = response.data.response;
        if (result.success && result.player) {
          saveData(result.player.uid, data);
          // if (result.user) dispatch(setItem(['user'], result.user));
          // navigate(`/create-player-success/${result.query_uid}`);
        } else {
          const error = response.data.error ? response.data.error : 'Неизвестная ошибка';
          dispatch(setItem(['notifications', 'updateProfile'], { type: 'error', title: 'Ошибка', content: error }));
        }
      })
      .catch(error => {
        dispatch(setItem(['notifications', 'request'], { type: 'error', content: 'Ошибка обработки запроса. Перезагрузите страницу и попробуйте позже' }));
      });
  };

  const saveData = (uid, data) => {
    if (!uid) return;

    const fd = new FormData();
    for (let key in data) {
      fd.append(`params[${key}]`, data[key]);
    }

    api
      .post(`/updateUserProfile/${uid}/`, fd)
      .then(response => {
        const result = response.data.response;
        if (result.success) {
          dispatch(setItem(['user', 'created_players', result.query_uid], result.query_uid));
          dispatch(setItem(['notifications', 'updateProfile'], { type: 'success', content: 'Игрок добавлен!' }));
          navigate('/create');
        } else {
          const error = response.data.error ? response.data.error : 'Неизвестная ошибка';
          dispatch(setItem(['notifications', 'updateProfile'], { type: 'error', title: 'Ошибка', content: error }));
        }
      })
      .catch(error => {
        dispatch(setItem(['notifications', 'request'], { type: 'error', content: 'Ошибка обработки запроса. Перезагрузите страницу и попробуйте позже' }));
      });
  }

  const buttons = (
    <Button
      type="submit"
      fullWidth={true}
      butStyle="primary"
      disabled={!isSubmitActive}
      size="small"
    >
      Далее
    </Button>
  );

  return (
    <RegisterLayout>
      <h1 className={classNames(styles.title, styles.withButton)}> <BackButton /> Карточка ребёнка</h1>
      <div className={styles.content}>
        <Form
          inputs={inputs}
          onSubmit={onFormSubmit}
          onValidChange={setSubmitActive}
          buttons={buttons}
        />
      </div>
    </RegisterLayout>
  );
}

export default CreatePlayer;
