import { useDispatch, useSelector } from 'react-redux';
import { ReactSVG } from 'react-svg';
import * as api from '../../helpers/api';
import Block from '../../components/Block';
import { MainLayout } from '../../components/Layouts';
import Form from '../../components/Form';
import { PlayersSelect } from '../../components/UsersSelect';
import Input from '../../components/fields/Input';
import Select from '../../components/fields/Select';
import Switch from '../../components/fields/Switch';
import DateInput from '../../components/fields/DateInput';
// import ButtonsContainer from '../../components/ButtonsContainer';
import Button from '../../components/Button';
import UserHeader from '../../components/UserHeader';
import { selectItem } from '../../store/app/getters';
import { setItem } from '../../store/app/actions';
import standartPhone from '../../helpers/standartPhone';

import styles from './mentor.module.css';

import { genders } from '../../data/mock';

// const tariff = '0';
// const tgBotUrl = process.env.REACT_APP_TG_BOT_URL;

const Update = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectItem(['user']));
  // const user_agent = useSelector(selectItem(['settings', 'user_agent']));

  const minDateBirth = new Date();
  minDateBirth.setFullYear(minDateBirth.getFullYear() - 90);

  const maxDateBirth = new Date();
  maxDateBirth.setFullYear(maxDateBirth.getFullYear() - 18);

  let locationOpts = [];
  if (user.location) {
    locationOpts.push({
      label: user.location,
      value: user.location
    });
  }

  const inputs = [
    { id: 'phone', name: 'phone', component: Input,
      props: { label: 'Номер телефона', value: standartPhone(user.phone), placeholder: '+7', fullWidth: true,
        mask: '+7 ### ### ## ##', disabled: true
      }
    },
    { id: 'name', name: 'name', component: Input, validation: 'string',
      props: { label: 'Ваше имя', value: user.name || '', placeholder: 'Например, Иван', fullWidth: true }
    },
    { id: 'surname', name: 'surname', component: Input, validation: 'string',
      props: { label: 'Ваша фамилия', value: user.surname || '', placeholder: 'Например, Иванов', fullWidth: true }
    },
    { id: 'gender', name: 'gender', component: Switch, validation: 'select',
      props: { id: 'gender', label: 'Пол', options: genders, value: user.gender || '' }
    },
    { id: 'location', name: 'location', component: Select, validation: 'select',
      props: {
        label: 'Город проживания', placeholder: 'Выберите город', value: user.location, query: user.location, options: locationOpts,
        searchPlaceholder: 'Например, "Новоуральск"', fullWidth: true, isSearchable: true,
        loadUrl: '/getcity', mapper: (data) => data.map(({ city }) => ({ label: city, value: city }))
      }
    },
    { id: 'birth_date', name: 'birth_date', component: DateInput,
      props: { type: 'date', label: 'Дата рождения', value: user.birth_date || '', placeholder: '5 июня 1990 года',
        fullWidth: true, icon: <ReactSVG src="/images/icon-calendar.svg" />, minDate: minDateBirth, maxDate: maxDateBirth
      }
    },
    /* { id: 'birth_time', name: 'birth_time', component: Input,
      props: { type: 'time', label: 'Время рождения', placeholder: 'Например, 12:30', fullWidth: true, value: user.birth_time || '',
        pattern: '[0-9]{2}:[09]{2}', icon: <ReactSVG src="/images/icon-time.svg" />
      }
    }, */
  ];

  const onSubmit = data => {
    const fd = new FormData();
    for (let key in data) {
      if (key !== 'phone') fd.append(`params[${key}]`, data[key]);
    }

    api
      .post(`/updateUserProfile/${user.uid}/`, fd)
      .then(response => {
        const result = response.data.response;
        if (result.success) {
          const data = {...result.user};
          delete(data.player);
          dispatch(setItem(['user'], data));
          dispatch(setItem(['notifications', 'updateUser'], { type: 'success', content: 'Изменения сохранены' }));
        } else {
          dispatch(setItem(['notifications', 'updateUser'], { type: 'error', content: result.error }));
        }
      })
      .catch(error => {
        dispatch(setItem(['notifications', 'request'], { type: 'error', content: 'Ошибка обработки запроса. Перезагрузите страницу и попробуйте позже' }));
      });
  };

  const buttons = (
    <Button
      type="submit"
      fullWidth={true}
      butStyle="primary"
    >
      Сохранить
    </Button>
  );

  // const setShowReview = (open) => {
  //   dispatch(setItem(['global', 'feedback_popup', 'open'], open));
  // };

  return (
    <MainLayout>
      <Block
        title="Настройки"
        headerAlign="center"
        showBackButton={true}
        infoSlug="settings"
      >
        <UserHeader user={user} showEdit={true} />
        <PlayersSelect users={user.player} current={user.current_player?.uid_gamer} />

        {/* <ButtonsContainer>
          {user.current_player &&
            <Button
              href="/invited"
              butStyle="secondary"
              fullWidth={true}
            >
              Приглашенные игроком
            </Button>
          }
          {user_agent !== 'skillary-mobile' && (
            user.tg_key ? (
              <Button
                butStyle="primary"
                fullWidth={true}
                disabled={true}
              >
                Telegram-бот подключен
              </Button>
            ) : (
              <>
                <Button
                  href={`${tgBotUrl}${user.self_key}`}
                  butStyle="primary"
                  fullWidth={true}
                  target="_blank"
                >
                  Подключить Telegram бота
                </Button>
                <div className={styles.buttonsNotice}>Подключите Telegram-бота для получения важных уведомлений</div>
              </>
            )
          )}

          <Button
            fullWidth={true}
            butStyle="secondary"
            onClick={() => setShowReview(true)}
          >
            Оставить отзыв на сервис
          </Button>
        </ButtonsContainer> */}
      </Block>

      <h2 className={styles.title}>Данные наставника</h2>
      <Form inputs={inputs} onSubmit={onSubmit} buttons={buttons} inputClass="dark" />

      <div style={{ marginBottom: '40px' }} />
    </MainLayout>
  );
}

export default Update;
