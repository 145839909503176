import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { MainLayout } from '../../../components/Layouts';
import Block from '../../../components/Block';
import ButtonsContainer from '../../../components/ButtonsContainer';
import Button from '../../../components/Button';
import Modal from '../../../components/Modal';
import Checkbox from '../../../components/fields/Checkbox';
import Switch from '../../../components/fields/Switch';
import useApiQuery from '../../../hooks/useApiQuery';
import * as api from '../../../helpers/api';
import { selectItem } from '../../../store/app/getters';
import { setItem } from '../../../store/app/actions';

import styles from './Rewards.module.css';

const durations = [
  { label: 'Неделя', value: 'W' },
  { label: 'Месяц', value: 'M' },
  { label: '3 месяца', value: 'Q' },
  { label: 'Год', value: 'Y' },
];

const defaultDuration = 'W';

const CustomReward = ({ uid, onSave }) => {
  const [ isShowCustom, setShowCustom ] = useState(false);
  const [ value, setValue ] = useState(null);

  return (
    <>
      <ButtonsContainer className={styles.buttons} key={uid}>
        <Button
          fullWidth={true}
          butStyle="secondary"
          size="small"
          onClick={() => setShowCustom(true)}
        >
          Своя награда
        </Button>

        <Modal
          title="Своя награда"
          viewStyle="bottom"
          open={isShowCustom}
          onClose={() => setShowCustom(false)}
        >
          <textarea
            id="reward_name"
            name="reward_name"
            rows="4"
            placeholder="Введите название награды"
            maxLength="255"
            onChange={e => setValue(e.target.value)}
          />

          <ButtonsContainer>
            <Button
              butStyle="primary"
              fullWidth={true}
              onClick={() => {
                onSave(value);
                setShowCustom(false);
              }}
            >
              Сохранить
            </Button>
          </ButtonsContainer>
        </Modal>
      </ButtonsContainer>
    </>
  );
};

const RewardCheckbox = ({ uid, name, rewards, onChange, mentorUid, playerUid, checked }) => {
  let selected = null;
  const names = rewards ? Object.values(rewards).map(item => {
    const { mentor_uid, mentor_name, player_uid, is_checked } = item;

    if (is_checked && Number(mentor_uid) === Number(mentorUid) && Number(player_uid) === Number(playerUid))
      selected = item;

    if (is_checked && !(Number(mentor_uid) === Number(mentorUid) && Number(player_uid) === Number(playerUid)))
      return `выбрал ${mentor_name}`;
    else return null
  }) : [];

  return (
    <div className={styles.row} key={uid}>
      <Checkbox
        id={uid}
        name={uid}
        label={<>{name} {names.length ? <div className={styles.selected}>{names.join(', ')}</div> : null}</>}
        checked={checked || !!selected}
        onChange={e => {
          if (selected) onChange(selected.uid, selected.reward_name, e.target.checked);
          else onChange(uid, name, e.target.checked);
        }}
      />
    </div>
  );
};

const UpdateRewards = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectItem(['user']));
  const currentPlayer = user.current_player;
  const playerUid = currentPlayer?.uid_gamer;

  const [ duration, setDuration ] = useState(defaultDuration);

  const { data: rewards } = useApiQuery({
    name: ['rewards', playerUid],
    path: `/getMentorRewards/${playerUid}/`
  });

  const handleChangeReward = (reward_uid, name, checked) => {
    const fd = new FormData();

    fd.append('update[reward_uid]', reward_uid);
    fd.append('update[reward_name]', name);
    fd.append('update[mentor_uid]', user.uid);
    fd.append('update[player_uid]', playerUid);

    if (!checked) fd.append('update[delete_reward]', reward_uid);

    api
      .post(`/updateMentorRewards/`, fd)
      .then(response => {
        const result = response.data.response;

        if (result) dispatch(setItem(['rewards', playerUid], result, { isHard: true }));
        if (response.data.error)
          dispatch(setItem(['notifications', 'updateProfile'], { type: 'error', content: response.data.error }));
      })
      .catch(error => {
        dispatch(setItem(['notifications', 'request'], { type: 'error', content: 'Ошибка обработки запроса. Перезагрузите страницу и попробуйте позже' }));
      });
  };

  return (
    <MainLayout>
      <Block title="Конструктор наград" headerAlign="center" showBackButton={true} infoSlug="rewards_m_f_p">
        <div className={styles.rewardHeader}>
          <Switch id="duration" name="duration" options={durations} value={duration} onChange={e => setDuration(e.target.value)} />
        </div>

        {rewards &&
          Object.values(rewards[duration]).map(({ uid, name, rewards, is_custom }) => {
            if (is_custom) {
              return (
                (rewards && Object.values(rewards).length) ? (
                  Object.values(rewards).map(({ uid, reward_name, is_checked }) => (
                    <RewardCheckbox
                      uid={uid}
                      name={reward_name}
                      playerUid={playerUid}
                      mentorUid={user.uid}
                      checked={is_checked}
                      onChange={(uid, value, checked) => handleChangeReward(uid, value, checked)}
                      key={uid}
                    />
                  ))
                ) : null
              );
            }

            return (
              <RewardCheckbox
                uid={uid}
                name={name}
                rewards={rewards}
                playerUid={playerUid}
                mentorUid={user.uid}
                onChange={(uid, value, checked) => handleChangeReward(uid, value, checked)}
                key={uid}
              />
            );
          })
        }

        {rewards &&
          Object.values(rewards[duration]).map(({ uid, is_custom }) => {
            return is_custom ? (
              <CustomReward
                uid={uid}
                onSave={value => handleChangeReward(uid, value, true)}
                key={uid}
              />
            ) : null;
          })
        }
      </Block>
    </MainLayout>
  );
};

export default UpdateRewards;
