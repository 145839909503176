import classnames from 'classnames';
import { ReactSVG } from 'react-svg';
import { Link } from 'react-router-dom';
import BalanceItem from '../BalanceItem';

import styles from '../balance.module.css';

const Rewards = ({ user, items }) => {
  if (!items) return (
    <div className={classnames(styles.item, 'skeleton-loader')} />
  );

  return (
    <BalanceItem>
      {(user && user.type === 'M') &&
        <Link className={styles.settings} to={'/rewards'}>
          <ReactSVG src="/images/icon-settings.svg" className={styles.settingsIcon} wrapper="span" />
        </Link>
      }
      <div className={classnames(styles.row, styles.title)}>
        Награды
      </div>
      <div className={styles.row}>
        Недельные <span className={classnames(styles.value, styles.valueCircle)}>{items.filter(({ type }) => type === 'W').length}</span>
      </div>
      <div className={styles.row}>
        Месячные <span className={classnames(styles.value, styles.valueCircle)}>{items.filter(({ type }) => type === 'M').length}</span>
      </div>
      <div className={styles.row}>
        Квартальные <span className={classnames(styles.value, styles.valueCircle)}>{items.filter(({ type }) => type === 'Q').length}</span>
      </div>
      <div className={styles.row}>
        Годовые <span className={classnames(styles.value, styles.valueCircle)}>{items.filter(({ type }) => type === 'Y').length}</span>
      </div>
    </BalanceItem>
  );
};

export default Rewards;
