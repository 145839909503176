import { useSelector } from 'react-redux';
import useApiQuery from '../../hooks/useApiQuery';
import { MainLayout } from '../../components/Layouts';
import Block from '../../components/Block';
import Tabs from '../../components/Tabs';
import QuestsList from '../../components/quests/QuestsList';
import { selectItem } from '../../store/app/getters';

import { user_quest_types } from '../../data/mock';

const UserQuestsList = () => {
  const user = useSelector(selectItem(['user']));

  const { data: quests } = useApiQuery({
    name: ['user_quests'],
    path: `/getUserQuest/${user.uid}/`,
    skip: !user.uid,
    mapper: (data = {}, prev) => {
      let next = {};

      for (let key in data) {
        next[key] = (prev && prev[key]) ? {...prev[key]} : {};

        if (data[key] && data[key].length) {
          for (let questKey in data[key]) {
            const quest = data[key][questKey];
            next[key][quest.uid] = quest;
          }
        }
      }

      return next;
    }
  });

  return (
    <MainLayout>
      <Block title="Квесты" showBackButton={true} headerAlign="center">
        <Tabs>
          <div label={user_quest_types.active.name} key="active">
            <QuestsList quests={quests && quests.active && Object.values(quests.active).reverse()} emptyText="Квесты не найдены" type="simple" showSign={true} />
          </div>
          <div label={user_quest_types.complete.name} key="complete">
            <QuestsList quests={quests && quests.complete && Object.values(quests.complete).reverse()} emptyText="Квесты не найдены" type="complete" showRate={true} />
          </div>
          <div label={user_quest_types.cancel.name} key="cancel">
            <QuestsList quests={quests && quests.cancel && Object.values(quests.cancel).reverse()} emptyText="Квесты не найдены" type="cancelled" />
          </div>
        </Tabs>
      </Block>
    </MainLayout>
  );
};

export default UserQuestsList;
