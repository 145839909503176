import { useState } from 'react';
import { useSelector } from 'react-redux';
import useApiQuery from '../../../hooks/useApiQuery';
import { MainLayout } from '../../../components/Layouts';
import Block from '../../../components/Block';
import Switch from '../../../components/fields/Switch';
import { Ambitions } from '../../../components/Skills';
import { selectItem } from '../../../store/app/getters';

import { ambitionsTabs } from '../../../data/mock';

const AmbitionsPage = () => {
  const [ type, setType ] = useState(ambitionsTabs[0].value);
  const user = useSelector(selectItem(['user']));

  const { data: ambitions } = useApiQuery({
    name: ['user_ambitions'],
    path: `/getPlayerAmbitions/${user.uid}/`,
    skip: !user || !user.uid,
    mapper: (data = {}, prev) => {
      let next = {};

      for (let key in data) {
        next[key] = (prev && prev[key]) ? {...prev[key]} : {};

        if (data[key] && data[key].length) {
          for (let ambKey in data[key]) {
            const amb = data[key][ambKey];
            next[key][amb.skill_uid] = amb;
          }
        }
      }

      return next;
    }
  });

  if (!user) return null;

  const onChangeTab = (e) => {
    setType(e.target.value);
  };

  return (
    <MainLayout>
      <Block title="Мои цели" headerAlign="center" showBackButton={true} infoSlug="ambitions">
        {/* <ButtonsContainer>
          <Button
            fullWidth={true}
            butStyle="primary"
            to="/create-ambition"
          >
            Поставить новую цель
          </Button>
        </ButtonsContainer> */}

        <Switch
          id="type"
          name="type"
          options={ambitionsTabs}
          value={type}
          view="radio"
          onChange={(e) => onChangeTab(e)}
        />
      </Block>
      {ambitions &&
        <Block>
          <Ambitions items={Object.values(ambitions[type])} showDivider={true} />
        </Block>
      }
    </MainLayout>
  );
};

export default AmbitionsPage;
