import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import * as api from '../../helpers/api';
import { RegisterLayout } from '../../components/Layouts';
import Button from '../../components/Button';
import ButtonsContainer from '../../components/ButtonsContainer';
import { selectItem } from '../../store/app/getters';
import { setItem } from '../../store/app/actions';

import styles from './account.module.css';

import { types } from '../../data/mock';

const SelectType = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectItem(['user']));
  const [ accountType, setAccountType ] = useState(null);

  const onSubmit = data => {
    const fd = new FormData();
    fd.append(`params[type]`, accountType);

    api
      .post(`/updateUserProfile/${user.uid}/`, fd)
      .then(response => {
        const result = response.data.response;
        if (result.success) {
          dispatch(setItem(['user'], result.user));
        } else {
          dispatch(setItem(['notifications', 'updateUser'], { type: 'error', content: result.error }));
        }
      })
      .catch(error => {
        dispatch(setItem(['notifications', 'updateUser'], { type: 'error', content: 'Ошибка обработки запроса. Перезагрузите страницу и попробуйте позже' }));
      });
  };

  return (
    <RegisterLayout>
      <h2 className={styles.title}>Регистрация</h2>
      <div className={styles.subtitle}>Выберите тип аккаунта</div>
      <div className={styles.subtitle}>Выбор нельзя будет изменить!</div>
      <div className={styles.content}>
        <div className={styles.accountTypes}>
          {types.map(({ label, value }) => (
            <button
              className={classNames(styles.accountTypesButton, accountType === value && styles.active)}
              onClick={() => setAccountType(value)}
              style={{ backgroundImage: `url(/images/account-bg-${value}.svg)` }}
            >
              <span dangerouslySetInnerHTML={{ __html: label }} />
            </button>
          ))}
        </div>
        <ButtonsContainer>
          <Button
            fullWidth={true}
            butStyle="primary"
            disabled={!accountType}
            onClick={onSubmit}
          >
            Далее
          </Button>
        </ButtonsContainer>
      </div>
    </RegisterLayout>
  );
}

export default SelectType;
