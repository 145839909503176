import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, Navigate } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import * as api from '../../helpers/api';
import useApiQuery from '../../hooks/useApiQuery';
import { MainLayout } from '../../components/Layouts';
import Form from '../../components/Form';
import Input from '../../components/fields/Input';
import Select from '../../components/fields/Select';
import Switch from '../../components/fields/Switch';
import DateInput from '../../components/fields/DateInput';
import Button from '../../components/Button';
import UserHeader from '../../components/UserHeader';
import { setItem } from '../../store/app/actions';
import { selectItem } from '../../store/app/getters';
import standartPhone from '../../helpers/standartPhone';

import { genders, grades, letters } from '../../data/mock';
import { filterByKey } from '../../helpers/keyBy';
import Block from '../../components/Block';

const UpdatePlayer = () => {
  let { uid } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const mentor = useSelector(selectItem(['user']));
  const [ userLocation, setUserLocation ] = useState(null);
  const [ isSubmitActive, setSubmitActive ] = useState(false);

  if (!uid && mentor && mentor.current_player) uid = mentor.current_player.uid_gamer;

  const { data: player, status, error } = useApiQuery({
    name: ['players', uid],
    path: `/getUserProfile/${uid}/`,
    mapper: (data = {}, prev) => {
      return {...prev, ...data.main};
    }
  });

  useEffect(() => {
    if (!player || !mentor) return null;
    if (player.location) setUserLocation(player.location);

    // eslint-disable-next-line
  }, [player]);

  if (status === 404) return <Navigate replace to="/404" />;
  if (error) return <Navigate replace to="/update" />;

  const minDateBirth = new Date();
  minDateBirth.setFullYear(minDateBirth.getFullYear() - 13);

  const maxDateBirth = new Date();
  maxDateBirth.setFullYear(maxDateBirth.getFullYear() - 6);

  // let birthLocationOpts = [];
  // if (player.birth_location) {
  //   birthLocationOpts.push({
  //     label: player.birth_location,
  //     value: player.birth_location
  //   });
  // }

  let locationOpts = [];
  if (player && player.location) {
    locationOpts.push({
      label: player.location,
      value: player.location
    });
  }

  let selectedSchool = [];
  if (player && player.school && player.school_name) {
    selectedSchool.push({
      label: player.school_name,
      value: player.school
    });
  }

  if (!uid) return null;

  const _player = filterByKey(mentor.player, 'uid_gamer', uid);
  let relationUid = null;
  if (_player.length && _player[0].relation) relationUid = _player[0].relation.uid;

  const inputs = [
    { id: 'phone', name: 'phone', component: Input,
      props: { label: 'Номер телефона', value: player && standartPhone(player.phone), placeholder: '+7', fullWidth: true,
        mask: '+7 ### ### ## ##'
      }
    },
    { id: 'nickname', name: 'nickname', component: Input,
      props: { label: 'Никнейм', placeholder: 'Например, Ivanov', fullWidth: true,
        value: (player && player.nickname && player.nickname !== 'undefined') ? player.nickname : ''
      }
    },
    { id: 'gender', name: 'gender', component: Switch, validation: 'select',
      props: { id: 'gender', label: 'Пол', options: genders, value: (player && player.gender) || '' }
    },
    { id: 'school', name: 'school', component: Select, validation: 'select',
      props: {
        label: 'Номер школы', placeholder: 'Выберите школу', fullWidth: true, isSearchable: true,
        value: (player && player.school) || '', options: selectedSchool,
        loadUrl: `/getschool/${userLocation}`, mapper: (data) => data.map(({ name, uid }) => ({ label: name, value: uid })),
        customParams: {
          user_uid: uid,
          city: player && player.location,
          buttonUrl: '/newschool/',
          buttonText: 'Школы нет в списке',
          inputText: 'Введите название школы',
        }
      }
    },
    { id: 'class', name: 'class', component: Select, validation: 'select',
      props: { label: 'Класс', placeholder: '1', options: grades, value: (player && player.class) || '' }
    },
    { id: 'class_sign', name: 'class_sign', component: Select, validation: 'select',
      props: { placeholder: 'А', options: letters, value: (player && player.class_sign) || '' }
    },
    { id: 'birth_date', name: 'birth_date', component: DateInput,
      props: { type: 'date', label: 'Дата рождения', value: (player && player.birth_date) || '', placeholder: '5 июня 1990 года',
        fullWidth: true, icon: <ReactSVG src="/images/icon-calendar.svg" />, minDate: minDateBirth, maxDate: maxDateBirth
      }
    },
    /* { id: 'birth_time', name: 'birth_time', component: Input,
      props: { type: 'time', label: 'Время рождения', placeholder: 'Например, 12:30', fullWidth: true, value: player.birth_time || '',
        pattern: '[0-9]{2}:[09]{2}', icon: <ReactSVG src="/images/icon-time.svg" />
      }
    }, */
    /* { id: 'birth_location', name: 'birth_location', component: Select, validation: 'select',
      props: {
        label: 'Место рождения', placeholder: 'Выберите город', value: player.birth_location, query: player.birth_location, options: birthLocationOpts,
        searchPlaceholder: 'Сочи', fullWidth: true, isSearchable: true,
        loadUrl: '/getcity', mapper: (data) => data.map(({ city }) => ({ label: city, value: city }))
      }
    }, */
    { id: 'location', name: 'location', component: Select, validation: 'select',
      props: {
        label: 'Город проживания', placeholder: 'Выберите город', value: player && player.location, query: player && player.location, options: locationOpts,
        searchPlaceholder: 'Например, "Новоуральск"', fullWidth: true, isSearchable: true,
        loadUrl: '/getcity', mapper: (data) => data.map(({ city }) => ({ label: city, value: city }))
      }
    },
    { id: 'relation', name: 'relation', component: Select, validation: 'select',
      props: {
        label: 'Тип родства', placeholder: 'Выберите тип родства', fullWidth: true,
        value: relationUid || '', disabled: !relationUid,
        loadUrl: `/getmentoringstructure`, mapper: (data) => (data.for_sex[mentor.gender].map(item => ({ label: item.name, value: item.uid })))
      }
    },
  ];

  const onFormChange = (data) => {
    if (data.location) setUserLocation(data.location);
  };

  const onSubmit = data => {
    const fd = new FormData();
    for (let key in data) {
      if (key !== 'phone') fd.append(`params[${key}]`, data[key]);
    }

    api
      .post(`/updateUserProfile/${player.uid}/`, fd)
      .then(response => {
        const result = response.data.response;
        if (result.success) {
          let playerKey;
          for (let key in mentor.player) {
            if (String(mentor.player[key]['uid_gamer']) === String(player.uid)) playerKey = key;
          }

          if (playerKey) {
            dispatch(setItem(['user', 'player', playerKey, 'info'], data));
            dispatch(setItem(['players', player.uid], data));
            dispatch(setItem(['notifications', 'updateUser'], { type: 'success', content: 'Изменения сохранены' }));
            navigate(`/update-player/${player.uid}`);
          } else {
            dispatch(setItem(['notifications', 'updateUser'], { type: 'error', content: 'Ошибка. Обновите страницу и попробуйте снова' }));
          }
        } else {
          dispatch(setItem(['notifications', 'updateUser'], { type: 'error', content: result.error }));
        }
      })
      .catch(error => {
        dispatch(setItem(['notifications', 'request'], { type: 'error', content: 'Ошибка обработки запроса. Перезагрузите страницу и попробуйте позже' }));
      });
  };

  const buttons = (
    <>
      <Button
        type="submit"
        fullWidth={true}
        disabled={!isSubmitActive}
        butStyle="primary"
      >
        Сохранить
      </Button>
      <Button
        to={`/invite-mentor/${player && player.uid}`}
        fullWidth={true}
        butStyle="primary"
      >
        Добавить наставника Игроку
      </Button>
    </>
  );

  return (
    <MainLayout>
      <Block
        title="Профиль"
        headerAlign="center"
        showBackButton={true}
        infoSlug="profile"
      >
        <UserHeader user={player} />
      </Block>

      {player &&
        <Form inputs={inputs} onChange={onFormChange} onSubmit={onSubmit} buttons={buttons} inputClass="dark" onValidChange={setSubmitActive} />
      }
      <div style={{ marginBottom: '40px' }} />
    </MainLayout>
  );
};

export default UpdatePlayer;
