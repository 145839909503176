import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import classnames from 'classnames';
import TabBar from '../../TabBar';
import FeedbackPopup from '../../modals/FeedbackPopup';
import BottomFaq from '../../modals/BottomFaq';
import { selectItem } from '../../../store/app/getters';
import { player_menu, mentor_menu, mentor_player_menu } from '../../../data/menu';

import styles from './main-layout.module.css';

const MainLayout = ({ children, contentValign }) => {
  const [ isSticked, setSticked ] = useState(false);
  const user = useSelector(selectItem(['user']));
  const { pathname } = useLocation();

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleScroll = (e) => {
    const { scrollHeight, scrollTop } = document.documentElement;
    setSticked((scrollHeight - 100) < (scrollTop + window.innerHeight));
  };

  return (
    <div className={styles.container}>
      <div className={classnames(styles.content, 'container', contentValign && styles['align-' + contentValign])}>
        { children }
      </div>
      <div className={classnames(styles.bottom, isSticked && styles.sticked)}>
        {user.type === 'M' ? (
          <TabBar menu={(pathname === '/' || pathname === '/update' || pathname === '/faq') ? mentor_menu : mentor_player_menu} />
        ) : (
          <TabBar menu={player_menu} />
        )}
      </div>
      <FeedbackPopup />
      <BottomFaq />
    </div>
  );
};

export default MainLayout;