import { useState, useEffect, Fragment } from 'react';
import classNames from 'classnames';

import styles from './game-chat.module.css';

const Dots = () => {
  const [ dots, setDots ] = useState('.');

  useEffect(() => {
    const interval = setInterval(() => {
      setDots(prev => {
        if (prev.length === 3) return '.';
        else return prev + '.';
      });
    }, 400);

    return () => clearInterval(interval);
    //eslint-disable-next-line
  }, []);

  return dots;
}

const ChatMessage = ({ avatar, name, hidden, showDots, text, align }) => {
  return (
    <div className={classNames(styles.item, hidden && styles.hidden)}>
      {align === 'left' && <img src={avatar} className={styles.image} alt={name} />}
      <div className={classNames(styles.message, styles[align])}>
        {showDots ? <Dots /> : text}
      </div>
      {align === 'right' && <img src={avatar} className={styles.image} alt={name} />}
    </div>
  );
};

const ChatItem = ({ user, ava_emo, name, text, ask, hidden, showDots }) => {
  return (
    <Fragment>
      <ChatMessage
        avatar={ava_emo}
        hidden={hidden}
        name={name}
        showDots={showDots}
        align="right"
        text={text}
      />

      {(ask && ask.uid) && (
        <>
          <ChatMessage
            avatar={ava_emo}
            hidden={hidden}
            name={name}
            text={ask.text_ask}
            align="right"
          />

          {Object.values(ask).map(({ is_choisen, label, text, npc_ava_emo, npc_name }, index) => {
            if (!is_choisen) return null;
            return (
              <Fragment key={'answer_' + index}>
                <ChatMessage
                  avatar={user.avatar}
                  hidden={hidden}
                  name={user.name}
                  text={label}
                  align="left"
                />

                <ChatMessage
                  avatar={npc_ava_emo}
                  hidden={hidden}
                  name={npc_name}
                  text={text}
                  align="right"
                />
              </Fragment>
            );
          })}
        </>
      )}
    </Fragment>
  );
};

const GameChat = ({ user, chat, current: currentId, onAnswer }) => {
  const [ current, setCurrent ] = useState(0);

  const currentDialog = chat[currentId] && chat[currentId].dialog;

  useEffect(() => {
    window.scrollTo(0, 100000);

    if (currentDialog && current < currentDialog.length && !(
      currentDialog[current].ask && currentDialog[current].ask.uid && currentDialog[current].text
    )) {
      setTimeout(() => {
        setCurrent(current + 1);
      }, currentDialog[current].text.length * 35);
    }
    //eslint-disable-next-line
  }, [current, currentDialog]);

  if (!chat || !currentDialog) return null;

  return (
    <div className={styles.root}>
      <div className={styles.list}>
        {Object.keys(chat).map(key => (
          <Fragment key={key}>
            {chat[key].dialog.map((item, index) => {
              key = Number(key)
              if (currentId < key || (currentId === key && index > current)) return null;

              return (
                <ChatItem
                  {...item}
                  user={user}
                  hidden={currentId === key && index > current}
                  showDots={currentId === key && index === current && !(item.ask && item.ask.uid)}
                  key={index}
                />
              );
            })}
          </Fragment>
        ))}
      </div>
      {(currentDialog[current] && currentDialog[current].ask && currentDialog[current].ask.uid && !currentDialog[current].ask.user_variant) && (
        <div className={styles.buttons}>
          {currentDialog[current].ask.yes && (
            <button className={styles.button} onClick={() => onAnswer(currentId, current, currentDialog[current].ask.uid, 1)}>
              {currentDialog[current].ask.yes.label}
            </button>
          )}
          {currentDialog[current].ask.no && (
            <button className={styles.button} onClick={() => onAnswer(currentId, current, currentDialog[current].ask.uid, 2)}>
              {currentDialog[current].ask.no.label}
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default GameChat;
