import { useSelector } from 'react-redux';
import useApiQuery from '../../hooks/useApiQuery';
import { MainLayout } from '../../components/Layouts';
import HeaderPlayer from '../../components/header/HeaderPlayer';
import Block from '../../components/Block';
import QuestsList from '../../components/quests/QuestsList';
import ButtonsContainer from '../../components/ButtonsContainer';
import Button from '../../components/Button';
import Balance from '../../components/Balance';
// import TariffNotification from '../../components/tariffs/TariffNotification';
import HpStories from '../../components/homepage/HpStories';
// import Courses from '../../components/homepage/Courses';
// import UserCourses from '../../components/homepage/UserCourses';
// import Professions from '../../components/homepage/Professions';
import About from '../../components/homepage/About';
import { selectItem } from '../../store/app/getters';

import styles from './player.module.css';

const UserQuery = ({ uid }) => {
  useApiQuery({
    name: ['user_quests'],
    path: `/getUserQuest/${uid}/`,
    mapper: (data = {}, prev) => {
      let next = {};

      for (let key in data) {
        next[key] = (prev && prev[key]) ? {...prev[key]} : {};

        if (data[key] && data[key].length) {
          for (let questKey in data[key]) {
            const quest = data[key][questKey];
            next[key][quest.uid] = {...quest, position: key};
          }
        }
      }

      return next;
    }
  });

  useApiQuery({
    name: ['user_ambitions'],
    path: `/getPlayerAmbitions/${uid}/`,
    mapper: (data = {}, prev) => {
      let next = {};

      for (let key in data) {
        next[key] = (prev && prev[key]) ? {...prev[key]} : {};

        if (data[key] && data[key].length) {
          for (let ambKey in data[key]) {
            const amb = data[key][ambKey];
            next[key][amb.skill_uid] = amb;
          }
        }
      }

      return next;
    }
  });

  useApiQuery({
    name: ['grants'],
    path: `/getstepresult/${uid}/`
  });

  useApiQuery({
    name: ['quest_groups', 'S'],
    path: `/getquestgroup/S/0/${uid}/`
  });

  // useApiQuery({
  //   name: ['courses_groups', 'P'],
  //   path: `/getCoursesAll/${uid}/P/`
  // });

  // useApiQuery({
  //   name: ['courses_groups', 'T'],
  //   path: `/getCoursesAll/${uid}/T/`
  // });

  // useApiQuery({
  //   name: ['courses'],
  //   path: `/getCoursesAll/${uid}/`,
  //   mapper: (data = {}) => {
  //     const next = data.reduce((prev, current) => ({ ...prev , [current.uid]: current }), {});
  //     return next;
  //   }
  // });

  // useApiQuery({
  //   name: ['user_courses'],
  //   path: `/getUserCourses/${uid}/`,
  //   isHard: true,
  //   mapper: (data = {}) => {
  //     const next = data.reduce((prev, current) => {
  //       return { ...prev , [current.course_uid]: current };
  //       // return (current.length && current[0].course_uid) ? { ...prev , [current[0].course_uid]: course } : prev;
  //     }, {});
  //     return next;
  //   }
  // });

  useApiQuery({
    name: ['rewards_mentor', uid],
    path: `/playerrewardsfrommentor/${uid}/`,
    mapper: (data = {}) => {
      return Object.values(data).reduce((prev, current) => {
        if (current) return ([ ...prev, ...current ]);
        return prev;
      }, []);
    }
  });

  return null;
};

const Home = () => {
  const user = useSelector(selectItem(['user']));
  const user_quests = useSelector(selectItem(['user_quests']));
  const ambitions = useSelector(selectItem(['user_ambitions']));
  const grants = useSelector(selectItem(['grants']));
  const rewards_mentor = useSelector(selectItem(['rewards_mentor', user.uid]));
  // const courses = useSelector(selectItem(['courses']));
  // const courses_groups = useSelector(selectItem(['courses_groups']));
  const quest_groups = useSelector(selectItem(['quest_groups']));
  // const user_courses = useSelector(selectItem(['user_courses']));
  // const notification = useSelector(selectItem(['settings', 'notification']));

  const stipendion = grants ? (
    (grants.stipendion && grants.stipendion.length) ? [...grants.stipendion].sort((item) => (Number(item.mentor_uid) === Number(user.uid) ? -1 : 0)) : []
  ) : null;

  let quests = {...user_quests};
  const keys = (quests && quests.complete) ? Object.keys(quests.complete) : [];

  if (quests.complete && keys.length > 3) {
    quests.complete = keys.slice(keys.length-3, keys.length).reduce((result, key) => {
      result[key] = quests.complete[key];
      return result;
    }, {});
  }

  const sortByPosition = (items) => {
    return items.sort((prev, current) => prev.position - current.position);
  };

  const showAllOld = (
    <div className={styles.buttonQuests}>
      <Button butStyle="text-primary" to="/user-quests/#complete">Смотреть все</Button>
    </div>
  );

  const showAllActive = (
    <div className={styles.buttonQuests}>
      <Button butStyle="text-primary" to="/user-quests/#active">Смотреть все</Button>
    </div>
  );

  // const showAllActiveCourses = (
  //   <div className={styles.buttonQuests}>
  //     <Button butStyle="text-primary" to="/user-courses">Смотреть все</Button>
  //   </div>
  // );

  return (
    <MainLayout>
      {(user && user.uid) && <UserQuery uid={user.uid} />}
      <HeaderPlayer user={user} />

      <Block title="О сервисе">
        {user && <About user={user} />}
      </Block>

      <Block>
        <Balance
          score={{ now: user.score_now, day: user.score_day, week: user.score_week, prevWeek: user.score_week_prev, total: user.reward_limit }}
          ambitions={ambitions && ambitions.active}
          stipendion={stipendion}
          rewards={rewards_mentor}
          showRewards={true}
        />
        <ButtonsContainer>
          <Button
            fullWidth={true}
            butStyle="primary"
            to="/quests-wheel"
          >
            Выбрать новый квест
          </Button>
        </ButtonsContainer>
      </Block>

      <Block title="Мои квесты" rightButton={showAllActive}>
        <QuestsList
          quests={quests && quests.active && sortByPosition(Object.values(quests.active).slice(0, 5))}
          type="small"
          direction="row"
          emptyText="Нет активных квестов"
        />
      </Block>

      {/* <Block
        title="Активные курсы"
        rightButton={showAllActiveCourses}
      >
        <UserCourses items={user_courses} courses={courses} emptyText="Нет активных курсов" />
      </Block> */}

      <Block title="Игры" infoSlug="games">
        <HpStories items={quest_groups && quest_groups.S} />
      </Block>

      {/* <Block title="Курсы по темам" infoSlug="course_themes">
        <Courses items={courses_groups && courses_groups.T} emptyText="Нет тем для курсов" />
      </Block> */}

      {/* <Block title="Курсы по профессиям" infoSlug="course_prof">
        <Professions items={courses_groups && courses_groups.P} />
      </Block> */}

      <Block title="Пройденные квесты" rightButton={showAllOld}>
        <QuestsList
          quests={quests && quests.complete && Object.values(quests.complete).reverse().slice(0, 5)}
          type="small"
          direction="row"
          emptyText="Нет пройденных квестов"
          showRate={true}
        />
      </Block>
    </MainLayout>
  );
}

export default Home;
