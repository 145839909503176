import classnames from 'classnames';
import styles from './register-layout.module.css';

const RegisterLayout = ({ children }) => {
  return (
    <div className={styles.container}>
      <div className={styles.top} style={{ backgroundImage: `url(/images/clouds-top.png)` }}></div>
      <div className={classnames(styles.content, 'container')}>
        { children }
      </div>
      <div className={styles.bottom} style={{ backgroundImage: `url(/images/clouds-bottom.png)` }}></div>
    </div>
  );
}

export default RegisterLayout;