import { useState, useRef } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { MainLayout } from '../../components/Layouts';
import Block from '../../components/Block';
import ButtonsContainer from '../../components/ButtonsContainer';
import Button from '../../components/Button';
import ModalApprove from '../../components/modals/ModalApprove';
import { QuestDescription } from '../../components/quests/Quest';
import { setItem } from '../../store/app/actions';
import { selectItem } from '../../store/app/getters';
import useApiQuery from '../../hooks/useApiQuery';
import * as api from '../../helpers/api';
import Rating from '../../components/Rating';

import styles from './player.module.css';

const Message = ({ user, quest }) => {
  return (
    <>
      {user.name}, вы подтверждаете,
      что <span className="accent-text">{quest.user.user_name}</span> выполнил{quest.user.user_gender === 'W' && 'a'} квест <span className="accent-text">{quest.info.name}</span>
    </>
  );
};

const ApproveQuest = () => {
  let { token } = useParams();
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const photoInput = useRef(null);
  const [ rating, setRating ] = useState(0);

  const user = useSelector(selectItem(['user']));
  const max_file_size = useSelector(selectItem(['settings', 'max_file_size']));
  // const quest = useSelector(selectItem(['user_quests', 'query', qid]));

  const [ isShowApprove, setShowApprove ] = useState(false);
  const [ isAnimated, setAnimated ] = useState(false);

  const { data: quest, error } = useApiQuery({
    name: ['quest_approve', token],
    path: `/getQuestAproove/${token}/`
  });

  const { data: player } = useApiQuery({
    name: ['players', quest?.user?.uid],
    path: `/getUserProfile/${quest?.user?.uid}/`,
    skip: !quest || !quest.user || !quest.user.uid,
    mapper: (data = {}, prev) => {
      return {...prev, ...data.main};
    }
  });

  if (error) return <Navigate replace to="/" />;
  if (!quest || !user) return null;
  if (!quest.start || quest.is_complete || quest.is_cancel) return <Navigate replace to="/" />;

  const onChangePhoto = (e) => {
    const file = e.target.files[0];
    if (!file) return null;
    else if (file.size > max_file_size) {
      dispatch(setItem(['notifications', 'approveQuest'], { type: 'error', title: 'Ошибка', content: 'Макс. размер файла 20Мб' }));
      return null;
    }

    const fd = new FormData();
    fd.append('file', file, file.name);

    api
      .post(`/upload/`, fd, { mimeType: 'multipart/form-data', contentType: false })
      .then(response => {
        const result = response.data.response;

        if (result && result.file) {
          sendApprove(result.file);
        } else {
          const error = response.data.error ? response.data.error : 'Ошибка загрузки файла';
          dispatch(setItem(['notifications', 'approveQuest'], { type: 'error', title: 'Ошибка', content: error }));
        }
      })
      .catch(error => {
        dispatch(setItem(['notifications', 'request'], { type: 'error', content: 'Ошибка обработки запроса. Перезагрузите страницу и попробуйте позже' }));
      });
  };

  const sendApprove = (photo) => {
    const fd = new FormData();
    if (photo) fd.append('photo', photo);
    if (rating) fd.append('mark', rating);
    fd.append('quest_uid', quest.uid);

    api
      .post(`/aproove/`, fd, { mimeType: 'multipart/form-data', contentType: false })
      .then(response => {
        const result = response.data.response;

        if (result && result.sucess) {
          setShowApprove(true);
          setTimeout(() => { setAnimated(true); }, 200);
          setTimeout(() => { setShowApprove(false); }, 3000);
          setTimeout(() => { navigate('/'); }, 3100);
          setTimeout(() => { setAnimated(false); }, 3500);
        } else {
          const error = response.data.error ? response.data.error : 'Ошибка подтверждения';
          dispatch(setItem(['notifications', 'approveQuest'], { type: 'error', title: 'Ошибка', content: error }));
        }
      })
      .catch(error => {
        dispatch(setItem(['notifications', 'request'], { type: 'error', content: 'Ошибка обработки запроса. Перезагрузите страницу и попробуйте позже' }));
      });
  };

  const onClickSubmit = () => {
    photoInput.current.click();
  };

  if (player && player.tariff && player.tariff.end && new Date(player.tariff.end) < new Date()) return (
    <MainLayout>
      <Block title="Невозможно подтвердить выполнение квеста!" align="center" headerAlign="center" showBackButton={true} />
      <Block>
        <div className={styles.tariffMessage}>
          <div className={styles.tariffMessageText}>
            У этого игрока закончился бесплатный пробный период, невозможно подтвердить выполнение квеста!
          </div>
        </div>
      </Block>
    </MainLayout>
  );

  return (
    <MainLayout>
      <div style={{ padding: '10px' }} />
      <Block title={<Message user={user} quest={quest} />} align="center" headerAlign="center" />
      <Rating defaultValue={rating} onChange={value => setRating(value)}>Оценка прохождение квеста</Rating>
      <Block>
        <QuestDescription quest={quest} />
        {quest.link &&
          <Button butStyle="text-primary" href={quest.link}>Дополнительно</Button>
        }
      </Block>

      <ButtonsContainer className={styles.buttons}>
        <form className={styles.form}>
          <input
            id="photo"
            type="file"
            name="photo"
            accept="image/*"
            onChange={e => onChangePhoto(e)}
            style={{ display: 'none' }}
            ref={photoInput}
          />
        </form>
        <Button
          fullWidth={true}
          butStyle="primary"
          onClick={() => onClickSubmit()}
        >
          Приложить фото и подтвердить
        </Button>
        <Button
          fullWidth={true}
          butStyle="text"
          to="/"
        >
          Отмена
        </Button>
      </ButtonsContainer>

      {(isAnimated || isShowApprove) &&
        <ModalApprove approves={1} isAnimated={isAnimated} isOpen={isShowApprove} name={quest.user.user_name} />
      }
    </MainLayout>
  );
}

export default ApproveQuest;
