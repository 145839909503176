import classNames from 'classnames';

import styles from './artefacts-list.module.css';

const ArtefactsList = ({ items = [1, 2, 3, 4, 5] }) => {
  return (
    <div className={styles.list}>
      {items.map(({ uid, name, url, given }, index) => {
        if (!uid) return (
          <div className={styles.item} key={index}>
            <div className={classNames(styles.imageContainer, 'skeleton-loader')} />
            <div className={classNames(styles.title, 'skeleton-loader')} />
          </div>
        );

        return (
          <div className={styles.item}>
            <div className={classNames(styles.imageContainer, !given && styles.locked)}>
              {given ? (
                <img className={styles.image} src={url} alt={name} />
              ) : (
                <img className={styles.lockIcon} src="/images/locked.svg" alt="" />
              )}
            </div>
            <div className={styles.title}>
              {name}
            </div>
          </div>
        );
      })}
    </div>
  )
};

export default ArtefactsList;
