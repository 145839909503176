import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RegisterLayout } from '../../components/Layouts';
import Form from '../../components/Form';
// import Modal from '../../components/Modal';
// import Video from '../../components/Video';
import PhoneCode from '../../components/fields/PhoneCode';
import Button from '../../components/Button';
import { setItem } from '../../store/app/actions';
import { selectItem } from '../../store/app/getters';

import styles from './account.module.css';

// const videoUrl = 'https://play.skillary.club/intro.mp4';

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector(selectItem(['user']));
  const verification = useSelector(selectItem(['verification']));

  const onPhoneSubmit = (result) => {
    if (!result.user || result.user.error) {
      dispatch(setItem(['notifications', 'login'], { type: 'error', title: 'Профиль не найден', content: 'Чтобы пользоваться сервисом пройдите регистрацию.' }));
      dispatch(setItem(['verification'], {}, {isHard: true}));
      return null;
    }

    dispatch(setItem(['user'], result.user));
  };

  const inputs = [
    { id: 'phone', name: 'phone', component: PhoneCode, validation: 'phone',
      props: { label: 'Введите ваш номер телефона', placeholder: '+7', fullWidth: true, mask: '+7 ### ### ## ##', type: 'tel',
        onPhoneSubmit: onPhoneSubmit, path: '/auth'
      }
    },
  ];

  useEffect(() => {
    if (user && user.uid) {
      if (!user.name) navigate('/create');
      else navigate('/');
    }

    // eslint-disable-next-line
  }, [user]);

  const onFormSubmit = data => {
    if (!verification.isCodeSended) dispatch(setItem(['verification', 'sendCode'], true));
    if (!verification.isCodeValid) return null;
  };

  // const howToControl = forwardRef(({ onClick }, ref) => (
  //   <Button
  //     fullWidth={true}
  //     butStyle="primary"
  //     onClick={onClick}
  //     ref={ref}
  //   >
  //     Как пользоваться сервисом?
  //   </Button>
  // ));

  const buttons = (
    <>
      <Button
        type="submit"
        fullWidth={true}
        butStyle="primary"
      >
        Войти
      </Button>
      <Button
        to="/register"
        fullWidth={true}
        butStyle="secondary"
      >
        Зарегистрироваться
      </Button>

      <div className={styles.terms}>
        Нажимая кнопку ”<span className="accent-text">Войти</span>” вы соглашаетесь
        с <a href="https://skillary.club/docs/license.pdf">политикой обработки персональных данных</a>
        и принимаете <a href="https://skillary.club/docs/oferta.pdf">условия оферты сервиса</a>
      </div>

      {/* <Modal
        viewStyle="bottom"
        buttons={[
          { text: 'Закрыть', action: 'close', style: 'primary' }
        ]}
        control={howToControl}
      >
        <Video src={videoUrl} autoPlay={true} controls={true} />
      </Modal> */}
    </>
  );

  return (
    <RegisterLayout>
      <h1 className={styles.title}>Авторизация</h1>
      <div className={styles.subtitle}>
        {verification.isCodeSended ? (
          <>
            Проверяйте код из смс, он прийдёт на номер
            <div><b>{verification.checkingPhone}</b></div>
          </>
        ) : (
          <>
            Введите номер телефона, а затем код из смс
          </>
        )}
      </div>
      <div className={styles.content}>
        <Form inputs={inputs} onSubmit={onFormSubmit} buttons={!verification.isCodeSended && buttons} />
      </div>-
    </RegisterLayout>
  );
}

export default Login;
