import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { ReactSVG } from 'react-svg';
import * as api from '../../helpers/api';
import { RegisterLayout } from '../../components/Layouts';
import Form from '../../components/Form';
import { PlayersSelect } from '../../components/UsersSelect';
import Input from '../../components/fields/Input';
// import Select from '../../components/fields/Select';
import Switch from '../../components/fields/Switch';
// import DateInput from '../../components/fields/DateInput';
import Button from '../../components/Button';
import { selectItem } from '../../store/app/getters';
import { setItem } from '../../store/app/actions';

import styles from '../Account/account.module.css';
import { useNavigate } from 'react-router-dom';

import { genders } from '../../data/mock';

const CreateMentor = () => {
  const [ isSubmitActive, setSubmitActive ] = useState(false);
  const [ toAdd, setToAdd ] = useState(false);

  const dispatch = useDispatch();
  const user = useSelector(selectItem(['user']));
  const navigate = useNavigate();
  const tokens = useSelector(selectItem(['tokens']));

  const minDateBirth = new Date();
  minDateBirth.setFullYear(minDateBirth.getFullYear() - 90);

  const maxDateBirth = new Date();
  maxDateBirth.setFullYear(maxDateBirth.getFullYear() - 18);

  let locationOpts = [];
  if (user.location) {
    locationOpts.push({
      label: user.location,
      value: user.location
    });
  }
  let birthLocationOpts = [];
  if (user.birth_location) {
    birthLocationOpts.push({
      label: user.birth_location,
      value: user.birth_location
    });
  }

  const inputs = [
    { id: 'name', name: 'name', component: Input, validation: 'string',
      props: { label: 'Ваше имя', value: user.name || '', placeholder: 'Например, Иван', fullWidth: true, autoFocus: true }
    },
    { id: 'surname', name: 'surname', component: Input, validation: 'string',
      props: { label: 'Ваша фамилия', value: user.surname || '', placeholder: 'Например, Иванов', fullWidth: true }
    },
    // { id: 'location', name: 'location', component: Select, validation: 'select',
    //   props: {
    //     label: 'Город проживания', placeholder: 'Выберите город', value: user.location, query: user.location, options: locationOpts,
    //     searchPlaceholder: 'Например, "Новоуральск"', fullWidth: true, isSearchable: true,
    //     loadUrl: '/getcity', mapper: (data) => data.map(({ city }) => ({ label: city, value: city }))
    //   }
    // },
    // { id: 'birth_date', name: 'birth_date', component: DateInput,
    //   props: { type: 'date', label: 'Дата рождения', value: user.birth_date || '', placeholder: '5 июня 1990 года',
    //     fullWidth: true, icon: <ReactSVG src="/images/icon-calendar.svg" />, minDate: minDateBirth, maxDate: maxDateBirth
    //   }
    // },
    { id: 'gender', name: 'gender', component: Switch, validation: 'select',
      props: { id: 'gender', label: 'Пол', options: genders, value: user.gender || '' }
    }
  ];

  const onSubmit = (data) => {
    const fd = new FormData();
    for (let key in data) {
      fd.append(`params[${key}]`, data[key]);
    }

    if (tokens && Object.keys(tokens).length) {
      for (let key in tokens) {
        const token = tokens[key];
        fd.append(`params[${token.key}]`, token.value);
      }
    }

    api
      .post(`/updateUserProfile/${user.uid}/`, fd)
      .then(response => {
        const result = response.data.response;
        if (result.success) {
          dispatch(setItem(['user'], result.user));

          if (toAdd) { navigate('/create-player'); }
          else {
            if (user.created_players) navigate('/create-player-success');
            else navigate('/guide');
          }
        } else {
          const error = response.data.error ? response.data.error : 'Неизвестная ошибка';
          dispatch(setItem(['notifications', 'updateProfile'], { type: 'error', title: 'Ошибка', content: error }));
        }
      })
      .catch(error => {
        dispatch(setItem(['notifications', 'request'], { type: 'error', content: 'Ошибка обработки запроса. Перезагрузите страницу и попробуйте позже' }));
      });
  };

  const buttons = (
    <>
      <PlayersSelect
        users={user.player}
        viewStyle="register"
        showAddButton={false}
      />

      <div className={styles.addPlayer}>
        <div className="text-center">
          {(user.player && user.player.length) ? 'Можно добавить еще 1 игрока' : 'Добавьте минимум 1 игрока чтобы он смог выполнять квесты'}
        </div>
        <Button
          type="submit"
          fullWidth={true}
          butStyle="primary"
          disabled={!isSubmitActive}
          onClick={() => setToAdd(true)}
          size="small"
        >
          {/* <ReactSVG src="/images/icon-plus.svg" className={styles.icon} /> */}
          Добавить игрока
        </Button>
      </div>

      <Button
        type="submit"
        fullWidth={true}
        butStyle="secondary"
        disabled={!isSubmitActive}
        size="small"
      >
        Далее
      </Button>
    </>
  );

  return (
    <RegisterLayout>
      <h1 className={styles.title}>
        {user.created_players ? 'Поздравляем, аккаунты Наставника и Игрока созданы!' : 'Поздравляем, аккаунт наставника создан'}
      </h1>
      {/* <div className={styles.subtitle}>Осталось пройти еще пару шагов, это займет меньше минуты =)</div> */}
      <div className={styles.content}>
        <Form inputs={inputs} onSubmit={onSubmit} buttons={buttons} onValidChange={setSubmitActive} />
      </div>
    </RegisterLayout>
  );
}

export default CreateMentor;
