import { useSelector } from 'react-redux';
import { MainLayout } from '../../components/Layouts';
import Block from '../../components/Block';
import HeaderMentor from '../../components/header/HeaderMentor';
import AboutBlocks from '../../components/homepage/AboutBlocks';
import useApiQuery from '../../hooks/useApiQuery';
import { selectItem } from '../../store/app/getters';
import { faq_items } from '../../data/mock';

const About = () => {
  const user = useSelector(selectItem(['user']));

  const { data: pin_code } = useApiQuery({
    name: ['pin_code'],
    path: `/getpincode/${user.uid}/`,
    skip: !user
  });

  return (
    <MainLayout>
      <HeaderMentor user={user} pin_code={pin_code} />
      <Block title="О сервисе">
        {user && <AboutBlocks user={user} items={faq_items} />}
      </Block>
    </MainLayout>
  );
}

export default About;
