import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ReactSVG } from 'react-svg';
import { GameLayout } from '../../components/Layouts';
import GameMap from '../../components/GameMap';
import GameChat from '../../components/GameChat';
import useApiQuery from '../../hooks/useApiQuery';
import * as api from '../../helpers/api';
import { selectItem } from '../../store/app/getters';
import { setItem } from '../../store/app/actions';

import styles from './game.module.css';

const Game = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectItem(['user']));
  const gameParams = useSelector(selectItem(['game_params'], {}));
  const [ update, setUpdate ] = useState(null);
  const [ isInited, setInited ] = useState(false);

  useEffect(() => {
    setUpdate(new Date());
  }, []);

  const { data: game, isSuccess } = useApiQuery({
    name: ['game', user.uid],
    path: `/gameprogress/${user.uid}/`,
    skip: !user,
    save_history: true,
    update_param: gameParams && gameParams.city + update
  });

  const handleClickAnswer = (dialog_id, message_key, ask_uid, variant) => {
    api
      .get(`/gameaskanswer/${user.uid}/${ask_uid}/${variant}/`)
      .then(response => {
        const result = response.data.response;

        if (result) {
          dispatch(setItem(['game', user.uid, 'dialogs', dialog_id, 'dialog', message_key, 'ask'], result, { isHard: true }));
        }
      });
  };

  const addNewPoint = async (point_uid) => {
    const response = await api.get(`/gameaddnewpoint/${point_uid}/`);
    const result = response.data.response;

    if (response.data.error) return null;
    if (result) {
      dispatch(setItem(['game', user.uid, 'energy'], result.energy));
      return result;
    }
  };

  const changeCity = async () => {
    const response = await api.get(`/gamecitygate/${user.uid}/`);
    const result = response.data.response;

    if (response.data.error || result.error) return null;
    if (result) {
      // dispatch(setItem(['game_params', 'city'], result.new_point_uid));
      return result;
    }
  };

  const handleCityOut = async () => {
    api.get(`/game_edit_player/remove_progress/${user.uid}/`);
    api.get(`/game_edit_player/add_ball/${user.uid}/5000/`);
  }

  if (!game && !isSuccess) return null;

  // todo: редирект если все чекнуто

  const currentPoint = game && Object.values(game.points).find(({ is_active }) => !!is_active);

  const onAddPoints = () => api.get(`/game_edit_player/add_ball/${user.uid}/1000/`);
  const onRemovePoints = () => api.get(`/game_edit_player/remove_progress/${user.uid}/`);
  const onRemoveProgress = (point_uid) => api.get(`/gameaddnewpoint/${point_uid}/remove/${user.uid}/`);

  return (
    <GameLayout game={game}>
      <div key="game">
    <a style={{ display: 'block', marginTop: '80px' }} onClick={onAddPoints} href="#">Add points</a>
    {/* <a style={{ display: 'block', marginTop: '80px' }} onClick={onRemovePoints} href="#">Remove points</a> */}
    {/* <a onClick={() => onRemoveProgress(1002)} href="#">Remove 1002</a>&nbsp;&nbsp;&nbsp; */}

        {isSuccess && (
          <GameMap
            user={user}
            game={game}
            gameParams={gameParams}
            onAddNewPoint={addNewPoint}
            onChangeCity={changeCity}
            afterInit={() => setInited(true)}
            onCityOut={() => handleCityOut()}
          />
        )}
        {!isInited && (
          <div className={styles.loader}>
            <div className="container">
              <ReactSVG src="/images/game/loader.svg" className={styles.loaderImage} />
            </div>
          </div>
        )}

      </div>
      <div key="chat">
        {isSuccess &&
          <GameChat user={user} chat={game.dialogs} current={currentPoint.uid} onAnswer={handleClickAnswer} />
        }
      </div>
    </GameLayout>
  );
};

export default Game;