import { ReactSVG } from 'react-svg';
import { Link } from 'react-router-dom';
import classnames from 'classnames';

import styles from './users-select.module.css';

const Mentors = ({ users }) => {
  if (!users) return null;

  return (
    <ul className={styles.list}>
      {Object.values(users).map(({ name, surname, gender, phone, relation, avatar, info, verify_mentor }, index) => {
        if (info) {
          name = info.name;
          surname = info.surname;
          gender = info.gender;
          phone = info.phone;
          avatar = info.avatar;
        }

        const photo = avatar ? avatar : (`/images/icon-mentor-${gender ? gender.toLowerCase() : 'm'}.svg`);
        const text = (name || surname) ? `${name && name} ${surname && surname}` : 'Без имени';

        return (
          <li key={index} className={styles.item}>
            {verify_mentor ? (
              <div className={classnames(styles.button, styles.verified)}>
                {gender && <img src={photo} className={styles.icon} alt="" />}
                {text}
                {relation && ` (${relation.name})`}
              </div>
            ) : (
              <Link to={`/invite-mentor/${phone}`} className={classnames(styles.button, styles.inActive)}>
                {gender && <img src={photo} className={styles.icon} alt="" />}
                {text}
                {relation && ` (${relation.name})`}
              </Link>
            )}
          </li>
        )
      })}
    </ul>
  );
};

const MentorsSelect = ({ users, notice }) => {
  return (
    <div className={styles.main}>
      <h3 className={styles.title}>Взрослые</h3>
      {users &&
        <Mentors users={users} />
      }
      <Link to="/invite-mentor" className={classnames(styles.button, styles.addMentor)}>
        <ReactSVG src="/images/icon-plus.svg" className={styles.icon} />
        Пригласить наставника
      </Link>

      {notice &&
        <div className={styles.notice}> {notice} </div>
      }
    </div>
  );
};

export default MentorsSelect;
