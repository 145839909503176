import { useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Navigate, useParams, useNavigate } from 'react-router-dom';
import { MainLayout } from '../../components/Layouts';
import Block from '../../components/Block';
import ButtonsContainer from '../../components/ButtonsContainer';
import Button from '../../components/Button';
import Rating from '../../components/Rating';
import { setItem } from '../../store/app/actions';
import { selectItem } from '../../store/app/getters';
import useApiQuery from '../../hooks/useApiQuery';
import * as api from '../../helpers/api';

import styles from './player.module.css';

const RateQuest = () => {
  let { qid } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [ rating, setRating ] = useState(0);
  const commentInput = useRef(null);

  const user = useSelector(selectItem(['user']));
  const quest = useSelector(selectItem(['user_quests', 'query', qid]));
  const _complete_quest = useSelector(selectItem(['user_quests', 'complete', qid]));

  const { error } = useApiQuery({
    name: ['user_quests', 'query', qid],
    path: `/getUserQuest/${user.uid}/${qid}/`,
    mapper: (data = {}, prev) => {
      let next = {};

      for (let key in data) {
        if (data[key] && data[key].length) {
          for (let questKey in data[key]) { next = data[key][questKey]; }
        }
      }

      return {...prev, ...next};
    }
  });

  const onClickSubmit = () => {
    const fd = new FormData();

    fd.append('quest_uid', quest.uid);
    if (rating) fd.append('user_mark', rating);
    if (commentInput.current && commentInput.current.value) fd.append('comment', commentInput.current.value);

    api
      .post(`/questrating/`, fd)
      .then(response => {
        const result = response.data.response;

        if (result && result.success) {
          dispatch(setItem(['user_quests', 'query', qid, 'rating'], rating));
          dispatch(setItem(['notifications', 'rateQuest'], { type: 'success', content: result.success }));

          if (_complete_quest) dispatch(setItem(['user_quests', 'complete', qid, 'rating'], rating));

          if (quest.is_complete) navigate(`/`);
          else navigate(`/get-quest-approve/${quest.uid}`);

        } else {
          const error = response.data.error ? response.data.error : 'Неизвестная ошибка';
          dispatch(setItem(['notifications', 'rateQuest'], { type: 'error', content: error }));
        }
      })
      .catch(error => {
        dispatch(setItem(['notifications', 'request'], { type: 'error', content: 'Ошибка обработки запроса. Перезагрузите страницу и попробуйте позже' }));
      });
  }

  if (error) return <Navigate replace to="/" />;
  if (!quest) return null;
  if (quest.is_cancel) return <Navigate replace to="/404" />;

  return (
    <MainLayout>
      <div className={styles.success}>
        <Block title={quest.info.name} align="center" headerAlign="center">
          <Rating defaultValue={rating} onChange={value => setRating(value)}>Пожалуйста, оцените пройденный квест</Rating>
          <ButtonsContainer>
            <textarea
              id="comment"
              name="comment"
              rows="4"
              placeholder="Поделитесь мнением о квесте"
              maxLength="255"
              ref={commentInput}
            />
            <Button
              butStyle="primary"
              onClick={() => onClickSubmit()}
              fullWidth={true}
            >{quest.is_complete ? 'Завершить' : 'Получить подтверждение'}</Button>
          </ButtonsContainer>
        </Block>
      </div>
    </MainLayout>
  );
};

export default RateQuest;
