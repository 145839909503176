import { useSelector, useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { MainLayout } from '../../../components/Layouts';
import Block from '../../../components/Block';
import Button from '../../../components/Button';
import Audio from '../../../components/Audio';
import useApiQuery from '../../../hooks/useApiQuery';
import CoursesQuests from '../../../components/courses/CoursesQuests';
import { selectItem } from '../../../store/app/getters';
import { setItem } from '../../../store/app/actions';
import * as api from '../../../helpers/api';

import styles from './courses.module.css';

const CourseQuests = () => {
  const { cid, gid } = useParams();
  const user = useSelector(selectItem(['user']));
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { data: course } = useApiQuery({
    name: ['courses', cid],
    path: `/getCoursesAll/${user.uid}/${cid}/`,
    skip: !user,
    mapper: (data = []) => {
      if (data && data.length) return data[0];
      return data;
    }
  });

  const group = course?.groups.find(({ uid }) => (Number(gid) === Number(uid)));

  const handleClickStart = () => {
    api
      .get(`/addUserCourse/${user.uid}/${gid}/`)
      .then(response => {
        const result = response.data.response;
        if (result) {
          navigate(`/user-course/${gid}`);
          dispatch(setItem(['notifications', 'add_course'], { type: 'success', content: 'Курс начат. Успешного прохождения!' }));
        } else {
          const error = response.data.error ? response.data.error : 'Ошибка обработки запроса'
          dispatch(setItem(['notifications', 'add_course'], { type: 'error', content: error }));
        }
      })
      .catch(error => {
        dispatch(setItem(['notifications', 'add_course'], { type: 'error', content: 'Ошибка обработки запроса. Перезагрузите страницу и попробуйте позже' }));
      });
  }

  return (
    <MainLayout>
      <Block title={group ? group.name : <span>&nbsp;</span>} headerAlign="center" showBackButton={true}>
        {group.description &&
          <div className={styles.headerDescr} dangerouslySetInnerHTML={{ __html: group.description }} />
        }
        {group.audio &&
          <div className={styles.audio}>
            <Audio src={group.audio} />
          </div>
        }
        <div className={styles.headerButtons}>
          <Button
            butStyle="primary"
            size="medium"
            fullWidth={true}
            onClick={() => handleClickStart()}
          >Начать прохождение</Button>
        </div>
        <div className={styles.headerInfo}>
          <div className={!(group && group.quests) ? 'skeleton-loader' : undefined}>
            {(group && group.quests) && `${group.quests.length} квестов`}
          </div>
          <div className={!(group && group.hard_lvl) ? 'skeleton-loader' : undefined}>
            {(group && group.quests) && `Сложность: ${group.hard_lvl}`}
          </div>
        </div>
        <CoursesQuests items={group && group.quests} checkLock={true} />
      </Block>
    </MainLayout>
  );
};

export default CourseQuests;
