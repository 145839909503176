import { useRef, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import classNames from 'classnames';
// import SidebarMentor from './SidebarMentor';
import LoginPlayer from './LoginPlayer';
import Button from '../../Button';
import Modal from '../../Modal';
import { setItem } from '../../../store/app/actions';
import getCurrentHost from '../../../helpers/getCurrentHost';
import copyToClipboard from '../../../helpers/copyToClipboard';

import styles from './header.module.css';

const Avatar = ({ user, href, onClick, active }) => {
  const name = user.info ? user.info.name : user.name;
  const gender = user.info ? user.info.gender : user.gender;
  let avatar = user.info ? user.info.avatar : user.avatar;
  if (!avatar) avatar = `/images/icon-${(user.type && user.type === 'M') ? 'mentor' : 'child'}-${gender ? gender.toLowerCase() : 'm'}.svg`;

  return (
    <Link to={href} className={classNames(styles.profile, active && styles.active)} onClick={onClick}>
      <div className={styles.profileImageContainer}>
        <img src={avatar} className={styles.profileImage} alt="" />
      </div>
      {active &&
        <div className={styles.profileName}>{name}</div>
      }
    </Link>
  );
};

const LoginModal = ({ player, open, onClose, onClickLogin, onClickCopy }) => {
  const name = player.info ? player.info.name : player.name;
  const gender = player.info ? player.info.gender : player.gender;
  const self_key = player.info ? player.info.self_key : player.self_key;
  let avatar = player.info ? player.info.avatar : player.avatar;
  if (!avatar) avatar = `/images/icon-${(player.type && player.type === 'M') ? 'mentor' : 'child'}-${gender ? gender.toLowerCase() : 'm'}.svg`;

  return (
    <Modal
      viewStyle="bottom"
      open={open}
      title="Войти в аккаунт"
      onClose={onClose}
    >
      <div className={styles.login}>
        <img src={avatar} className={styles.loginImage} alt="" />
        <div className={styles.loginName}>{name}</div>

        <Button
          className={styles.loginButtonMain}
          butStyle="primary"
          fullWidth={true}
          onClick={() => onClickLogin(player.uid_gamer)}
        >
          Войти на этом телефоне
        </Button>
        <div className={styles.loginText}>или войти на телефоне {name}</div>
        <div className={styles.loginButtons}>
          <Button
            className={styles.loginButton}
            butStyle="secondary"
            to={`/invite-player/${player.uid_gamer}`}
          >
            Показать QR-код
          </Button>
          <Button
            className={styles.loginButton}
            butStyle="primary"
            onClick={() => onClickCopy(self_key)}
          >
            Отправить ссылку
          </Button>
        </div>
      </div>
    </Modal>
  );
};

const HeaderMentor = ({ user, pin_code }) => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const isMentorHome = pathname === '/' || pathname === '/faq';
  const containerRef = useRef(null);
  const playerRef = useRef(null);
  const loginRef = useRef(null);

  const [ isShowModal, setShowModal ] = useState(false);

  const handleClickCopy = async (key) => {
    const url = `${getCurrentHost()}/auth/${key}`;

    try {
      await navigator.share({
        title: 'Skillary: Войти в приложение',
        url: url
      });
    } catch (err) {
      const result = copyToClipboard(url);
      if (result) {
        dispatch(setItem(['notifications', 'copy_link'], { type: 'success', content: 'Ссылка для входа скопирована' }));
      } else {
        dispatch(setItem(['notifications', 'copy_link'], { type: 'error', content: 'Ошибка копирования' }));
      }
    }

    setShowModal(false);
  };

  const handleClickSignIn = (uid_gamer) => {
    setShowModal(true);
  };

  const handleClickLogin = (uid_gamer) => {
    setShowModal(false);
    loginRef.current.login(uid_gamer)
  };

  const handleClickUser = (uid_gamer) => {
    const player = user.player.find((player) => player.uid_gamer === uid_gamer);
    dispatch(setItem(['user', 'current_player'], player, { isHard: true }));
  };

  useEffect(() => {
    if (containerRef.current && playerRef.current) {
      const left = playerRef.current.offsetLeft - containerRef.current.offsetLeft;
      containerRef.current.scrollTo(left - 46 - 20, 0);
    }
  }, [containerRef, playerRef]);

  return (
    <>
      <div className={styles.root}>
        <div className={styles.left}>
          {/* <SidebarButton /> */}
          {/* <SidebarMentor
            user={user}
            current={user.current_player}
            players={user.player}
            onClickLogin={handleClickLogin}
          /> */}

          <div className={styles.users} ref={containerRef}>
            <div className={styles.usersItem}>
              <Avatar
                user={user}
                active={isMentorHome}
                href="/"
              />
            </div>
            {(user.player && user.player.length) ? (
              user.player.map(player => {
                const isActive = !isMentorHome && user.current_player && user.current_player.uid_gamer === player.uid_gamer;
                return (
                  <div className={styles.usersItem} key={player.uid_gamer} ref={isActive ? playerRef : null}>
                    <Avatar
                      user={player}
                      active={isActive}
                      href={`/player`}
                      onClick={() => handleClickUser(player.uid_gamer)}
                    />
                  </div>
                );
              })
            ) : null}
          </div>
        </div>

        <div className={styles.right}>
          {(!isMentorHome && user.current_player) && (
            <button className={styles.signIn} onClick={() => handleClickSignIn(user.current_player.uid_gamer)}>
              <span className={styles.signInText}>
                Войти как <br /> {user.current_player.info ? user.current_player.info.name : user.current_player.name}
              </span>
              <ReactSVG className={styles.signInIcon} src="/images/icon-sign-in.svg" wrapper="span" />
            </button>
          )}
        </div>
      </div>

      <LoginPlayer
        user={user}
        player={user.current_player}
        players={user.player}
        pin_code={pin_code}
        showButtons={!isMentorHome}
        ref={loginRef}
      />

      {user.current_player &&
        <LoginModal
          open={isShowModal}
          player={user.current_player}
          onClose={() => setShowModal(false)}
          onClickLogin={handleClickLogin}
          onClickCopy={handleClickCopy}
        />
      }
    </>
  );
};

export default HeaderMentor;
