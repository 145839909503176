import { forwardRef, useState } from 'react';
import classnames from 'classnames';

import baseStyles from '../fields.module.css';
import styles from './radio.module.css';

const Radio = forwardRef(({id, onClick, onBlur, onChange, value, name, options, title, label, error, Notice, hidden, inputClass}, ref) => {
  const [ current, setCurrent ] = useState(value);

  if (!options) return null;

  const onRadioChange = (e) => {
    setCurrent(e.target.value);
    if (onChange) onChange(e);
  }

  return (
    <div className={classnames(styles.container, hidden && styles.hidden)}>
      {title && <div className={styles.title}>{title}</div>}
      {label && <label className={baseStyles.label}>{label}</label>}
      <div className={classnames(styles.radio)}>
        {options.map(item =>
          <label
            className={classnames(styles.option, String(current) === String(item.value) && styles.checked, item.primary && styles.primary)}
            key={item.value} htmlFor={`${id}_${item.value}`}
          >
            <input
              type="radio"
              id={`${id}_${item.value}`}
              name={name}
              value={item.value}
              onChange={(e) => onRadioChange(e)}
              onClick={onClick}
              onBlur={onBlur}
              className={classnames(styles.input, inputClass && inputClass)}
              ref={ref}
            />
            <span className={classnames(styles.icon, String(current) === String(item.value) && styles.active)}></span>
            <span className={styles.text}>{item.label}</span>
          </label>
        )}
      </div>
      {error &&
        <span className={baseStyles.errorText}>{ error }</span>
      }
      {Notice &&
        <span className={classnames(baseStyles.notice, styles.notice)}><Notice /></span>
      }
    </div>
  );
});

export default Radio;
