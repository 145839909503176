import { forwardRef } from 'react';
import { Controller } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import classnames from 'classnames';

import styles from '../fields.module.css';

const Input = forwardRef(({ id, control, onClick, onChange, name, placeholder, mask, type='text', hidden, value,
  containerClass, title, label, fullWidth, error, icon, notice, pattern, inputClass, ...rest }, ref) => {
  const Component = mask ? NumberFormat : 'input';

  return (
    <div className={classnames(styles.container, fullWidth && styles.fullWidth, containerClass && styles[containerClass], hidden && styles.hidden)}>
      {title && <div className={styles.title}>{title}</div>}
      {label && <label className={styles.label} htmlFor={id}>{label}</label>}
      <div className={styles.inputContainer}>
        {control ? (
          <Controller
            name={name}
            control={control}
            render={({ field: { onChange, onBlur, value, ref }, formState }) => {
              return (
                <Component
                  {...rest}
                  id={id}
                  name={name}
                  placeholder={placeholder}
                  onChange={onChange}
                  onClick={onClick}
                  onBlur={onBlur}
                  type={type}
                  className={classnames(styles.input, icon && styles.withIcon, inputClass && inputClass, error && 'error', mask && 'masked', value && 'filled')}
                  defaultValue={value}
                  pattern={pattern}
                  ref={ref}
                  {...(mask ? {format: mask} : {})}
                  {...(mask ? {allowEmptyFormatting: true} : {})}
                />
              );
            }}
          />
        ) : (
          <Component
            {...rest}
            id={id}
            name={name}
            placeholder={placeholder}
            onChange={onChange}
            onClick={onClick}
            type={type}
            className={classnames(styles.input, icon && styles.withIcon, inputClass && inputClass, error && 'error')}
            value={value}
            ref={ref}
            {...(mask ? {format: mask} : {})}
          />
        )}
        {icon &&
          <span className={styles.icon}>{ icon }</span>
        }
      </div>
      {error &&
        <span className={styles.errorText}>{ error }</span>
      }
      {notice &&
        <span className={styles.notice}>{ notice }</span>
      }
    </div>
  );
});

export default Input;