import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { usePromiseTracker } from 'react-promise-tracker';
import { MainQuery, VersionQuery, CurrentPlayerQuery } from '../MainQuery';
import routes from '../data/routes';
import useCache from '../hooks/useCache';
import ScrollToTop from '../components/ScrollToTop';
import Loader from '../components/Loader';
import Notifications from '../components/Notifications';
import { selectItem } from '../store/app/getters';
import { setItem } from '../store/app/actions';

const Main = () => {
  useCache();
  const dispatch = useDispatch();
  const user = useSelector(selectItem(['user']));
  const show_loader = useSelector(selectItem(['show_loader']));

  const { promiseInProgress } = usePromiseTracker();

  const currentPlayer = (user && user.current_player) ? user.current_player : null;
  const [ currentRoutes, setRoutes ] = useState(null);

  useEffect(() => {
    if (!user || !user.uid) {
      setRoutes(routes.register);
      dispatch(setItem(['show_loader'], false));
      return;
    }

    if (!user.type) {
      setRoutes(routes.selectType);
      dispatch(setItem(['show_loader'], false));
      return;
    }

    if (user.type === 'P') { // Player
      if (!user.gender) setRoutes(routes.emptyPlayer);
      else setRoutes([...routes.main, ...routes.player]);
    } else if (user.type === 'M') { // Mentor
      if (!user.name) setRoutes(routes.emptyMentor);
      else setRoutes([...routes.main, ...routes.mentor]);
      if (!user.current_player && user.player) dispatch(setItem(['user', 'current_player'], user.player[0]));
    }
    dispatch(setItem(['show_loader'], false));

    // eslint-disable-next-line
  }, [user]);

  if (!currentRoutes) return null;

  return (
    <Router>
      <VersionQuery />
      {currentPlayer && currentPlayer.uid_gamer && <CurrentPlayerQuery uid={currentPlayer.uid_gamer} />}
      {(user && user.uid) && <MainQuery />}
      <Notifications />
      <ScrollToTop />
      <Loader show={show_loader || promiseInProgress}/>
      <Routes>
        {currentRoutes.map(props => <Route key={props.path} {...props} />)}
      </Routes>
      <div id="modal" />
    </Router>
  );
};

export default Main;