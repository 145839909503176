import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams, Navigate } from 'react-router-dom';
import * as api from '../../helpers/api';
import { setItem } from '../../store/app/actions';

const CreateMentoring = () => {
  const dispatch = useDispatch();
  const { token } = useParams();

  useEffect(() => {
    api
      .get(`/aproovementor/${token}/`)
      .then(response => {
        const error = response.data.error;
        const result = response.data.response;

        if (error) dispatch(setItem(['notifications', 'aproovementor'], { type: 'error', content: error }));
        else if (result.user) dispatch(setItem(['user'], result.user));

        if (result && result.action) {
          for (const item of result.action) {
            dispatch(setItem(['notifications', 'aproovementor'], { type: 'success', content: item }));
          }
        }
      })
      .catch(error => {
        dispatch(setItem(['notifications', 'request'], { type: 'error', content: 'Ошибка обработки запроса. Перезагрузите страницу и попробуйте позже' }));
      });

    //eslint-disable-next-line
  }, []);

  return <Navigate to="/" />;
}

export default CreateMentoring;
