import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation, Navigate } from 'react-router-dom';
import { useParams } from 'react-router';
import useApiQuery from '../../hooks/useApiQuery';
import { MainLayout } from '../../components/Layouts';
import { QuestDetails, QuestDescription, QuestSkills } from '../../components/quests/Quest';
import Block from '../../components/Block';
import ButtonsContainer from '../../components/ButtonsContainer';
import Button from '../../components/Button';
import Modal from '../../components/Modal';
import InfoBlock from '../../components/InfoBlock';
import * as api from '../../helpers/api';
import { selectItem } from '../../store/app/getters';
import { setItem } from '../../store/app/actions';

const Quest = () => {
  let { qid } = useParams();
  let navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const [ startedQuest, setStartedQuest ] = useState(null);

  const user = useSelector(selectItem(['user']));
  const cutPoints = !location.state || !location.state.from || location.state.from !== 'wheel';
  const showBackLink = !cutPoints;

  const handleClickStart = () => {
    api
      .get(`/addUserQuest/${user.uid}/${quest.uid}/`)
      // .get(`/addUserQuest/${user.uid}/${quest.uid}/${cutPoints ? 'reward_cut' : ''}`)
      .then(response => {
        const result = response.data.response;
        if (result && result.uid) {
          dispatch(setItem(['user_quests', 'active', result.uid], result));
          dispatch(setItem(['user', 'active_quest_count'], user.active_quest_count+1));
          setStartedQuest(result.uid);
        } else {
          const error = response.data.error ? response.data.error : 'Неизвестная ошибка';
          dispatch(setItem(['notifications', 'addUserQuest'], { type: 'error', content: error }));
        }
      })
      .catch(error => {
        dispatch(setItem(['notifications', 'request'], { type: 'error', content: 'Ошибка обработки запроса. Перезагрузите страницу и попробуйте позже' }));
      });
  };

  const { data: quests, error } = useApiQuery({
    name: ['quest', qid],
    path: `/getQuestAll/?uid=${qid}`,
    skip: !qid
  });

  if (!quests || !quests[0]) return null;
  if (error) return <Navigate replace to="/quests-wheel" />;

  const quest = quests[0];

  return (
    <MainLayout>
      {quest &&
        <>
        {/* showBackButton={showBackLink} */}
          <Block title={quest.name} headerAlign="center" showBackButton={true}>
            <QuestDetails user={user} quest={quest} cutPoints={cutPoints} />
            <QuestSkills quest={quest} />

          </Block>

          <Block title="Задание">
            <QuestDescription quest={quest} />
            {quest.link &&
              <ButtonsContainer>
                <Button butStyle="secondary" size="small" fullWidth="true" href={quest.link}>Дополнительно</Button>
              </ButtonsContainer>
            }

            {user.type === 'M' &&
              <div style={{ marginTop: '15px' }}>
                <InfoBlock color="red">
                  Взять квест может только ребенок в своем аккаунте
                </InfoBlock>
              </div>
            }

            <ButtonsContainer>
              {user.type === 'P' &&
                <Button
                  butStyle="primary"
                  fullWidth={true}
                  onClick={() => handleClickStart()}
                >
                  Начать выполнение
                </Button>
              }
              {showBackLink &&
                <Button
                  onClick={() => navigate(-1)}
                  butStyle="secondary"
                  fullWidth={true}
                >Вернуться в рулетку</Button>
              }
            </ButtonsContainer>
          </Block>

          <Modal
            title="Фото"
            viewStyle="bottom"
            open={!!startedQuest}
            onClose={() => setStartedQuest(null)}
            buttons={[
              { text: 'Хорошо', action: 'click', style: 'primary', onClick: () => navigate(`/user-quest/${startedQuest}`) }
            ]}
          >
            Попроси друзей сфотографировать тебя во время выполнения квеста. Когда нужно будет подтвердить выполнение они должны будут загрузить это фото!
          </Modal>
        </>
      }
    </MainLayout>
  );
};

export default Quest;
