import { ReactSVG } from 'react-svg';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import BalanceItem from '../BalanceItem';
import Placeholder from '../Placeholder';
import PointsProgress from '../../PointsProgress';

import styles from '../balance.module.css';

const Grants = ({ user, items }) => {
  if (!items) return (
    <div className={classnames(styles.item, 'skeleton-loader')} />
  );

  if (!Object.keys(items).length) {
    return (
      <Placeholder
        userType={user && user.type}
        title="Повысит мотивацию в развитии навыков и улучшит отношение к деньгам"
        childTitle="Предложи родителю настроить стипендию <br /> в личном кабинете"
        linkUrl="/grants"
        buttonText="Настроить стипендию"
      />
    );
  };

  return (
    items.map(({ mentor_uid, mentor_name, summ_week, summ_week_prev, week_limit_cash }) => (
      <BalanceItem key={mentor_uid}>
        <div className={classnames(styles.row, styles.title)}>
          Стипендия {mentor_name && `от ${mentor_name}`}
        </div>
        <div className={styles.row}>
          На этой неделе <span className={styles.value}>{summ_week || 0}</span>
        </div>
        <div className={styles.row}>
          На прошлой неделе <span className={styles.value}>{summ_week_prev || 0}</span>
        </div>

        <div className={styles.progress}>
          <div className={styles.progressTitle}>
            Лимит стипендии в неделю
          </div>
          <PointsProgress current={summ_week} total={week_limit_cash} />
        </div>

        {(user && user.type === 'M') &&
          <Link className={styles.settings} to={'/grants'}>
            <ReactSVG src="/images/icon-settings.svg" className={styles.settingsIcon} wrapper="span" />
          </Link>
        }
      </BalanceItem>
    ))
  );
};

export default Grants;
