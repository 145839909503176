import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { MainLayout } from '../../components/Layouts';
import Block from '../../components/Block';
import { QuestsWheel as Wheel } from '../../components/quests/QuestsWheel';
import Modal from '../../components/Modal';
import { selectItem } from '../../store/app/getters';

const QuestsWheel = () => {
  const [ showNotify, setShowNotify ] = useState(false);
  const [ isDisabled, setDisabled ] = useState(false);
  const user = useSelector(selectItem(['user']));

  useEffect(() => {
    if (user.reward_limit - user.score_day <= 20) setShowNotify(true);
    setDisabled(user.active_quest_count && user.quest_active_limit && user.active_quest_count >= user.quest_active_limit);
  }, [user]);

  return (
    <MainLayout>
      <Block
        title="Выбрать квест"
        align="center"
        headerAlign="center"
        infoSlug="quests-wheel"
      >
        <Wheel allowClick={!isDisabled} />

        {(user && user.reward_limit && user.reward_limit > 0) ?
          <Modal
            title="Оповещние"
            viewStyle="bottom"
            open={showNotify}
            onClose={() => setShowNotify(false)}
            buttons={[
              { text: 'Понятно', action: 'close' }
            ]}
          >
            {user.reward_limit <= user.score_day ? (
              <>Получено {user.score_day} баллов из {user.reward_limit} за день, за новый квест вы получите 0 баллов</>
            ) : (
              <>Получено {user.score_day} баллов из {user.reward_limit} за день, если ваш квест будет из {user.reward_limit - user.score_day + 10} баллов, вы получите за него только {user.reward_limit - user.score_day} баллов</>
            )}
          </Modal>
        : ''}
      </Block>
    </MainLayout>
  );
}

export default QuestsWheel;
