import { ReactSVG } from 'react-svg';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import BalanceItem from '../BalanceItem';
import Placeholder from '../Placeholder';
import PointsProgress from '../../PointsProgress';

import styles from '../balance.module.css';

const Ambitions = ({ user, items }) => {
  if (!items) return (
    <div className={classnames(styles.item, 'skeleton-loader')} />
  );

  if (!Object.keys(items).length) return (
    <Placeholder
      userType={user && user.type}
      title="Поставьте игроку цель, чтобы повысить мотивацию и развить целеустремленность"
      childTitle="Предложи родителю настроить цели <br /> в личном кабинете"
      linkUrl="/ambitions"
      buttonText="Настроить цели"
    />
  );

  return (
    <BalanceItem>
      <div className={classnames(styles.row, styles.title)}>
        Цели
      </div>
      <div className={styles.ambitionsList}>
        {Object.values(items).map(({ uid, skill_description, reward_name, summ, value, color }) => (
          <div className={styles.ambition} key={uid}>
            <div className={styles.ambitionHeader}>
              <span className={styles.ambitionTitle}>
                {skill_description}
              </span>
              <span className={styles.ambitionDescr}>
                {reward_name}
              </span>
            </div>
            <PointsProgress current={summ ? summ : 0} total={value} color={color} />
          </div>
        ))}

        {(user && user.type === 'M') &&
          <Link className={styles.settings} to={'/ambitions'}>
            <ReactSVG src="/images/icon-settings.svg" className={styles.settingsIcon} wrapper="span" />
          </Link>
        }
      </div>
    </BalanceItem>
  );
};

export default Ambitions;