import { useRef } from 'react';
import ReactPlayer from 'react-player';
import classNames from 'classnames';

import styles from './video.module.css';

const Video = ({ src, controls, onReady, autoPlay = false, className, onError }) => {
  const player = useRef(null);
  if (!src) return null;

  const handleReady = () => {
    if (onReady) onReady(player.current);
    if (autoPlay && player.current) player.current.wrapper.firstChild.play();
  };

  return (
    <div className={classNames(styles.root, className)}>
      <ReactPlayer
        className={styles.player}
        url={src}
        width="100%"
        height="100%"
        controls={controls}
        onReady={handleReady}
        onError={e => onError && onError(e)}
        autoPlay={autoPlay}
        ref={player}
        config={{
          youtube: {
            playerVars: { controls: 1 }
          }
        }}
      />
    </div>
  );
};

export default Video;
