import { Link, useLocation } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import classNames from 'classnames';

import styles from './game-header.module.css';

const GameHeader = ({ user, game, isChatOpen, onClickChat }) => {
  const { pathname } = useLocation();

  return (
    <div className={styles.root}>
      <div className={classNames(styles.row, 'container')}>
        <div className={styles.left}>
          <div
            className={styles.energy}
            id="game_energy"
          >
            <ReactSVG src="/images/game/energy.svg" className={styles.energyIcon} />
            <span>{game && game.energy}</span>
          </div>
        </div>
        <div className={styles.right}>
          <Link
            to={pathname === '/game-backpack' ? '/game' : '/backpack'}
            className={classNames(styles.button, pathname === '/backpack' && styles.active)}
            id="game_backpack"
          >
            <ReactSVG src="/images/game/backpack.svg" className={styles.icon} />
            <span>Рюкзак</span>
          </Link>
          <div
            onClick={onClickChat}
            className={classNames(styles.button, isChatOpen && styles.active)}
            id="game_chat"
          >
            <ReactSVG src="/images/game/phone.svg" className={styles.icon} />
            <span>Связь</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GameHeader;
