import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import classnames from 'classnames';
import { MainLayout } from '../../../../components/Layouts';
import Block from '../../../../components/Block';
import Button from '../../../../components/Button';
import ButtonsContainer from '../../../../components/ButtonsContainer';
import * as api from '../../../../helpers/api';
import useApiQuery from '../../../../hooks/useApiQuery';
import { selectItem } from '../../../../store/app/getters';
import { setItem, deleteItem } from '../../../../store/app/actions';

import styles from './Grants.module.css';

const Item = ({ name, value, onChange, placeholder, className, readOnly }) => {
  return (
    <div className={classnames(styles.item, className, !name && 'skeleton-loader')}>
      {name &&
        <>
          <div className={styles.itemTitle}>{name}</div>
          <NumberFormat
            type="tel"
            className={classnames(styles.itemInput, (!value || Number(value) === 0) && 'error')}
            value={value}
            onValueChange={values => onChange && onChange(values.value)}
            placeholder={placeholder}
            readOnly={readOnly}
            mask="#####"
          />
          {(!value || Number(value) === 0) && <span className={styles.error}>введите значение</span>}
        </>
      }
    </div>
  );
};

const Form = ({ user, player, grants, onSubmit }) => {
  const [ skills, setXp ] = useState(100);
  // const [ skills, setXp ] = useState(grants.from_skills);
  const [ p, setP ] = useState(grants.to_cash);
  const [ max, setMax ] = useState(grants.week_limit_cash);

  const handleChangeP = (value) => {
    if (!value) value = null;
    setP(value);
  };

  const handleChangeXp = (value) => {
    if (!value) value = null;
    setXp(value);
  };

  const handleChangeMax = (value) => {
    if (!value) value = null;
    setMax(value);
  };

  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <Item name="Баллы опыта" value={skills} onChange={handleChangeXp} placeholder={100} readOnly={true} />
        <img src="/images/icon-equal.svg" className={styles.equal} alt="=" />
        <Item name="₽" value={p} onChange={handleChangeP} placeholder={10} className={styles.currency} />
      </div>
      <div className={styles.description}>Максимальная выплата в неделю не должна привышать</div>
      <div className={styles.row}>
        <Item name="₽" value={max} onChange={handleChangeMax} placeholder={700} className={styles.currency} />
      </div>
      <ButtonsContainer>
        <Button
          butStyle="primary"
          onClick={() => onSubmit(p, skills, max)}
          disabled={!(user && player && p && skills && max && Number(p) !== 0 && Number(skills) !== 0 && Number(max) !== 0)}
        >
          Сохранить
        </Button>
      </ButtonsContainer>
    </div>
  );
};

const SkeletForm = () => {
  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <Item />
        <img src="/images/icon-equal.svg" className={styles.equal} alt="=" />
        <Item />
      </div>
      <div className={styles.description}>Максимальная выплата в неделю не должна привышать</div>
      <div className={styles.row}>
        <Item />
      </div>
      <ButtonsContainer>
        <Button butStyle="primary" disabled={true}>
          Сохранить
        </Button>
      </ButtonsContainer>
    </div>
  );
};

const Grants = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector(selectItem(['user']));
  const currentPlayer = user.current_player ? user.current_player : null;
  const grants = useSelector(selectItem(['user_grants', currentPlayer?.uid_gamer]));

  useApiQuery({
    name: ['user_grants', currentPlayer?.uid_gamer],
    path: `/getuserstep/${currentPlayer?.uid_gamer}/${user.uid}/`,
    skip: !user || !currentPlayer,
    mapper: (data = {}, prev) => {
      if (data.length) return data[0];
      else return {};
    }
  });

  const handleSubmit = (p, skills, max) => {
    if (!user || !currentPlayer) return null;

    const fd = new FormData();
    fd.append('update[player_uid]', currentPlayer.uid_gamer);
    fd.append('update[mentor_uid]', user.uid);
    fd.append('update[from_skills]', skills);
    fd.append('update[to_cash]', p);
    fd.append('update[week_limit_cash]', max);
    // fd.append("update[delete]", "delete");

    api
      .post('/stepupdate/', fd)
      .then(response => {
        const result = response.data.response;

        if (result.stipendion) {
          if (result.message)dispatch(setItem(['notifications', 'stepupdate'], { type: 'success', content: result.message }));
          dispatch(deleteItem(['user_grants'], currentPlayer.uid_gamer));
          navigate('/services');
        } else {
          if (response.data.error) dispatch(setItem(['notifications', 'stepupdate'], { type: 'error', content: response.data.error }));
        }
      })
      .catch(error => {
        dispatch(setItem(['notifications', 'stepupdate'], { type: 'error', content: 'Ошибка обработки запроса. Перезагрузите страницу и попробуйте позже' }));
      });
  }

  return (
    <MainLayout>
      <Block title="Стипендия" headerAlign="center" showBackButton={true} infoSlug="stipendy">
        <div className={styles.subtitle}>
          Стимулирует ребенка заниматься саморазвитием и осваивать новые навыки <br /><br />
          Ребенок может получать более 7000 баллов опыта в неделю
        </div>
      </Block>
      {user.player ? (
        <>
          <Block>
            {grants ? (
              <Form
                user={user}
                player={currentPlayer}
                grants={grants}
                onSubmit={handleSubmit}
              />
            ) : (
              <SkeletForm />
            )}
          </Block>
        </>
      ) : (
        <>
          <div className="text-center" style={{ marginTop: '60px' }}>Добавьте минимум 1 игрока чтобы назначать стипендию</div>
          <ButtonsContainer>
            <Button
              to="/create-player"
              fullWidth={true}
              butStyle="primary"
              state={{ from: '/create' }}
              size="medium"
            >
              {/* <ReactSVG src="/images/icon-plus.svg" className={styles.icon} /> */}
              Добавить игрока
            </Button>
          </ButtonsContainer>
        </>
      )}
    </MainLayout>
  );
};

export default Grants;