import { useSelector } from 'react-redux';
import { MainLayout } from '../../../components/Layouts';
import Block from '../../../components/Block';
import useApiQuery from '../../../hooks/useApiQuery';
import { UserCourses } from '../../../components/courses/Courses';
import { selectItem } from '../../../store/app/getters';

const UserCoursesPage = () => {
  const user = useSelector(selectItem(['user']));

  const { data: courses } = useApiQuery({
    name: ['user_courses'],
    path: `/getUserCourses/${user.uid}/`,
    skip: !user,
    mapper: (data = {}) => {
      const next = data.reduce((prev, current) => {
        return { ...prev , [current.course_uid]: current };
        // return (current.length && current[0].course_uid) ? { ...prev , [current[0].course_uid]: course } : prev;
      }, {});
      return next;
    }
  });

  return (
    <MainLayout>
      <Block title="Курсы" headerAlign="center" showBackButton={true} />
      <Block>
        <UserCourses group_uid={1} items={courses} emptyText="Нет активных курсов" />
      </Block>
    </MainLayout>
  );
};

export default UserCoursesPage;
