import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ReactSVG } from 'react-svg';
import * as api from '../../helpers/api';
import { RegisterLayout } from '../../components/Layouts';
import Form from '../../components/Form';
import Input from '../../components/fields/Input';
import Select from '../../components/fields/Select';
import DateInput from '../../components/fields/DateInput';
import Switch from '../../components/fields/Switch';
import Button from '../../components/Button';
import { setItem } from '../../store/app/actions';
import { selectItem } from '../../store/app/getters';

import 'react-datepicker/dist/react-datepicker.css';
import styles from '../Account/account.module.css';

import { genders } from '../../data/mock';

const CreatePlayer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [ isSubmitActive, setSubmitActive ] = useState(false);

  const user = useSelector(selectItem(['user']));
  const tokens = useSelector(selectItem(['tokens']));

  let schoolOpts = [];
  if (user.school && user.school_name) {
    schoolOpts.push({
      label: user.school_name,
      value: user.school
    });
  }
  let locationOpts = [];
  if (user.location) {
    locationOpts.push({
      label: user.location,
      value: user.location
    });
  }

  const minDateBirth = new Date();
  minDateBirth.setFullYear(minDateBirth.getFullYear() - 13);

  const maxDateBirth = new Date();
  maxDateBirth.setFullYear(maxDateBirth.getFullYear() - 6);

  const inputs = [
    { id: 'name', name: 'name', component: Input, validation: 'string',
      props: { label: 'Ваше имя', placeholder: 'Например, Иван', value: user.name || '', fullWidth: true }
    },
    { id: 'birth_date', name: 'birth_date', component: DateInput,
      props: { type: 'date', /* title: 'Дата и время рождения',*/ label: 'Дата рождения', placeholder: '5 июня 2006 года',
        fullWidth: true, icon: <ReactSVG src="/images/icon-calendar.svg" />, minDate: minDateBirth, maxDate: maxDateBirth
      }
    },
    { id: 'location', name: 'location', component: Select, validation: 'select',
      props: {
        label: 'Город проживания', placeholder: 'Выберите город', value: user.location, query: user.location, options: locationOpts,
        searchPlaceholder: 'Например, "Новоуральск"', fullWidth: true, isSearchable: true,
        loadUrl: '/getcity', mapper: (data) => data.map(({ city }) => ({ label: city, value: city }))
      }
    },
    { id: 'gender', name: 'gender', component: Switch, validation: 'select',
      props: { id: 'gender', label: 'Пол', options: genders, autoFocus: true }
    }
  ];

  const onFormSubmit = data => {
    const fd = new FormData();
    for (let key in data) {
      fd.append(`params[${key}]`, data[key]);
    }

    if (tokens && Object.keys(tokens).length) {
      for (let key in tokens) {
        const token = tokens[key];
        fd.append(`params[${token.key}]`, token.value);
      }
    }

    api
      .post(`/updateUserProfile/${user.uid}/`, fd)
      .then(response => {
        const result = response.data.response;
        if (result.success) {
          if (result.user) dispatch(setItem(['user'], result.user));
          dispatch(setItem(['notifications', 'addUser'], { type: 'success', content: 'Профиль создан!' }));
          navigate(`/guide`);
        } else {
          const error = response.data.error ? response.data.error : 'Неизвестная ошибка';
          dispatch(setItem(['notifications', 'updateProfile'], { type: 'error', title: 'Ошибка', content: error }));
        }
      })
      .catch(error => {
        dispatch(setItem(['notifications', 'request'], { type: 'error', content: 'Ошибка обработки запроса. Перезагрузите страницу и попробуйте позже' }));
      });
  };

  const buttons = (
    <Button
      type="submit"
      fullWidth={true}
      butStyle="primary"
      disabled={!isSubmitActive}
      size="small"
    >
      Далее
    </Button>
  );

  return (
    <RegisterLayout>
      <h1 className={styles.title}>Карточка игрока</h1>
      <div className={styles.content}>
        <Form inputs={inputs} onSubmit={onFormSubmit} buttons={buttons} onValidChange={setSubmitActive} />
      </div>
    </RegisterLayout>
  );
}

export default CreatePlayer;
