import { Link } from 'react-router-dom';
import classnames from 'classnames';

import styles from './buttons-grid.module.css';

const ButtonsGrid = ({ buttons }) => {
  return (
    <div className={styles.list}>
      {buttons.map(({ name, link, icon, disabled }, index) => (
        <div className={styles.item} key={index}>
          <Link className={classnames(styles.button, disabled && styles.disabled)} to={link}>
            <span className={styles.buttonImage}>
              <img className={styles.buttonIcon} src={icon} alt="name" />
            </span>
            <span className={styles.buttonText}>{name}</span>
          </Link>
        </div>
      ))}
    </div>
  );
};

export default ButtonsGrid;
