import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { MainLayout } from '../../../components/Layouts';
import Block from '../../../components/Block';
import Audio from '../../../components/Audio';
import useApiQuery from '../../../hooks/useApiQuery';
import CoursesQuests from '../../../components/courses/CoursesQuests';
import { selectItem } from '../../../store/app/getters';

import styles from './courses.module.css';

const CourseQuests = () => {
  const { cid, gid } = useParams();
  const user = useSelector(selectItem(['user']));
  const currentPlayer = user.current_player;
  const uid = currentPlayer?.uid_gamer;

  const { data: course } = useApiQuery({
    name: ['courses', uid, cid],
    path: `/getCoursesAll/${uid}/${cid}/`,
    mapper: (data = []) => {
      if (data && data.length) return data[0];
      return data;
    }
  });

  const group = course?.groups.find(({ uid }) => (Number(gid) === Number(uid)));

  return (
    <MainLayout>
      <Block title={group ? group.name : <span>&nbsp;</span>} headerAlign="center" showBackButton={true}>
        {group.description &&
          <div className={styles.headerDescr} dangerouslySetInnerHTML={{ __html: group.description }} />
        }
        {group.audio &&
          <div className={styles.audio}>
            <Audio src={group.audio} />
          </div>
        }
        <div className={styles.headerInfo}>
          <div className={!(group && group.quests) ? 'skeleton-loader' : undefined}>
            {(group && group.quests) && `${group.quests.length} квестов`}
          </div>
          <div className={!(group && group.hard_lvl) ? 'skeleton-loader' : undefined}>
            {(group && group.quests) && `Сложность: ${group.hard_lvl}`}
          </div>
        </div>
        <CoursesQuests items={group && group.quests} checkLock={true} />
      </Block>
    </MainLayout>
  );
};

export default CourseQuests;
