import { ReactSVG } from 'react-svg';
import classNames from 'classnames';
import Gallery from '../Gallery';

import styles from './message.module.css';

// const images = [
//   { link: 'https://s1.1zoom.me/big3/984/Canada_Parks_Lake_Mountains_Forests_Scenery_Rocky_567540_3840x2400.jpg' },
//   { link: 'https://s1.1zoom.me/big3/984/Canada_Parks_Lake_Mountains_Forests_Scenery_Rocky_567540_3840x2400.jpg' },
//   { link: 'https://s1.1zoom.me/big3/984/Canada_Parks_Lake_Mountains_Forests_Scenery_Rocky_567540_3840x2400.jpg' },
//   { link: 'https://s1.1zoom.me/big3/984/Canada_Parks_Lake_Mountains_Forests_Scenery_Rocky_567540_3840x2400.jpg' },
//   { link: 'https://s1.1zoom.me/big3/984/Canada_Parks_Lake_Mountains_Forests_Scenery_Rocky_567540_3840x2400.jpg' }
// ]

const Message = ({ type, content, audio, button, gallery, arrowPositions = { top: '100%', left: '20%', right: 'auto'}, onExit }) => {
  if (!content) return null;

  const handleClickPlayAudio = () => {
    console.log('audio playing start')
  };

  return (
    <div className={classNames(styles.message, styles[type])}>
      <span className={styles.messageArrow} style={{
        top: arrowPositions.top,
        left: arrowPositions.left || 'auto',
        right: arrowPositions.right || 'auto',
        backgroundImage: type === 'danger' ? 'url(/images/game/message_tick_danger.svg)' : 'url(/images/game/message_tick.svg)'
      }} />
      {(gallery && gallery.length) ? <Gallery items={gallery} /> : null}
      <div className={styles.gallery}></div>
      <div className={styles.content} dangerouslySetInnerHTML={{ __html: content }} />
      {button &&
        <div className={styles.buttons}>
          {audio &&
            <button className={classNames(styles.button, styles.secondary, styles.disabled)} onClick={handleClickPlayAudio}>
              озвучить
              <ReactSVG src="/images/game/button_play.svg" className={styles.buttonIcon} wrapper="span" />
            </button>
          }
          <button className={classNames(styles.button, styles.primary)} onClick={onExit}>
            {button}
            <ReactSVG src="/images/game/button_right.svg" className={styles.buttonIcon} wrapper="span" />
          </button>
        </div>
      }
    </div>
  );
};

export default Message;