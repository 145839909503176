import { useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Slider from 'react-slick';
import { TailSpin } from 'react-loader-spinner';
import Video from '../Video';

import styles from './fs-slider.module.css';

const FSSlider = ({ items, isVideo, exitOnEnd }) => {
  const navigate = useNavigate();
  const slider = useRef(null);

  const handleClickDocument = (e) => {
    if (slider.current) {
      const { width: sliderWidth } = slider.current.innerSlider.list.getBoundingClientRect();
      const width = document.documentElement.clientWidth;
      const clickAreaWidth = Math.max((width-sliderWidth)/2, width/5);

      if (e.pageX < clickAreaWidth) {
        e.preventDefault();
        slider.current.slickPrev();
      }
      else if (e.pageX > width-clickAreaWidth) {
        e.preventDefault();
        slider.current.slickNext();
      }
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickDocument);
    return () => document.removeEventListener('click', handleClickDocument);
  }, [slider]);

  const settings = {
    dots: true,
    arrows: false,
    infinite: false,
    lazyLoad: 'progressive',
    lazyIndicator: <TailSpin color="var(--brand-color)" height="64" width="64" />,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    // centerMode: true,
    // centerPadding: '20px',
    beforeChange: (currentSlide, nextSlide) => {
      if (items[currentSlide].video) {
        const video = slider.current.innerSlider.list.children[0].children[currentSlide].getElementsByTagName('video')[0];
        if (video) video.pause();
      }
      if (items[currentSlide].audio) {
        const audio = slider.current.innerSlider.list.children[0].children[currentSlide].getElementsByTagName('audio')[0];
        if (audio) audio.pause();
      }

      if (items[currentSlide].video) {
        const video = slider.current.innerSlider.list.children[0].children[nextSlide].getElementsByTagName('video')[0];
        if (video) video.play();
      }
      if (items[currentSlide].audio) {
        const audio = slider.current.innerSlider.list.children[0].children[nextSlide].getElementsByTagName('audio')[0];
        if (audio) audio.play();
      }

      if (exitOnEnd && currentSlide === items.length-1 && currentSlide === nextSlide) navigate('/');
    }
  };

  return (
    <div className={styles.root}>
      {/* <button className={classNames(styles.arrow, styles.prev, isVideo && styles.arrowVideo)} onClick={() => slider.current.slickPrev()} /> */}
      <Slider {...settings} ref={slider}>
        {items.map(({ src, color, video, link, audio }, index) => (
          <div className={styles.item} key={index}>
            {src &&
              <img src={src} className={styles.image} style={{ backgroundColor: color }} alt="" />
            }
            {video &&
              <Video src={video} className={styles.video} controls={true} autoPlay={index === 0} />
            }
            {audio &&
              <audio controls className={styles.audio} src={audio} autoPlay={index === 0} />
            }
          </div>
        ))}
      </Slider>
      {/* <button className={classNames(styles.arrow, styles.next, isVideo && styles.arrowVideo)} onClick={() => slider.current.slickNext()} /> */}
    </div>
  );
};

export default FSSlider;