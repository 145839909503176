import { Link } from 'react-router-dom';
import classnames from 'classnames';
import ruPlural from '../../../helpers/ruPlural';
import NoItems from '../../NoItems';

import styles from './courses.module.css';

import { courses_bgs } from '../../../data/mock';

const UserCourse = ({ uid, name, description, count, hard_lvl }) => {
  const cid = uid+'';
  const bg = courses_bgs[cid.slice(-1)];

  if (!uid) return (
    <div className={classnames(styles.course, 'skeleton-loader')} />
  );

  return (
    <Link to={`/user-course/${uid}`} className={styles.course} style={{ backgroundImage: `url(${bg})` }}>
      <div className={styles.title}>{name}</div>
      <div className={styles.description}>{description}</div>
      <div className={styles.bottom}>
        {count &&
          <div className={styles.count}>{count} {ruPlural(count, ['квест', 'квеста', 'квестов'])}</div>
        }
        <div className={styles.count}>Сложность: {hard_lvl}</div>
      </div>
    </Link>
  );
};

const UserCourses = ({ group_uid, items = [1, 2, 3, 4, 5], emptyText }) => {
  if (!Object.keys(items).length) return ( // no items
    <NoItems children={emptyText} />
  );

  return (
    <div className={styles.list}>
      {Object.values(items).map(({ course_uid, course_name, quests, hard_lvl }, index) => {
        return (
          <div className={styles.item} key={index}>
            <UserCourse group_uid={group_uid} uid={course_uid} name={course_name} count={quests && quests.length} hard_lvl={hard_lvl} />
          </div>
        );
      })}
    </div>
  );
};

export default UserCourses;
