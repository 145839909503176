import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { MainLayout } from '../../../components/Layouts';
import Block from '../../../components/Block';
import useApiQuery from '../../../hooks/useApiQuery';
import Courses from '../../../components/courses/Courses';
import Audio from '../../../components/Audio';
import { selectItem } from '../../../store/app/getters';

import styles from './courses.module.css';

const CoursePage = () => {
  const { cid } = useParams();
  const user = useSelector(selectItem(['user']));

  const { data: course } = useApiQuery({
    name: ['courses', cid],
    path: `/getCoursesAll/${user.uid}/${cid}/`,
    skip: !user,
    mapper: (data = []) => {
      if (data && data.length) return data[0];
      return data;
    }
  });

  return (
    <MainLayout>
      <Block title={course ? course.name : <>&nbsp;</>} headerAlign="center" showBackButton={true}>
        {(course && course.description) &&
          <div className={styles.headerDescr} dangerouslySetInnerHTML={{ __html: course.description }} />
        }
        {(course && course.audio) &&
          <div className={styles.audio}>
            <Audio src={course.audio} />
          </div>
        }
        <Courses group_uid={course && course.uid} items={course && course.groups} emptyText="Нет подходящих курсов" />
      </Block>
    </MainLayout>
  );
};

export default CoursePage;
