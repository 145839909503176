import { useSelector, useDispatch } from 'react-redux';
import { MainLayout } from '../../../../components/Layouts';
import Block from '../../../../components/Block';
import ButtonsGrid from '../../../../components/ButtonsGrid';
import ButtonsContainer from '../../../../components/ButtonsContainer';
import Button from '../../../../components/Button';
import copyToClipboard from '../../../../helpers/copyToClipboard';
import { selectItem } from '../../../../store/app/getters';
import { setItem } from '../../../../store/app/actions';

import styles from './Services.module.css';

import { mentor_services } from '../../../../data/mock';

const Services = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectItem(['user']));

  const handleClickShare = () => {
    const result = copyToClipboard(user.referal_url);
    if (result) dispatch(setItem(['notifications', 'copy_link'], { type: 'success', content: 'Ссылка для приглашения скопирована' }));
    else dispatch(setItem(['notifications', 'copy_link'], { type: 'error', content: 'Невозможно скопировать ссылку' }));
  }

  return (
    <MainLayout>
      <Block title="Сервисы" headerAlign="center" showBackButton={true}>
        <ButtonsGrid buttons={mentor_services} />
      </Block>
      {user.referal_url &&
        <Block>
          <p className={styles.shareTitle}>Пригласи родителя и получи месяц подписки за каждого вступившего</p>
          <ButtonsContainer className={styles.shareButtons}>
            <div className={styles.shareLink}>
              {user.referal_url}
            </div>
            <Button
              butStyle="primary"
              fullWidth="true"
              onClick={() => handleClickShare()}
              size="medium"
            >
              Копировать ссылку
            </Button>
          </ButtonsContainer>
        </Block>
      }
    </MainLayout>
  );
};

export default Services;
