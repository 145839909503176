import { useSelector } from 'react-redux';
import { MainLayout } from '../../../../components/Layouts';
import Block from '../../../../components/Block';
import Balance from '../../../../components/Balance';
import ButtonsContainer from '../../../../components/ButtonsContainer';
import Button from '../../../../components/Button';
import useApiQuery from '../../../../hooks/useApiQuery';
import { selectItem } from '../../../../store/app/getters';

const Wallet = () => {
  const user = useSelector(selectItem(['user']));
  const currentPlayer = user.current_player ? user.current_player : null;

  const { data: ambitions } = useApiQuery({
    name: ['user_ambitions', currentPlayer?.uid_gamer],
    path: `/getPlayerAmbitions/${currentPlayer?.uid_gamer}/`,
    skip: !currentPlayer || !currentPlayer.uid_gamer,
    mapper: (data = {}, prev) => {
      let next = {};

      for (let key in data) {
        next[key] = (prev && prev[key]) ? {...prev[key]} : {};

        if (data[key] && data[key].length) {
          for (let ambKey in data[key]) {
            const amb = data[key][ambKey];
            next[key][amb.skill_uid] = amb;
          }
        }
      }

      return next;
    }
  });

  const { data: grants } = useApiQuery({
    name: ['grants', currentPlayer?.uid_gamer],
    path: `/getstepresult/${currentPlayer?.uid_gamer}/`,
    skip: !user || !currentPlayer || !currentPlayer.uid_gamer
  });

  const stipendion = grants ? (
    (grants.stipendion && grants.stipendion.length) ? [...grants.stipendion].sort((item) => (Number(item.mentor_uid) === Number(user.uid) ? -1 : 0)) : []
  ) : null;

  return (
    <MainLayout>
      <Block title="Хранилище" headerAlign="center" showBackButton={true} infoSlug="wallet">
        {user.player ? (
          <>
            <Balance user={user} stipendion={stipendion} ambitions={ambitions && ambitions.active} size="small" />
            <ButtonsContainer>
              <Button
                to="/bonuses#A"
                butStyle="primary"
                fullWidth={true}
              >
                Начисления Игрока
              </Button>
            </ButtonsContainer>
          </>
        ) : (
          <>
            <div className="text-center" style={{ marginTop: '60px' }}>Добавьте минимум 1 игрока чтобы он смог выполнять квесты</div>
            <ButtonsContainer>
              <Button
                to="/create-player"
                fullWidth={true}
                butStyle="primary"
                state={{ from: '/create' }}
                size="medium"
              >
                {/* <ReactSVG src="/images/icon-plus.svg" className={styles.icon} /> */}
                Добавить игрока
              </Button>
            </ButtonsContainer>
          </>
        )}
      </Block>
    </MainLayout>
  );
};

export default Wallet;
