import { useNavigate } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import Button from '../../Button';

import styles from './back-button.module.css';

const BackButton = ({ returnUrl }) => {
  let navigate = useNavigate();

  if (returnUrl) return (
    <Button
      to={returnUrl}
      butStyle="text"
      className={styles.button}
      icon={<ReactSVG src="/images/arrow-left.svg" wrapper="span" />}
      iconSize="md"
    />
  );

  return (
    <Button
      onClick={() => navigate(-1)}
      butStyle="text"
      className={styles.button}
      icon={<ReactSVG src="/images/arrow-left.svg" wrapper="span" />}
      iconSize="md"
    />
  );
};

export default BackButton;
