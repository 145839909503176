import { ReactSVG } from 'react-svg';
import Modal from '../../../Modal';
import classnames from 'classnames';

import styles from './level.module.css';
import { forwardRef } from 'react';

const Item = ({ name, lvl, percent, disabled }) => {
  return (
    <div className={styles.item}>
      <div className={styles.image}>
        <ReactSVG src="/images/loading.svg" className={classnames(styles.level, disabled && styles.disabled)} beforeInjection={(svg) => {
          const elems = svg.getElementsByTagName('circle');
          for (let i=0; i<10; i++) {
            if (percent > i*10) elems[i].style.opacity = 1;
          }
        }} />
        <span className={styles.imageText}>
          {disabled ? (
            <ReactSVG src="/images/lock.svg"></ReactSVG>
          ) : (
            <>{percent + '%'}</>
          )}
        </span>
      </div>
      <div className={styles.content}>
        <div className={classnames(styles.title, styles.small)}>{name}</div>
        <div className={classnames(styles.description, styles.gray)}>Уровень {lvl}</div>
      </div>
    </div>
  );
};

const Level = ({ progress }) => {
  if (!progress || !progress.now) return null;

  const { now, next, prev, percent } = progress;

  const control = forwardRef(({ onClick }, ref) => (
    <div className={styles.block} onClick={onClick} ref={ref}>
      <div className={styles.blockTitle}>{now.lvl} Уровень</div>
      <div className={styles.blockSubtitle}>{now.name}</div>
    </div>
  ));

  return (
    <div className={styles.root}>
      <Modal
        title="Текущая стадия уровня"
        viewStyle="bottom"
        control={control}
      >
        <div className={styles.items}>
          <Item name={next.name} lvl={next.lvl} percent={0} inPopup={true} disabled={true} />
          <Item name={now.name} lvl={now.lvl} percent={percent} inPopup={true} />
          {(prev.lvl && prev.lvl > 0) ? <Item name={prev.name} lvl={prev.lvl} percent={100} inPopup={true} /> : null}
        </div>
      </Modal>
    </div>
  );
};

export default Level;
