import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { setItem } from '../../../store/app/actions';
import { faq_items } from '../../../data/mock';

import styles from './about.module.css';

const About = ({ user }) => {
  const dispatch = useDispatch();

  if (!user) return null;

  const handleClickItem = (slug) => {
    if (slug === 'about_feedback') dispatch(setItem(['common', 'feedback_popup', 'open'], true));
    else dispatch(setItem(['common', 'faq'], slug));
  };

  const user_type = user.info ? user.info.type : user.type;

  return (
    <div className={classNames(styles.list, 'scroll-list')}>
      {faq_items.map(({ name, slug, href, image2, color2, disabled, show_mentor, show_player }, index) => {
        if ((user_type === 'P' && show_player === false) || (user_type === 'M' && show_mentor === false)) return null;
        if (slug) return (
          <div
            className={classNames(styles.item, disabled && styles.disabled)}
            onClick={() => handleClickItem(slug)}
            style={{ backgroundColor: color2, backgroundImage: `url(${image2})` }}
            key={index}
          >
            {name}
          </div>
        );
        return (
          <Link
            className={classNames(styles.item, disabled && styles.disabled)}
            to={href}
            style={{ backgroundColor: color2, backgroundImage: `url(${image2})` }}
            key={index}
          >
            {name}
          </Link>
        );
      })}
    </div>
  );
};

export default About;
