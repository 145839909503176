import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import classnames from 'classnames';
import HeadLevel from './HeadLevel';
import SidebarPlayer from './SidebarPlayer';
import { setItem } from '../../../store/app/actions';

import styles from './header.module.css';

const Avatar = ({ user, href }) => {
  let avatar = user.info ? user.info.avatar : user.avatar;
  if (!avatar) {
    avatar = `/images/icon-${(user.type && user.type === 'M') ? 'mentor' : 'child'}-${user.gender ? user.gender.toLowerCase() : 'm'}.svg`;
  }

  return (
    <Link to={href} className={styles.profile}>
      <img src={avatar} className={styles.profileImage} alt="" />
    </Link>
  );
};

const HeaderPlayer = ({ user }) => {
  const dispatch = useDispatch();

  const toggleOpen = (open) => {
    dispatch(setItem(['common', 'side_menu', 'open'], open));
  };

  return (
    <div className={styles.root}>
      <div className={styles.left}>
        <Avatar user={user} href={`/update`} />
        <div className={styles.buttons}>
          <HeadLevel progress={user.progress} />
          <Link to="/wallet" className={classnames(styles.button)}>
            <ReactSVG src="/images/icon-wallet-home.svg" />
          </Link>
        </div>
      </div>
      <div className={styles.right}>
        <button className={styles.signIn} onClick={() => toggleOpen(true)}>
          <span className={styles.signInText}>
            Войти как <br /> взрослый
          </span>
          <ReactSVG className={styles.signInIcon} src="/images/icon-sign-in.svg" wrapper="span" />
        </button>
        {/* <SidebarButton /> */}
        <SidebarPlayer user={user} mentors={user.mentor} />
      </div>
    </div>
  );
};

export default HeaderPlayer;
