export const player_menu = [
  {
    id: 'rating',
    name: 'Карта',
    href: '/',
    icon: '/images/menu/home2.svg',
    routes: ['/game']
  },
  {
    id: 'quests',
    name: 'Квесты',
    href: '/player',
    icon: '/images/menu/star2.svg',
    routes: ['/quest', '/invite-mentor', '/get-quest-approve', '/user-quest', '/invited', '/player']
  },
  // {
  //   id: 'statistic',
  //   name: 'Навыки',
  //   href: '/statistic',
  //   icon: '/images/menu/target2.svg',
  //   routes: ['/statistic', '/ambitions', '/create-ambition']
  // },
  {
    id: 'card',
    name: 'Сервисы',
    href: '/services',
    icon: '/images/menu/app2.svg',
    routes: ['/card', '/invite-player', '/approve', '/update', '/create-avatar', '/wallet', '/backpack']
  },
  {
    id: 'scan',
    name: 'Сканировать',
    href: '#code-scaner-start',
    icon: '/images/menu/scan2.svg',
    routes: [],
    hidden: true
  }
];

export const mentor_menu = [
  {
    id: 'home',
    name: 'Главная',
    href: '/',
    icon: '/images/menu/home2.svg',
    routes: ['/player']
  },
  {
    id: 'settings',
    name: 'Настройки',
    href: '/update',
    icon: '/images/menu/settings.svg',
    routes: []
  },
  {
    id: 'scan',
    name: 'Сканировать',
    href: '#code-scaner-start',
    icon: '/images/menu/scan2.svg',
    routes: [],
    hidden: true
  }
];

let mentor_player_menu =  player_menu.map((x) => ({ ...x }));
mentor_player_menu[0].href = '/game';
export { mentor_player_menu };
