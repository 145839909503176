import { forwardRef } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import { Controller } from 'react-hook-form';
import classnames from 'classnames';
import { format } from 'date-fns';
import ru from 'date-fns/locale/ru';

import styles from './date-input.module.css';
import './date-input.css';

registerLocale('ru', ru);

const DateInput = forwardRef((props, ref) => {
  return (
    <div className={classnames(styles.container, props.fullWidth && styles.fullWidth)}>
      {props.title && <div className={styles.title}>{props.title}</div>}
      {props.label && <label className={styles.label} htmlFor={props.id}>{props.label}</label>}

      <Controller
        name={props.name}
        control={props.control}
        render={({ field: { onChange, onBlur, value, ref }, formState }) => {
          const handleChangeDate = (date) => {
            if (!date) return;
            const val = format(new Date(date), 'yyyy-MM-dd');
            onChange && onChange({target: { name: props.name, value: val }});
          };

          return (
            <div className={styles.picker}>
              <DatePicker
                {...props}
                onBlur={onBlur}
                onChange={handleChangeDate}
                ref={ref}
                className={classnames(styles.input, props.error && 'error', props.inputClass && props.inputClass)}
                placeholderText={props.placeholder}
                selected={value && new Date(value)}
                value={value && new Date(value)}
                autoComplete="off"
                locale="ru"
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                dateFormat="dd MMMM yyyy"
                withPortal
                portalId="modal"
              />

              {props.icon &&
                <span className={styles.iconContainer}>{props.icon}</span>
              }
            </div>
          );
        }}
      />

      {props.error &&
        <span className={styles.errorText}>{ props.error }</span>
      }
    </div>
  );
});

export default DateInput;