import { useSelector } from 'react-redux';
import useApiQuery from '../../hooks/useApiQuery';
import { MainLayout } from '../../components/Layouts';
import Block from '../../components/Block';
import QuestsList from '../../components/quests/QuestsList';
import Balance from '../../components/Balance';
import Button from '../../components/Button';
import ButtonsContainer from '../../components/ButtonsContainer';
import HeaderMentor from '../../components/header/HeaderMentor';
import TariffNotification from '../../components/tariffs/TariffNotification';
import HpStories from '../../components/homepage/HpStories';
// import Courses from '../../components/homepage/Courses';
// import UserCourses from '../../components/homepage/UserCourses';
// import Professions from '../../components/homepage/Professions';
// import About from '../../components/homepage/About';
// import Rewards from '../../components/homepage/Rewards';
import { selectItem } from '../../store/app/getters';

import styles from './mentor.module.css';

const UserQuery = ({ uid }) => {
  useApiQuery({
    name: ['user_quests', uid],
    path: `/getUserQuest/${uid}/`,
    skip: !uid,
    mapper: (data = {}, prev) => {
      let next = {};

      for (let key in data) {
        next[key] = (prev && prev[key]) ? {...prev[key]} : {};

        if (data[key] && data[key].length) {
          for (let questKey in data[key]) {
            const quest = data[key][questKey];
            next[key][quest.uid] = {...quest, position: key};
          }
        }
      }

      return next;
    }
  });

  useApiQuery({
    name: ['user_ambitions', uid],
    path: `/getPlayerAmbitions/${uid}/`,
    skip: !uid,
    mapper: (data = {}, prev) => {
      let next = {};

      for (let key in data) {
        next[key] = (prev && prev[key]) ? {...prev[key]} : {};

        if (data[key] && data[key].length) {
          for (let ambKey in data[key]) {
            const amb = data[key][ambKey];
            next[key][amb.skill_uid] = amb;
          }
        }
      }

      return next;
    }
  });

  useApiQuery({
    name: ['grants', uid],
    path: `/getstepresult/${uid}/`
  });

  useApiQuery({
    name: ['quest_groups', uid, 'S'],
    path: `/getquestgroup/S/0/${uid}/`
  });

  // useApiQuery({
  //   name: ['courses_groups', uid, 'P'],
  //   path: `/getCoursesAll/${uid}/P/`
  // });

  // useApiQuery({
  //   name: ['courses_groups', uid, 'T'],
  //   path: `/getCoursesAll/${uid}/T/`
  // });

  // useApiQuery({
  //   name: ['courses', uid],
  //   path: `/getCoursesAll/${uid}/`,
  //   isHard: true,
  //   mapper: (data = {}) => {
  //     const next = data.reduce((prev, current) => ({ ...prev , [current.uid]: current }), {});
  //     return next;
  //   }
  // });

  // useApiQuery({
  //   name: ['user_courses', uid],
  //   path: `/getUserCourses/${uid}/`,
  //   mapper: (data = {}) => {
  //     const next = data.reduce((prev, current) => {
  //       return { ...prev , [current.course_uid]: current };
  //       // return (current.length && current[0].course_uid) ? { ...prev , [current[0].course_uid]: course } : prev;
  //     }, {});
  //     return next;
  //   }
  // });

  // useApiQuery({
  //   name: ['rewards_mentor', uid],
  //   path: `/playerrewardsfrommentor/${uid}/`,
  //   mapper: (data = {}) => {
  //     return Object.values(data).reduce((prev, current) => {
  //       if (current) return ([ ...prev, ...current ]);
  //       return prev;
  //     }, []);
  //   }
  // });

  return null;
}

const Home = () => {
  const user = useSelector(selectItem(['user']));
  const currentPlayer = user.current_player;
  const uid = currentPlayer?.uid_gamer;
  const info = currentPlayer?.info;

  // const rewards_mentor = useSelector(selectItem(['rewards_mentor', uid]));
  const quests = useSelector(selectItem(['user_quests', uid]));
  const ambitions = useSelector(selectItem(['user_ambitions', uid]));
  const grants = useSelector(selectItem(['grants', uid]));
  // const courses = useSelector(selectItem(['courses', uid]));
  // const courses_groups = useSelector(selectItem(['courses_groups', uid]));
  const quest_groups = useSelector(selectItem(['quest_groups', uid]));
  // const user_courses = useSelector(selectItem(['user_courses', uid]));
  const user_agent = useSelector(selectItem(['settings', 'user_agent']));
  const pin_code = useSelector(selectItem(['pin_code']));

  const stipendion = grants ? (
    (grants.stipendion && grants.stipendion.length) ? [...grants.stipendion].sort((item) => (Number(item.mentor_uid) === Number(user.uid) ? -1 : 0)) : []
  ) : null;

  useApiQuery({
    name: ['pin_code'],
    path: `/getpincode/${user.uid}/`,
    skip: !user
  });

  const sortByPosition = (items) => {
    return items.sort((prev, current) => prev.position - current.position);
  };

  const showAllOld = (
    <div className={styles.buttonQuests}>
      <Button butStyle="text-primary" to="/user-quests/#complete">Смотреть все</Button>
    </div>
  );

  const showAllActiveQuests = (
    <div className={styles.buttonQuests}>
      <Button butStyle="text-primary" to="/user-quests/#active">Смотреть все</Button>
    </div>
  );

  // const showAllActiveCourses = (
  //   <div className={styles.buttonQuests}>
  //     <Button butStyle="text-primary" to="/user-courses">Смотреть все</Button>
  //   </div>
  // );

  // const showAllRewards = (
  //   <div className={styles.buttonQuests}>
  //     <Button butStyle="text-primary" to="/rewards">Смотреть все</Button>
  //   </div>
  // );

  return (
    <MainLayout>
      <HeaderMentor user={user} pin_code={pin_code} />
      {(user && user.player) ? (
        <>
          {currentPlayer && currentPlayer.uid_gamer && <UserQuery uid={currentPlayer.uid_gamer} />}

          {(currentPlayer && info && info.tariff && info.tariff.message && info.tariff.is_visible && user_agent !== 'skillary-mobile') &&
            <Block>
              <TariffNotification tariff={info.tariff} user_type={user.type} uid_gamer={currentPlayer.uid_gamer} />
            </Block>
          }

          {/* <Block title="О сервисе">
            {user && <About user={currentPlayer} />}
          </Block>

          {/* <Block title="Награды для игрока" rightButton={showAllRewards}>
            {user && <Rewards user={currentPlayer} items={rewards_mentor} emptyText="Нет полученных наград" />}
          </Block> */}

          <Block title="Игры" infoSlug="games">
            <HpStories items={quest_groups && quest_groups.S} />
          </Block>

          <Block>
            {/* <div style={{ marginTop: (quests && quests.active && !Object.keys(quests.active).length) ? '32px' : 0 }}> */}
            <Balance
              user={user}
              score={info && { now: info.score_now, day: info.score_day, week: info.score_week, prevWeek: info.score_week_prev, total: info.reward_limit }}
              ambitions={ambitions && ambitions.active}
              stipendion={stipendion}
              // rewards={rewards_mentor}
            />
            {/* </div> */}
            <ButtonsContainer>
              <Button
                fullWidth={true}
                butStyle="primary"
                to="/quests-wheel"
              >
                Посмотреть квесты
              </Button>
            </ButtonsContainer>
          </Block>

          <Block
            title="Активные квесты"
            rightButton={showAllActiveQuests}
          >
            <QuestsList
              quests={quests && quests.active && sortByPosition(Object.values(quests.active).slice(0, 5))}
              type="small"
              direction="row"
              emptyText="Нет активных квестов"
              showSign={true}
            />
          </Block>

          {/* <Block
            title="Активные курсы"
            rightButton={showAllActiveCourses}
          >
            <UserCourses items={user_courses} courses={courses} emptyText="Нет активных курсов" />
          </Block> */}

          {/* <Block title="Курсы по темам" infoSlug="course_themes">
            <Courses items={courses_groups && courses_groups.T} emptyText="Нет тем для курсов" />
          </Block> */}

          {/* <Block title="Курсы по профессиям" infoSlug="course_prof">
            <Professions items={courses_groups && courses_groups.P} />
          </Block> */}

          <Block title="Пройденные квесты" rightButton={showAllOld}>
            <QuestsList
              quests={quests && quests.complete && Object.values(quests.complete).reverse().slice(0, 5)}
              type="small"
              direction="row"
              emptyText="Нет пройденных квестов"
            />
          </Block>
        </>
      ) : (
        <>
          <div className="text-center" style={{ marginTop: '60px' }}>Добавьте минимум 1 игрока чтобы он смог выполнять квесты</div>
          <ButtonsContainer>
            <Button
              to="/create-player"
              fullWidth={true}
              butStyle="primary"
              state={{ from: '/create' }}
              size="medium"
            >
              Добавить игрока
            </Button>
          </ButtonsContainer>
        </>
      )}
    </MainLayout>
  );
}

export default Home;
