import classNames from 'classnames';

import styles from './header-message.module.css';

const HeaderMessage = ({ content, position = 'right', icons }) => {
  if (!content) return null;
  return (
    <div className={styles.message}>
      <span className={classNames(styles.messageArrow, styles[position])} style={{
        backgroundImage: 'url(/images/game/message_tick_edge.svg)'
      }} />
      <div className={styles.content}>
        {icons ? icons.map(icon => <img className={styles.child} src={icon} alt="" key={icon} />) : null}
        <div className={styles.text} dangerouslySetInnerHTML={{ __html: content }} />
      </div>
    </div>
  );
};

export default HeaderMessage;