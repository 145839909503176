import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PinPad from '../../PinPad';
import Input from '../../../fields/Input';
import Modal from '../../../Modal';
import { setItem } from '../../../../store/app/actions';
import * as api from '../../../../helpers/api';

import styles from './login-mentor.module.css';

const LoginMentor = ({ user, mentor, onClose }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [ isShowPinModal, setShowPinModal ] = useState(false);
  const [ pinCode, setPinCode ] = useState('');
  const [ error, setError ] = useState(null);

  useEffect(() => {
    api
      .get(`/getpincode/${mentor.uid_mentor}/`)
      .then(response => {
        const result = response.data.response;
        if (result) {
          setShowPinModal(true);
        } else {
          const error = result.error ? result.error : 'Пин код для входа не установлен';
          dispatch(setItem(['notifications', 'pin_code'], { type: 'error', content: error }));
          onClose();
        }
      })
      .catch(error => {
        dispatch(setItem(['notifications', 'pin_code'], { type: 'error', content: 'Ошибка обработки запроса. Перезагрузите страницу и попробуйте позже' }));
      });

    // eslint-disable-next-line
  }, [mentor]);

  useEffect(() => {
    if (!mentor || pinCode.length < 4) return null;
    const phone = mentor.info ? mentor.info.phone : mentor.phone;

    api
      .get(`/auth/${phone}/${pinCode}/`)
      .then(response => {
        const result = response.data.response;

        if (result.user) {
          dispatch(setItem(['user'], result.user, { isHard: true }));
          navigate('/');
        } else {
          const error = result.error ? result.error : 'Неверный код авторизации';
          setError(error);
        }
      })
      .catch(error => {
        dispatch(setItem(['notifications', 'login'], { type: 'error', content: 'Ошибка обработки запроса. Перезагрузите страницу и попробуйте позже' }));
      });

    // eslint-disable-next-line
  }, [pinCode]);

  const handleClickButton = (number) => {
    setPinCode(state => state.length < 4 ? `${state}${number}` : state);
  };

  const handleClickRemove = () => {
    setPinCode(state => state.slice(0, state.length-1));
  };

  if (!mentor) return null;

  return (
    <>
      {/* <Modal
        viewStyle="bottom"
        open={isOpen}
        onClose={onClose}
      >
        <div className={styles.mentorLoginTitle}>
          Авторизоваться как наставник?
        </div>
        <div className={styles.mentorLoginButtons}>
          <Button butStyle="primary" onClick={() => handleClickLogin()}>Да</Button>
          <Button butStyle="secondary" onClick={onClose}>Нет</Button>
        </div>
      </Modal> */}

      <Modal
        viewStyle="bottom"
        open={isShowPinModal}
        onClose={onClose}
      >
        <div className={styles.root}>
          <div className={styles.title}>Введите пин-код</div>
          <Input mask="####" placeholder="0000" inputClass={styles.input} value={pinCode} readOnly={true} />
          {error && <div className={styles.error}>{error}</div>}
          <PinPad
            onClickButton={handleClickButton}
            onClickRemove={handleClickRemove}
          />
        </div>
      </Modal>
    </>
  );
};

export default LoginMentor;
