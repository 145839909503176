import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import classnames from 'classnames';
import GameHeader from '../../GameHeader';
import TabBar from '../../TabBar';
import FeedbackPopup from '../../modals/FeedbackPopup';
import BottomFaq from '../../modals/BottomFaq';
import { selectItem } from '../../../store/app/getters';
import { player_menu, mentor_menu, mentor_player_menu } from '../../../data/menu';

import styles from './game-layout.module.css';

const GameLayout = ({ game, children }) => {
  const user = useSelector(selectItem(['user']));
  const { pathname } = useLocation();
  const [ isSticked, setSticked ] = useState(false);
  const [ isChatOpen, setChatOpen ] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleScroll = (e) => {
    const { scrollHeight, scrollTop } = document.documentElement;
    setSticked((scrollHeight - 100) < (scrollTop + window.innerHeight));
  };

  const selected = isChatOpen ? 'chat' : 'game';

  return (
    <div className={styles.container} style={{ backgroundImage: 'url(/images/game/chat_bg.png)' }}>
      <GameHeader user={user} game={game} isChatOpen={isChatOpen} onClickChat={() => setChatOpen(!isChatOpen)} />
      <div className={classnames(styles.content, 'container')}>
        {children.map(child => {
          return (
            <div key={child.key} className={selected !== child.key && styles.hidden}>
              {child}
            </div>
          )
        })}
      </div>
      <div className={classnames(styles.bottom, isSticked && styles.sticked)}>
        {user.type === 'M' ? (
          <TabBar menu={(pathname === '/' || pathname === '/update') ? mentor_menu : mentor_player_menu} />
        ) : (
          <TabBar menu={player_menu} />
        )}
      </div>
      <FeedbackPopup />
      <BottomFaq />
    </div>
  );
};

export default GameLayout;