import { Link } from 'react-router-dom';
import classnames from 'classnames';
import ruPlural from '../../../helpers/ruPlural';
import NoItems from '../../NoItems';

import styles from './courses.module.css';

import { courses_bgs } from '../../../data/mock';

const Course = ({ item, group_uid }) => {
  const { uid, name, profesy, quests_count, hard_lvl } = item;
  const cid = uid+'';
  const bg = courses_bgs[cid.slice(-1)];

  if (!uid) return (
    <div className={classnames(styles.course, 'skeleton-loader')} />
  );

  return (
    <Link to={`/courses/${group_uid}/${uid}`} className={styles.course} style={{ backgroundImage: `url(${bg})` }}>
      <div className={styles.title}>{name}</div>
      <div className={styles.description}>{profesy}</div>
      <div className={styles.bottom}>
        {quests_count &&
          <div className={styles.count}>{quests_count} {ruPlural(quests_count, ['квест', 'квеста', 'квестов'])}</div>
        }
        <div className={styles.count}>Сложность: {hard_lvl}</div>
      </div>
    </Link>
  );
};

const Courses = ({ group_uid, items = [1, 2, 3, 4, 5], emptyText }) => {
  if (!Object.keys(items).length) return ( // no items
    <NoItems children={emptyText} />
  );

  return (
    <div className={styles.list}>
      {Object.values(items).map((item, index) => {
        return (
          <div className={styles.item} key={index}>
            <Course item={item} group_uid={group_uid} />
          </div>
        );
      })}
    </div>
  );
};

export default Courses;
