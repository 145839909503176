import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Navigate, useParams, useNavigate } from 'react-router-dom';
import classnames from 'classnames';
import { MainLayout } from '../../components/Layouts';
import Block from '../../components/Block';
import ButtonsContainer from '../../components/ButtonsContainer';
import Button from '../../components/Button';
import Rating from '../../components/Rating';
import { setItem } from '../../store/app/actions';
import { selectItem } from '../../store/app/getters';
import useApiQuery from '../../hooks/useApiQuery';
import * as api from '../../helpers/api';

import styles from './player.module.css';

const QuestSuccess = () => {
  let { qid } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [ rating, setRating ] = useState(0);

  const user = useSelector(selectItem(['user']));
  const quest = useSelector(selectItem(['user_quests', 'query', qid]));

  useEffect(() => {
    if (quest && quest.rating) setRating(quest.rating);
  }, [quest]);

  const { error } = useApiQuery({
    name: ['user_quests', 'query', qid],
    path: `/getUserQuest/${user.uid}/${qid}/`,
    mapper: (data = {}, prev) => {
      let next = {};

      for (let key in data) {
        if (data[key] && data[key].length) {
          for (let questKey in data[key]) { next = data[key][questKey]; }
        }
      }

      return {...prev, ...next};
    }
  });

  const onClickSubmit = () => {
    const fd = new FormData();
    fd.append('mark', rating);

    api
      .get(`/questrating/${qid}/${rating}/`)
      .then(response => {
        const result = response.data.response;

        if (result && result.success) {
          dispatch(setItem(['user_quests', 'query', qid, 'rating'], rating));
          dispatch(setItem(['user_quests', 'complete', qid, 'rating'], rating));
          dispatch(setItem(['notifications', 'rateQuest'], { type: 'success', content: result.success }));
          navigate('/');
        } else {
          const error = result.error ? result.error : 'Неизвестная ошибка';
          dispatch(setItem(['notifications', 'approveQuest'], { type: 'error', title: 'Ошибка', content: error }));
        }
      })
      .catch(error => {
        dispatch(setItem(['notifications', 'request'], { type: 'error', content: 'Ошибка обработки запроса. Перезагрузите страницу и попробуйте позже' }));
      });
  }

  if (error) return <Navigate replace to="/" />;
  if (!quest) return null;
  if (quest.is_cancel) return <Navigate replace to="/404" />;

  return (
    <MainLayout>
      <div className={styles.success}>
        <h2 className={styles.successTitle}>
          Поздравляем, ты заработал
          <span className={classnames(styles.totalBlockTitle, 'accent-text')}>+{quest.reward} баллов</span>
        </h2>

        <Block title={quest.info.name} align="center" headerAlign="center">
          <Rating defaultValue={quest.rating ? quest.rating : 0} onChange={value => setRating(value)}>Пожалуйста, оцените пройденный квест</Rating>
          <ButtonsContainer>
            <Button
              butStyle="primary"
              onClick={() => onClickSubmit()}
              fullWidth={true}
            >Завершить</Button>
          </ButtonsContainer>
        </Block>
      </div>
    </MainLayout>
  );
};

export default QuestSuccess;
