import { useRef, useState, useEffect } from 'react';
import classnames from 'classnames';

import styles from './balance.module.css';

const BalanceItem = (props) => {
  const childRef = useRef(null);
  const [isIntersecting, setIntersecting] = useState(false);

  useEffect(() => {
    const child = childRef.current;

    const observer = new IntersectionObserver(([entry]) => {
      setIntersecting(entry.isIntersecting);
      if (props.once && entry.isIntersecting) observer.unobserve(child);
    }, props.observerOptions);

    observer.observe(child);

    return () => observer.unobserve(child);

    // eslint-disable-next-line
  }, []);

  if (props.once && isIntersecting) return props.children;

  return (
    <div ref={childRef} className={classnames(styles.item, !isIntersecting && 'skeleton-loader')}>
      {isIntersecting && props.children}
    </div>
  );
};

export default BalanceItem;
