import { Link, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { ReactSVG } from 'react-svg';
import classnames from 'classnames';
import { setItem } from '../../store/app/actions';
import { selectItem } from '../../store/app/getters';

import styles from './tab-bar.module.css';

const LinkMenu = ({ item, isActive }) => {
  return (
    <Link className={classnames(styles.button, isActive && styles.active)} to={item.href}>
      <ReactSVG src={item.icon} className={styles.icon} wrapper="span" />
      <span className={styles.name}> {item.name} </span>
    </Link>
  );
};

const ButtonMenu = ({ item, isActive }) => {
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(setItem(['common', 'side_menu', 'open'], true));
  };

  return (
    <button className={classnames(styles.button, isActive && styles.active)} onClick={handleClick}>
      <ReactSVG src={item.icon} className={styles.icon} wrapper="span" />
      <span className={styles.name}> {item.name} </span>
    </button>
  );
};

const checkIsActive = (location, menuItem) => {
  const active = menuItem.routes ? menuItem.routes.filter(value => location.startsWith(value)) : [];
  if (active.length || menuItem.href === location) return true;
}

const TabBar = ({ menu }) => {
  const user_agent = useSelector(selectItem(['settings', 'user_agent']));
  let { pathname } = useLocation();

  if (!menu || !menu.length) return null;

  return (
    <div className={styles.main}>
      <div className={classnames(styles.list, 'container')}>
        {menu.map((item, index) => {
          if (item.hidden && user_agent !== 'skillary-mobile') return null;
          else if (item.action === 'open-menu') return (
            <div className={styles.item} key={index}>
              <ButtonMenu item={item} isActive={checkIsActive(pathname, item)} />
            </div>
          )
          else return (
            <div className={styles.item} key={index}>
              <LinkMenu item={item} isActive={checkIsActive(pathname, item)} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default TabBar;
