import { useState } from 'react';
import { ReactSVG } from 'react-svg';
import classnames from 'classnames';

import styles from './skills.module.css';

const SkillItem = ({ skill, isShowDetails, onClickDetails }) => {
  const { uid, description, icon, val, name, reward, skills } = skill;
  let percent = (reward && reward.percent) ? reward.percent : 100;
  if (!val && val === 0) percent = 0;

  const hasSkills = skills && Object.keys(skills) && !Array.isArray(skills);
  const showDescr = reward && reward.value && (reward.desc || hasSkills);

  return (
    <div className={styles.skill}>
      <div className={styles.skillContent}>
        <img src={icon} className={styles.icon} style={{ borderColor: name, fill: name }} alt="" />
        <div className={classnames(styles.name, showDescr && styles.withDescr)}>
          {description}
        </div>
        <div className={styles.status}>
          <div className={styles.bar}>
            <div className={styles.barBg} style={{ background: name }}></div>
            <div className={styles.progress} style={{ minWidth: `${percent}%`, background: name }}>
              {val}
            </div>
          </div>
          {(reward && reward.value) ?
            <div className={styles.reward}>
              <div className={styles.rewardIconWrap}>
                <img src={icon} className={styles.rewardIcon} style={{ borderColor: name, fill: name }} alt="" />
              </div>
              <span className={styles.rewardValue}>{reward.value}</span>
            </div>
          : null}
        </div>
      </div>
      <div className={classnames(styles.skillInfo, showDescr && styles.withValue)}>
        {(reward && reward.desc) &&
          <div className={classnames(styles.description, icon && styles.withIcon)}>{reward.desc}</div>
        }
        {(hasSkills && !isShowDetails) &&
          <div className={styles.info}>
            <span className={styles.link} onClick={() => onClickDetails(uid)}>
              Детально
              <ReactSVG src="/images/icon-drop-down.svg" />
            </span>
          </div>
        }
      </div>
      {isShowDetails &&
        <div className={styles.skillList}>
          {Object.values(skills).map(({ uid, description, val }, index) => (
            <div className={styles.skillListItem} key={uid + index}>
              <div className={styles.bar}>
                <div className={styles.barBg} style={{ background: name }}></div>
                <div className={styles.progress} style={{ width: '100%', background: name }}>
                  {val}
                </div>
              </div>
              <span>
                {description}
              </span>
            </div>
          ))}
          <div className={styles.skillInfo}>
            <span></span>
            <span className={classnames(styles.link, styles.open)} onClick={() => onClickDetails(null)}>
              Скрыть
              <ReactSVG src="/images/icon-drop-down.svg" />
            </span>
          </div>
        </div>
      }
    </div>
  )
};

const Skills = ({ skills, showDivider }) => {
  const [ showDetailsUid, setShowDetails ] = useState(false);

  if (!skills) return null;

  return (
    <div className={styles.list}>
      {Object.values(skills).map((item, index) => {
        if (!item.uid) return null;
        return (
          <div className={classnames(styles.item, showDivider && styles.divide)} key={index}>
            <SkillItem skill={item} isShowDetails={showDetailsUid === item.uid} onClickDetails={uid => setShowDetails(uid)} />
          </div>
        );
      })}
    </div>
  )
};

export default Skills;