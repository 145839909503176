import { useDispatch, useSelector } from 'react-redux';
import * as api from '../../../../helpers/api';
import { MainLayout } from '../../../../components/Layouts';
import Block from '../../../../components/Block';
import Button from '../../../../components/Button';
import Form from '../../../../components/Form';
import Input from '../../../../components/fields/Input';
import DateInput from '../../../../components/fields/DateInput';
import { selectItem } from '../../../../store/app/getters';
import { setItem } from '../../../../store/app/actions';

const Tasks = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectItem(['user']));

  const inputs = [
    { id: 'name', name: 'name', component: Input, validation: 'string',
      props: { title: 'Задайте название задания', placeholder: 'Введите текст', fullWidth: true }
    },
    { id: 'descr', name: 'descr', component: Input, validation: 'string',
      props: { title: 'Опишите суть задания ', placeholder: 'Введите текст', fullWidth: true }
    },
    { id: 'date', name: 'date', component: DateInput, validation: 'date',
      props: { title: 'Укажите дату, к которой оно должно быть выполнено ', placeholder: '10.06.2022', fullWidth: true }
    },
    { id: 'reward', name: 'reward', component: Input, validation: 'string',
      props: { title: 'Награда за выполнение квеста в баллах (XP)', placeholder: '1000 XP', fullWidth: true }
    }
  ];

  const onSubmit = data => {
    const fd = new FormData();
    for (let key in data) {
      fd.append(`params[${key}]`, data[key]);
    }

    api
      .post(`/updateUserProfile/${user.uid}/`, fd)
      .then(response => {
        const result = response.data.response;
        if (result.success) {
          dispatch(setItem(['user'], result.user));
        } else {
          dispatch(setItem(['notifications', 'updateUser'], { type: 'error', content: result.error }));
        }
      })
      .catch(error => {
        dispatch(setItem(['notifications', 'updateUser'], { type: 'error', content: 'Ошибка обработки запроса. Перезагрузите страницу и попробуйте позже' }));
      });
  };

  const buttons = (
    <Button
      type="submit"
      fullWidth={true}
      butStyle="primary"
      size="small"
    >
      Создать задание
    </Button>
  );

  return (
    <MainLayout>
      <Block title="Задания" headerAlign="center" showBackButton={true}>
        <Form inputs={inputs} onSubmit={onSubmit} buttons={buttons} />
      </Block>
    </MainLayout>
  );
};

export default Tasks;