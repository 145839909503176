import { useSelector, useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { MainLayout } from '../../../components/Layouts';
import Block from '../../../components/Block';
import ButtonsContainer from '../../../components/ButtonsContainer';
import Button from '../../../components/Button';
import useApiQuery from '../../../hooks/useApiQuery';
import CoursesQuests from '../../../components/courses/CoursesQuests';
import PointsProgress from '../../../components/PointsProgress';
import Modal from '../../../components/Modal';
import { selectItem } from '../../../store/app/getters';
import { setItem } from '../../../store/app/actions';
import getDuration from '../../../helpers/getDuration';
import * as api from '../../../helpers/api';

import styles from './courses.module.css';
import { forwardRef } from 'react';

const UserCourseQuests = () => {
  const { cid } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector(selectItem(['user']));

  const { data: course } = useApiQuery({
    name: ['user_courses', cid],
    path: `/getUserCourses/${user.uid}/${cid}/`,
    skip: !user,
    mapper: (data = []) => {
      if (data && data.length) return data[0];
      return data;
    }
  });

  const durationText = (course && course.quests) ? getDuration(course.quests[course.quests.length - 1].lifetime_end) : '';
  // const isCourseComplete = course?.quests.filter(({ is_cancel, end }) => (is_cancel || end));

  const handleClickComplete = () => {
    api
      .get(`/addUserCourse/${user.uid}/${cid}/COMPLETE/`)
      .then(response => {
        const result = response.data.response;
        if (result) {
          navigate(`/`);
          dispatch(setItem(['notifications', 'complete_course'], { type: 'success', content: 'Курс был успешно завершен!' }));
        } else {
          const error = response.data.error ? response.data.error : 'Ошибка обработки запроса'
          dispatch(setItem(['notifications', 'complete_course'], { type: 'error', content: error }));
        }
      })
      .catch(error => {
        dispatch(setItem(['notifications', 'complete_course'], { type: 'error', content: 'Ошибка обработки запроса. Перезагрузите страницу и попробуйте позже' }));
      });
  };

  const handleClickCancel = () => {
    api
      .get(`/addUserCourse/${user.uid}/${cid}/CANCEL/`)
      .then(response => {
        const result = response.data.response;
        if (result) {
          navigate(`/`);
          dispatch(setItem(['notifications', 'cancel_course'], { type: 'success', content: 'Курс был успешно отменен!' }));
        } else {
          const error = response.data.error ? response.data.error : 'Ошибка обработки запроса'
          dispatch(setItem(['notifications', 'cancel_course'], { type: 'error', content: error }));
        }
      })
      .catch(error => {
        dispatch(setItem(['notifications', 'cancel_course'], { type: 'error', content: 'Ошибка обработки запроса. Перезагрузите страницу и попробуйте позже' }));
      });
  };

  const cancelControl = forwardRef(({ onClick }, ref) => (
    <Button
      butStyle="secondary"
      size="medium"
      fullWidth={true}
      onClick={onClick}
    >
      Отменить курс
    </Button>
  ));

  const isSubmitActive = (course && course.quests) ? course.quests.filter(({ status }) => status === 'C').length === course.quests.length : false;

  return (
    <MainLayout>
      <Block title={course ? course.course_name : <>&nbsp;</>} headerAlign="center" showBackButton={true}>
        <div className={styles.progress}>
          <div className={styles.progressTitle}>
            Осталось квестов: <span className="accent-text">{course && `${course.need - course.complete} из ${course.need}`}</span>
          </div>
          <div className={styles.progressBar}>
            <PointsProgress current={course && course.complete} total={course && course.need} />
          </div>
          <div className={styles.progressDescr} dangerouslySetInnerHTML={{ __html: `Осталось времени: <span class="accent-text">${durationText}</span>` }} />
        </div>
        <CoursesQuests items={course && course.quests} checkLock={true} />
        <ButtonsContainer>
          <Button
            butStyle="primary"
            size="medium"
            fullWidth={true}
            disabled={!isSubmitActive}
            onClick={() => isSubmitActive && handleClickComplete()}
          >Завершить курс</Button>

          <Modal
            title="Отменить"
            viewStyle="bottom"
            control={cancelControl}
            buttons={[
              { text: 'Подтвердить', action: 'click', style: 'primary', onClick: () => handleClickCancel() },
              { text: 'Закрыть', action: 'close', style: 'secondary' }
            ]}
          >
            Вы уверены, что хотите отменить курс?
          </Modal>
        </ButtonsContainer>
      </Block>
    </MainLayout>
  );
};

export default UserCourseQuests;
