import { useState, useEffect } from 'react';
import { ReactSVG } from 'react-svg';
import Groups from './Groups';
import Elements from './Elements';
import Button from '../Button';
import Modal from '../Modal';

import styles from './avatar-constructor.module.css';

const baseUrl = process.env.REACT_APP_PUCLIC_URL;

const AvatarConstructor = ({ user, constructor, onClickSave, payStatus, onClickBuy }) => {
  const [ selectedGroup, setSelectedGroup ] = useState(Object.values(constructor)[0].uid);
  const [ selected, setSelected ] = useState(user.avatar_this.reduce((prev, current) => ({ ...prev, [current.type]: current }), {}));
  const [ isShowModalBuy, setShowModalBuy ] = useState(false);
  const [ itemToPay, setItemToPay ] = useState(null);
  const [ isShowModalStatus, setShowModalStatus ] = useState(null);

  useEffect(() => {
    setShowModalStatus(!!payStatus);
  }, [payStatus]);

  const handleClickGroup = (id) => {
    setSelectedGroup(id);
    setSelected(state => Object.keys(state).reduce((prev, current) => {
      return ({
        ...prev,
        [current]: (state[current].need_pay && state[current].prev) ? { ...state[current], need_pay: false, component: state[current].prev } : { ...state[current] }
      });
    }, {}));
  };

  const handleClickItem = ({ uid, parent_uid, price, is_pay, url }) => {
    const isSelected = selected[parent_uid] && selected[parent_uid].component === `${parent_uid}-${uid}`;
    if (isSelected) removeSelectedItem(parent_uid);
    else setSelectedItem({ uid, parent_uid, need_pay: (price && !is_pay), url, price });
  };

  const setSelectedItem = ({ uid, parent_uid, need_pay, url, price }) => {
    setSelected(prev => ({
      ...prev,
      [parent_uid]: {
        type: parent_uid,
        component: `${parent_uid}-${uid}`,
        need_pay: need_pay,
        prev: prev[parent_uid] && (prev[parent_uid].need_pay ? selected[parent_uid].prev : selected[parent_uid].component)
      }
    }));

    if (need_pay) setItemToPay({ uid, parent_uid, url, price });
  };

  const removeSelectedItem = (parent_uid) => {
    setSelected(prev => {
      let newSelected = { ...prev };
      delete newSelected[parent_uid];
      return newSelected
    });
  };

  const getPath = () => Object.values(selected).sort((a, b) => {
    if (constructor[a.type].priority > constructor[b.type].priority) return 1;
    if (constructor[a.type].priority < constructor[b.type].priority) return -1;
    return 0;
  }).reduce((prev, cur) => [ ...prev, `${cur.component}` ], []).join(',');

  return (
    <div className={styles.root}>
      <div className={styles.main}>
        <div className={styles.left}>
          <div className={styles.balance}>
            <ReactSVG src="/images/crystal.svg" className={styles.balanceIcon} wrapper="span" />
            {user.crystalls} кристалов
          </div>
          <div className={styles.imageContainer}>
            <img
              // src={`${baseUrl}/api/generateAvatar/${Object.values(selected).join(',')}/`}
              src={Object.keys(selected).length ? `${baseUrl}/api/generateAvatar/${getPath()}/` : user.avatar}
              className={styles.image}
              alt=""
            />
          </div>
          <div className={styles.buttonContainer}>
            {(selected && Object.keys(selected).length && Object.values(selected).filter(({ need_pay }) => !!need_pay).length) ? (
              <Button
                onClick={() => setShowModalBuy(true)}
                butStyle="primary"
                fullWidth={true}
                size="small"
                className={styles.button}
              >
                Купить
              </Button>
            ) : (
              <Button
                onClick={() => onClickSave(getPath())}
                butStyle="primary"
                fullWidth={true}
                size="small"
                className={styles.button}
              >
                Сохранить
              </Button>
            )}
          </div>
        </div>
        <div className={styles.right}>
          <Groups
            constructor={constructor}
            selectedGroup={selectedGroup}
            onSelectGroup={handleClickGroup}
          />
        </div>
      </div>
      <div className={styles.bottom}>
        <Elements
          selectedGroup={selectedGroup}
          selected={selected}
          constructor={constructor}
          onClickItem={handleClickItem}
        />
      </div>
      {itemToPay &&
        <Modal
          viewStyle="bottom"
          open={isShowModalBuy}
          onClose={() => setShowModalBuy(false)}
          buttons={[
            {
              text: 'Да,купить',
              style: 'primary',
              onClick: () => onClickBuy(itemToPay)
            },
            {
              action: 'close',
              text: 'Нет, я передумал',
              style: 'secondary',
            }
          ]}
        >
          <div className={styles.modalImageContainer}>
            <img src={`${baseUrl}/avatar_components/${itemToPay.url}`} className={styles.image} alt="" />
          </div>
          <div className={styles.modalTitle}>
            Купить за {itemToPay.price}<ReactSVG src="/images/crystal.svg" className={styles.modalPriceIcon} wrapper="span" /> ?
          </div>
        </Modal>
      }
      <Modal
        viewStyle="bottom"
        open={isShowModalStatus}
        onClose={() => setShowModalStatus(false)}
        buttons={[
          {
            text: payStatus === 'success' ? 'Перейти к Аватару' : 'Вернуться в магазин',
            action: 'close',
            style: 'secondary'
          }
        ]}
      >
        <div className={styles.modalImageContainer}>
          {itemToPay && <img src={`${baseUrl}/avatar_components/${itemToPay.url}`} className={styles.image} alt="" />}
        </div>
        <div className={styles.modalTitle}>
          {payStatus === 'success' && 'Успешная покупка!'}
          {payStatus === 'errorPrice' && 'Очень жаль, но для покупки нехватает кристалов :('}
          {!(payStatus === 'success' || payStatus === 'errorPrice') && payStatus}
        </div>
        <div className={styles.modalDescr}>
          {payStatus === 'success' && 'Его уже можно использовать для Ававтара!'}
        </div>
        <br />
      </Modal>
    </div>
  );
};

export default AvatarConstructor;
