import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { MainLayout } from '../../../components/Layouts';
import Block from '../../../components/Block';
import useApiQuery from '../../../hooks/useApiQuery';
import CoursesQuests from '../../../components/courses/CoursesQuests';
import PointsProgress from '../../../components/PointsProgress';
import { selectItem } from '../../../store/app/getters';
import getDuration from '../../../helpers/getDuration';

import styles from './courses.module.css';

const UserCourseQuests = () => {
  const { cid } = useParams();
  const user = useSelector(selectItem(['user']));
  const currentPlayer = user.current_player;
  const uid = currentPlayer?.uid_gamer;

  const { data: course } = useApiQuery({
    name: ['user_courses', uid, cid],
    path: `/getUserCourses/${uid}/${cid}/`,
    mapper: (data = []) => {
      if (data && data.length) return data[0];
      return data;
    }
  });

  const durationText = (course && course.quests) ? getDuration(course.quests[course.quests.length - 1].lifetime_end) : '';

  return (
    <MainLayout>
      <Block title={course ? course.course_name : <>&nbsp;</>} headerAlign="center" showBackButton={true}>
        <div className={styles.progress}>
          <div className={styles.progressTitle}>
            Осталось квестов: <span className="accent-text">{course && `${course.need - course.complete} из ${course.need}`}</span>
          </div>
          <div className={styles.progressBar}>
            <PointsProgress current={course && course.complete} total={course && course.need} />
          </div>
          <div className={styles.progressDescr} dangerouslySetInnerHTML={{ __html: `Осталось времени: <span class="accent-text">${durationText}</span>` }} />
        </div>
        <CoursesQuests items={course && course.quests} checkLock={true} />
      </Block>
    </MainLayout>
  );
};

export default UserCourseQuests;
