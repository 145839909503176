import { useRef, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useDispatch } from 'react-redux';
import Message from './Message';
import HeaderMessage from './HeaderMessage';
import Reward from './Reward';
import { setItem } from '../../store/app/actions';

import { childGame } from '../../scripts/game';

import styles from './game-map.module.css';

const GameMap = ({ user, game, gameParams, onAddNewPoint, onChangeCity, afterInit, onCityOut }) => {
  const dispatch = useDispatch();
  const ref = useRef(null);
  const anim = useRef(null);
  const [ reward, setReward ] = useState(null);

  const message = (domElement, content, audio, gallery, button, arrowPositions, type, exitFunction) => {
    ReactDOM.render(
      (<Message
        type={type}
        audio={audio}
        gallery={gallery}
        content={content}
        button={button}
        arrowPositions={arrowPositions}
        onExit={exitFunction}
      />),
      domElement
    );
  };

  const headerMessage = (domElement, content, position, icons) => {
    ReactDOM.render(
      (<HeaderMessage
        content={content}
        position={position}
        icons={icons}
      />),
      domElement
    );
  };

  let gameRef = useRef(null);

  useEffect(() => {
    if (ref.current && game && !gameRef.current) {
      dispatch(setItem(['game_params', 'energy_prev'], game.energy));

      gameRef.current = new childGame({
        game,
        container: ref.current,
        animationContainer: anim.current,
        reactMessage: message,
        reactHeaderMessage: headerMessage,
        addNewPoint: onAddNewPoint,
        changeCity: onChangeCity,
        afterInit: afterInit,
        onCityOut: onCityOut
      });
    }

    // eslint-disable-next-line
  }, [game, ref]);

  useEffect(() => {
    if (game && gameParams.energy_prev && (game.energy - gameParams.energy_prev > 0)) {
      const next = Object.values(game.points).reduce((prev, current) => prev.is_active ? prev : current, {});

      if (next) {
        setReward({
          points: game.energy - gameParams.energy_prev,
          need: next.need
        });

        setTimeout(() => {
          setReward(null);
        }, [3000]);
      }
    }

    // eslint-disable-next-line
  }, [game, gameParams]);

  return (
    <div className={styles.root}>
      <div className={styles.image} ref={ref} />
      <div className={styles.animation} ref={anim} />
      {reward &&
        <Reward
          points={reward.points}
          need={reward.need}
        />
      }
    </div>
  );
};

export default GameMap;