import styles from './points-progress.module.css';

const PointsProgress = ({ current, total, color }) => {
  const percent = current <= total ? (current * 100) / total : 100;

  return (
    <div className={styles.bar}>
      <div className={styles.progress} style={{ width: `${percent}%`, backgroundColor: color && color }}>
        <span className={styles.progressText}> {current} </span>
      </div>
      <span className={styles.totalText}> {total} </span>
    </div>
  );
};

export default PointsProgress;
