import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import * as api from '../../helpers/api';
import { setItem } from '../../store/app/actions';

const Auth = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token } = useParams();

  if (!token) navigate('/login');

  api
    .get(`/childauth/${token}/`)
    .then(response => {
      const result = response.data.response;

      if (result.user) {
        dispatch(setItem(['user'], result.user));
      } else {
        const error = result.error ? result.error : 'Неверная ссылка авторизации'
        dispatch(setItem(['notifications', 'login'], { type: 'error', content: error }));
        navigate('/login');
      }
    })
    .catch(error => {
      dispatch(setItem(['notifications', 'login'], { type: 'error', content: 'Ошибка обработки запроса. Перезагрузите страницу и попробуйте позже' }));
    });

    return null;
};

export default Auth;
