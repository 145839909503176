import { ReactSVG } from 'react-svg';
import classnames from 'classnames';

import styles from './avatar-constructor.module.css';

const baseUrl = process.env.REACT_APP_PUCLIC_URL;

const Elements = ({ selectedGroup, selected, constructor, onClickItem }) => {
  return (
    <div className={styles.elementsWrap}>
      <div className={styles.elements}>
        {selectedGroup &&
          constructor[selectedGroup].variants.map(({ uid, parent_uid, url, is_pay, price }, index) => {
            const isSelected = selected[parent_uid] && selected[parent_uid].component === `${parent_uid}-${uid}`;
            return (
              <div
                className={classnames(styles.element, isSelected && styles.active, is_pay && styles.paid)}
                onClick={() => onClickItem({ uid, parent_uid, price, is_pay, url })}
                key={index}
              >
                <img
                  src={`${baseUrl}/avatar_components/${url}`}
                  className={styles.image}
                  alt=""
                />
                {(price && !is_pay) ?
                  <div className={styles.price}>
                    <div className={styles.priceValue}>
                      <ReactSVG src="/images/crystal.svg" className={styles.priceIcon} wrapper="span" />
                      {price}
                    </div>
                  </div>
                : null}
                {is_pay &&
                  <div className={styles.status}>
                    Куплен <ReactSVG src="/images/icon-checked-circle.svg" className={styles.statusIcon} wrapper="span" />
                  </div>
                }
              </div>
            );
          })
        }
      </div>
    </div>
  );
};

export default Elements;
