import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import * as api from '../../../../helpers/api';
import useApiQuery from '../../../../hooks/useApiQuery';
import { MainLayout } from '../../../../components/Layouts';
import Block from '../../../../components/Block';
import InputsContainer from '../../../../components/fields/InputsContainer';
import ButtonsContainer from '../../../../components/ButtonsContainer';
import Quantity from '../../../../components/fields/Quantity';
import Select from '../../../../components/fields/Select';
import Input from '../../../../components/fields/Input';
import Button from '../../../../components/Button';
import Modal from '../../../../components/Modal';
import { setItem, deleteItem } from '../../../../store/app/actions';
import { selectItem } from '../../../../store/app/getters';

import styles from './Ambitions.module.css';

const CreateAmbition = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const [ skillUid, setSkillUid ] = useState(null);
  const [ values, setValues ] = useState({});
  const [ prizes, setPrizes ] = useState([]);
  const [ showCustomField, setShowCustomField ] = useState(false);
  const [ isSubmitActive, setSubmitActive ] = useState(false);
  const user = useSelector(selectItem(['user']));
  const currentPlayer = user.current_player;
  const uid = currentPlayer?.uid_gamer;
  const [ isShowNotice, setShowNotice ] = useState(true);

  const { data: constructor } = useApiQuery({
    name: ['constructor', uid],
    path: `/getconstructor/${uid}/`,
    skip: !uid
  });

  useEffect(() => {
    let rewards;
    let showCustom;

    if (values.skill_uid && values.skill_uid !== skillUid) {
      setSkillUid(null);
      setTimeout(() => {
        setSkillUid(values.skill_uid);
        setValues({ skill_uid: values.skill_uid });
      }, 100);
    }

    if (values.skill_uid && values.value && values.value > constructor[values.skill_uid].val) {
      rewards = constructor[values.skill_uid].rewards;
      const options = Object.values(rewards).map(({ uid, description, is_custom }) => ({ value: uid, label: description, primary: is_custom }));
      if (prizes !== options) setPrizes(options);
    } else {
      setPrizes([]);
    }

    if (values.skill_uid && rewards && values.reward_uid && rewards[values.reward_uid] && rewards[values.reward_uid].is_custom) {
      showCustom = true;
      setShowCustomField(true);
    } else {
      showCustom = false;
      setShowCustomField(false);
      if (values.custom_name) setValues({ ...values, custom_name: null });
    }

    if (values.skill_uid
      && values.value && values.value > constructor[values.skill_uid].val
      && values.reward_uid
      && (!showCustom || values.custom_name)
    ) setSubmitActive(true);
    else setSubmitActive(false);

    // eslint-disable-next-line
  }, [values]);

  useEffect(() => {
    setValues({});
    setSkillUid(null);
    // eslint-disable-next-line
  }, [uid]);

  if (!user || !uid) return null;

  const skills = constructor ? Object.values(constructor).map(({ uid, description }) => ({ value: uid, label: description })) : [];

  const Tip = () => {
    if (!constructor || !skillUid || !constructor[skillUid] || !constructor[skillUid].tip) return null;
    return (
      <div style={{ color: constructor[skillUid].tip_color }}>{constructor[skillUid].tip}</div>
    );
  };

  const inputs = [
    { id: 'skill_uid', name: 'skill_uid', component: Select,
      props: {
        title: 'Создать новую цель', label: 'Выберите навык', options: skills, value: values ? values.skill_uid : '',
        placeholder: 'Выбрать', fullWidth: true, onChange: ({ target }) => setValues({ ...values, [target.name]: target.value })
      }
    },
    {
      name: 'tip', component: Tip
    },
    { id: 'value', name: 'value', component: skillUid ? Quantity : 'div',
      props: {
        title: 'Значение для достижения цели', placeholder: '0', fullWidth: true, min: constructor ? constructor[skillUid]?.val : 0,
        defaultValue: constructor ? constructor[skillUid]?.val : 0,
        onChange: ({ target }) => setValues({ ...values, value: target.value })
      }
    },
    // { component: skillUid ? Quantity : 'div', name: 'default_val',
    //   props: {
    //     title: 'Текущее значение', defaultValue: constructor && constructor[skillUid]?.val,
    //     placeholder: '0', fullWidth: true, disabled: true
    //   }
    // },
    { id: 'reward_uid', name: 'reward_uid', component: (skillUid && prizes.length) ? Select : 'div',
      props: {
        label: 'Выберите приз', options: prizes, placeholder: 'Выбрать', fullWidth: true, hidden: !prizes.length, value: values ? values.reward_uid : '',
        onChange: ({ target }) => setValues({ ...values, [target.name]: target.value, reward_name: target.label })
      }
    },
    { id: 'custom_name', name: 'custom_name', component: (skillUid && showCustomField) ? Input : 'div',
      props: {
        placeholder: 'Введите название приза', fullWidth: true, hidden: !showCustomField,
        onChange: ({ target }) => setValues({ ...values, [target.name]: target.value })
      }
    },
  ];

  const onSubmit = () => {
    const data = {...values};

    if (data.custom_name) data.reward_name = data.custom_name;

    const fd = new FormData();
    for (let key in data) {
      if (key !== 'tip' && key !== 'default_val') fd.append(key, data[key]);
    }
    fd.append('player_uid', uid);

    api
      .post(`/addPlayerAmbition/`, fd)
      .then(response => {
        const data = response.data;
        if (data.success) {
          dispatch(deleteItem(['api'], `/getPlayerAmbitions/${uid}/`));
          dispatch(deleteItem(['api'], `/getconstructor/${uid}/`));
          dispatch(deleteItem(['constructor'], uid));
          dispatch(setItem(['players', uid, 'skills'], data.skills, { isHard: true }));
          dispatch(setItem(['user', 'current_player', 'skills'], data.skills, { isHard: true }));
          dispatch(setItem(['notifications', 'addPlayerAmbition'], { type: 'success', content: 'Цель добавлена' }));
          navigate('/ambitions');
        } else {
          dispatch(deleteItem(['api'], `/getconstructor/${uid}/`));
          dispatch(deleteItem(['constructor'], uid));
          dispatch(setItem(['notifications', 'addPlayerAmbition'], { type: 'error', content: data.error }));
        }
      })
      .catch(error => {
        dispatch(setItem(['notifications', 'request'], { type: 'error', content: 'Ошибка обработки запроса. Перезагрузите страницу и попробуйте позже' }));
      });
  };

  const buttons = (
    <Button
      type="submit"
      fullWidth={true}
      butStyle="primary"
      onClick={(e) => { e.preventDefault(); onSubmit(); }}
      disabled={!isSubmitActive || (showCustomField && !values.custom_name)}
    >
      Сохранить
    </Button>
  );

  return (
    <MainLayout>
      <Block
        title="Конструктор целей"
        headerAlign="center"
        showBackButton={true}
      >
        {constructor ? (
          <form>
            <InputsContainer className={styles.fields}>
              {inputs.map(({ id, name, props, component: Component }, index) => (
                <Component id={id} name={name} key={name + index} inputClass="dark" {...props} />
              ))}
            </InputsContainer>
            <ButtonsContainer className={styles.buttons}>
              {buttons}
            </ButtonsContainer>
          </form>
        ) : (
          <div>
            <div className={classNames(styles.plInputTitle, 'skeleton-loader')}></div>
            <div className={classNames(styles.plInputLabel, 'skeleton-loader')}></div>
            <div className={classNames(styles.plInput, 'skeleton-loader')}></div>
            <ButtonsContainer className={styles.buttons}>
              {buttons}
            </ButtonsContainer>
          </div>
        )}
      </Block>
      <Modal
        title="Зачем это нужно"
        viewStyle="bottom"
        open={isShowNotice}
        onClose={() => setShowNotice(false)}
        buttons={[
          { text: 'Хорошо', action: 'close', style: 'primary' }
        ]}
      >
        <div className="text-center">
          Настройте цели для Игрока, чтобы задать правильный вектор развития ребёнка
        </div>
      </Modal>
    </MainLayout>
  );
};

export default CreateAmbition;
