import FSSlider from '../../../components/FSSlider';
import { MainLayout } from '../../../components/Layouts';

import { guide } from '../../../data/mock';

const Guide = () => {
  return (
    <MainLayout contentValign="stretch">
      <FSSlider items={guide} exitOnEnd={true} />
    </MainLayout>
  );
};

export default Guide;