import classnames from 'classnames';
import QuestItem from '../QuestItem/QuestItem';
import QuestItemUser from '../QuestItem/QuestItemUser';
import QuestItemComplete from '../QuestItem/QuestItemComplete';
import QuestItemCancel from '../QuestItem/QuestItemCancel';
import QuestItemSmall from '../QuestItemSmall';
import QuestItemSimple from '../QuestItemSimple';
import NoItems from '../../NoItems';

import styles from './quests-list.module.css';

const QuestsList = ({ quests  = [1, 2, 3, 4, 5], direction = 'column', type = 'default', showRate, showSign, emptyText }) => {
  if (!quests.length && emptyText) return ( // no items
    <NoItems children={emptyText} />
  );

  return (
    <div className={classnames(styles.list, styles[direction])}>
      {quests.map((quest, index) => (
        <div className={styles.listItem} key={index}>
          {type === 'default' && <QuestItem quest={quest} />}
          {type === 'user' && <QuestItemUser quest={quest} showSign={showSign} />}
          {type === 'complete' && <QuestItemComplete quest={quest} showRate={showRate} />}
          {type === 'cancelled' && <QuestItemCancel quest={quest} />}
          {type === 'small' && <QuestItemSmall quest={quest} /> }
          {type === 'simple' && <QuestItemSimple quest={quest} />}
        </div>
      ))}
    </div>
  );
};

export default QuestsList;
