import { useState } from 'react';
import { useSelector } from 'react-redux';
import { MainLayout } from '../../components/Layouts';
import Switch from '../../components/fields/Switch';
import PlayersList from '../../components/PlayersList';
import Block from '../../components/Block';
import ButtonsContainer from '../../components/ButtonsContainer';
import Button from '../../components/Button';
import useApiQuery from '../../hooks/useApiQuery';
import { selectItem } from '../../store/app/getters';

const Rating = () => {
  const user = useSelector(selectItem(['user']));
  const [ type, setType ] = useState('class');

  const tabs = [
    {
      label: 'В классе',
      value: 'class'
    },
    {
      label: 'В школе',
      value: 'school'
    }
  ];

  const { data: rating } = useApiQuery({
    name: ['rating'],
    path: `/ratingschoolclass/${user.uid}/${user.school}/${user.class}/${user.class_sign}/`,
    skip: !user || !user.school,
    mapper: (data = {}, prev) => {
      let next = {
        class: {},
        school: {}
      };
      const { rating } = data;

      if (rating.class.length) {
        for (let key in rating.class) {
          const item = rating.class[key];
          next.class[item.rank_class] = item;
        }
      }

      if (rating.school.length) {
        for (let key in rating.school) {
          const item = rating.school[key];
          next.school[item.rank_school] = item;
        }
      }

      return next;
    }
  });

  if (!user) return null;

  const onChangeTab = (e) => {
    setType(e.target.value);
  };

  return (
    <MainLayout>
      <Block title="Мой рейтинг" headerAlign="center" infoSlug="raiting" showBackButton={true}>
        {user.school ? (
          <>
            <Switch
              id="school"
              name="school"
              options={tabs}
              value={type}
              view="radio"
              onChange={(e) => onChangeTab(e)}
              inputClass="dark"
            />
            {rating &&
              <PlayersList players={rating[type]} current={user.uid} type={type} />
            }
          </>
        ) : (

        <>
          <div className="text-center" style={{ marginTop: '60px' }}>Заполните аккаунт чтобы просматривать рейтинг</div>
          <ButtonsContainer>
            <Button
              to="/update"
              fullWidth={true}
              butStyle="primary"
              size="medium"
            >
              В настройки
            </Button>
          </ButtonsContainer>
        </>
        )}
      </Block>
    </MainLayout>
  );
};

export default Rating;
