import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { setItem } from '../../../store/app/actions';

import styles from './about-blocks.module.css';

const AboutBlocks = ({ items }) => {
  const dispatch = useDispatch();

  const handleClickItem = (slug) => {
    if (slug === 'about_feedback') dispatch(setItem(['common', 'feedback_popup', 'open'], true));
    else dispatch(setItem(['common', 'faq'], slug));
  };

  return (
    <div className={styles.list}>
      {items.map(({ name, slug, href, image, color, disabled }, index) => {
        if (slug) return (
          <button
            className={classNames(styles.item, disabled && styles.disabled)}
            onClick={() => handleClickItem(slug)}
            style={{ color: color, backgroundImage: `url(${image})` }}
            key={index}
          >
            <span className={styles.itemText}>
              {name}
            </span>
          </button>
        );
        return (
          <Link
            className={classNames(styles.item, disabled && styles.disabled)}
            to={href}
            style={{ color: color, backgroundImage: `url(${image})` }}
            key={index}
          >
            <span className={styles.itemText}>
              {name}
            </span>
          </Link>
        );
      })}
    </div>
  );
};

export default AboutBlocks;
