import axios from 'axios';
import { SVG } from '@svgdotjs/svg.js';

/* monolog uid
1   Герои   #00FF7F
2   Легенды   #7FFFD4
3   Игроки   #FF69B4
4   Злодеи   #FF0000
*/

export function childGame(options) {
  let defaults = {
    game: null,
    container: null,
    reactMessage: () => null,
    reactHeaderMessage: () => null,
    addNewPoint: () => null
  };
  options = { ...defaults, ...options };

  let elements = {};
  let state = {
    isBusy: false,
    isMonologOpen: false
  };

  const rewardLayouts = [
    [
      {
        // width: 466,
        // height: 466,
        width: 300,
        height: 300,
        top: 355,
        left: 195
      }
    ],
    [
      {
        // width: 394,
        // height: 394,
        width: 251,
        height: 220,
        top: 241,
        left: 195
      },
      {
        width: 251,
        height: 220,
        top: 445,
        left: 195
      }
    ],
    [
      {
        // width: 518,
        // height: 518,
        width: 330,
        height: 330,
        top: 275,
        left: 195
      },
      {
        width: 156,
        height: 136,
        top: 466,
        left: 120
      },
      {
        width: 156,
        height: 136,
        top: 466,
        left: 270
      }
    ],
    [
      {
        // width: 466,
        // height: 466,
        width: 300,
        height: 300,
        top: 226,
        left: 195
      },
      {
        width: 142,
        height: 125,
        top: 400,
        left: 127
      },
      {
        width: 142,
        height: 125,
        top: 400,
        left: 263
      },
      {
        width: 142,
        height: 125,
        top: 520,
        left: 195
      }
    ],
    [
      {
        // width: 466,
        // height: 466,
        width: 300,
        height: 300,
        top: 226,
        left: 195
      },
      {
        width: 142,
        height: 125,
        top: 400,
        left: 127
      },
      {
        width: 142,
        height: 125,
        top: 400,
        left: 263
      },
      {
        width: 142,
        height: 125,
        top: 520,
        left: 127
      },
      {
        width: 142,
        height: 125,
        top: 520,
        left: 263
      }
    ],
  ];

  this.init = async function() {
    const svg = await this.appendMap(options.game.city.uid, options.container);

    // делаем доступной запись в массив game
    options.game = { ...options.game };
    options.game.dialogs = { ...options.game.dialogs };
    options.game.points = { ...options.game.points };

    if (svg) {
      elements.draw = SVG(svg);
      const currentPoint = options.game && Object.values(options.game.points).find(({ is_active }) => !!is_active);
      state.currentPoint = currentPoint.uid;

      if (Number(currentPoint.path_number) !== 0) this.hideClouds(1);

      await this.setActiveIsland(currentPoint);
      const nextPoint = Object.values(options.game.points).find(({ path_number }) => Number(path_number) === Number(currentPoint.path_number) + 1);

      if (nextPoint) {
        state.nextPoint = nextPoint.uid;
        const islands = elements.draw.find('[id^=island_]');

        islands.each(async elem => {
          if (!elem.attr('id').match('active')) {
            const index = elem.attr('id').replace('island_', '');
            const stars = await this.appendElem(`/images/game/stars_${index}.svg`, [110, 85], `stars_${index}`);
            if (stars) {
              this.moveElem(stars, elem, 'L', [-10, -20], [0, 0]);
              elem.put(stars);
            }

            elem.click(e => this.onClickIsland(elem));
          }
        });
      }

      await this.addGates(0.00001);
      elements.draw.find('#gates').click(e => this.onClickGates());

      if (options.afterInit) options.afterInit();

      this.createCache();
    }
  };

  this.appendMap = async function(city_uid, container) {
    const map = await axios.get(`/images/game/city_${city_uid}.svg`);
    container.innerHTML = map.data;

    const map_active = await axios.get(`/images/game/city_${city_uid}_active.svg`);
    let div = document.createElement('div');
    div.innerHTML = map_active.data;
    div.querySelectorAll('[id^="way_"]').forEach(item => {
      const id = item.id.replace('_active', '');
      const elem = document.getElementById(id);
      if (elem) elem.after(item);
      item.style.display = 'none';
      item.setAttribute('opacity', 0);
    });
    div.querySelectorAll('[id^="island_"]').forEach(item => {
      const id = item.id.replace('_active', '');
      const elem = document.getElementById(id);
      if (elem) elem.after(item);
      item.style.display = 'none';
      item.setAttribute('opacity', 0);
    });

    return container.children[0];
  };

  this.onClickIsland = async function(elem) {
    if (state.isBusy || state.isMonologOpen) return null;

    const clickedIslandIndex = elem.attr('id').replace('island_', '');
    const currentPoint = options.game.points[state.currentPoint];
    const nextPoint = state.nextPoint && options.game.points[state.nextPoint];

    // если клик по последующим точкам
    if (Number(clickedIslandIndex) > Number(currentPoint.path_number)) {
      if (nextPoint && Number(clickedIslandIndex) === Number(nextPoint.path_number)
        && nextPoint.must_check
        // && options.game.energy >= 1000
      ) {
        state.isBusy = true;

        const pointData = await options.addNewPoint(state.nextPoint);

        if (pointData) {
          options.game.dialogs[pointData.point.uid] = { ...pointData.dialogs, mess_reaction: pointData.point.mess_reaction };
          options.game.points[state.nextPoint] = { ...nextPoint, rewards: pointData.rewards };
          options.game.energy = pointData.energy;

          const animate = elements.childs.find('animateTransform')[0].node;
          const that = this;

          animate.addEventListener('repeatEvent', function me(e) {
            that.pointTransformations(pointData);
            animate.removeEventListener('repeatEvent', me, false);
          }, false);
        }
      } else {
        this.createHeaderMessage(
          `Нам не хватает ещё ${1000 - options.game.energy} энергии, для перемещения!`,
          [ '/images/game/alisa-wow.svg' ]
        );
      }
    }
  };

  this.pointTransformations = async function(pointData) {
    let currentPoint = options.game.points[state.currentPoint];
    // add teleport to old island
    let teleport = await this.appendElem('/images/game/kids_teleport.svg', [100, 115], 'teleport');
    this.moveElem(
      teleport,
      elements.draw.find(`#island_${currentPoint.path_number}_active`),
      currentPoint.orientation,
      [-6.8, -71], [-210.5, -71]
    );
    teleport.insertAfter(elements.childs);

    // set teleport animation playing once
    teleport.find('animate, animateMotion, animateTransform').each(elem => {
      // elem.attr({ repeatCount: '1' });
      const dur = elem.attr('dur');
      elem.attr({ end: dur });
    });

    // hide childs and stop animation
    elements.childs.css('opacity', '0');
    elements.childs.find('animate, animateMotion, animateTransform').each(elem => {
      elem.attr({ repeatCount: '1' });
    });

    // hide person and info icon
    elements.info.animate(1000).attr({ opacity: 0 });
    elements.person.animate(1000).attr({ opacity: 0 });

    // set end event
    teleport.find('animate')[0].node.addEventListener('endEvent', async (e) => {
console.log('end1')
      teleport.remove();

      // set new active island
      state.currentPoint = state.nextPoint;
      options.game.points[state.currentPoint].legend_icon = pointData.point.legend_icon;
      currentPoint = options.game.points[state.currentPoint];

      this.setActiveIsland(currentPoint, 1000, 1000);
      state.nextPoint = Object.values(options.game.points).find(({ path_number }) => Number(path_number) === Number(currentPoint.path_number) + 1)?.uid;

      // add teleport to new island
      teleport = await this.appendElem('/images/game/island-transformation.svg', [195, 165], 'teleport');
      teleport.insertAfter(elements.childs);
      this.moveElem(
        teleport,
        elements.draw.find(`#island_${currentPoint.path_number}_active`),
        currentPoint.orientation,
        // [-52.5, -78], [-83, -79]
        [-52.5, -78], [-82.8, -78]
      );

      // set teleport animation playing once
      teleport.find('animate, animateMotion, animateTransform').each(elem => {
        // elem.attr({ repeatCount: '1' });
        const dur = elem.attr('dur');
        elem.attr({ end: dur });
      });

      // set end event
      teleport.find('animate')[0].node.addEventListener('endEvent', async () => {
console.log('end2')
        teleport.remove();

        // show childs and start animation
        elements.childs.find('animateMotion')[0].node.removeEventListener('repeatEvent', this.pointTransformations);
        elements.childs.css('opacity', '1');
        elements.childs.find('animate, animateMotion, animateTransform').each(elem => {
          elem.attr({ repeatCount: 'indefinite' });
        });

        // show person and info icon
        elements.info.animate(1000).attr({ opacity: 1 });
        elements.person.animate(1000).attr({ opacity: 1 });

        state.isBusy = false;
      }, false);
    }, false);
  };

  this.addGates = async function(repeatCount) {
    const gates = elements.draw.find('#gates');
    const child = gates.children()[0]
    const width = child.width()[0];
    const height = child.height()[0];
    const x = child.x()[0];
    const y = child.y()[0];

    const gatesAnimation = await this.appendElem('/images/game/gates_open.svg', [width.toFixed(3), height.toFixed(3)], 'gates');
    gatesAnimation.find('animate, animateMotion, animateTransform').each(elem => {
      if (repeatCount) elem.attr({ repeatCount: repeatCount });
      const dur = elem.attr('dur');
      elem.attr({ end: dur });
    });

    gatesAnimation.move(x, y);

    gates.after(gatesAnimation);
    gates.remove();

    return gatesAnimation;
  };

  this.onClickGates = async function() {
    if (state.isBusy) return null;

    const res = await options.changeCity();
    if (res) {
      const gates = await this.addGates();
      const city_out_animation = await axios.get(res.city_out_animation);

      gates.find('animate')[0].node.addEventListener('endEvent', () => {
        if (city_out_animation) {
          options.animationContainer.innerHTML = city_out_animation.data;
          options.animationContainer.style.opacity = 1;
          options.animationContainer.style.visibility = 'visible';

          setTimeout(() => {
            options.onCityOut();
          }, 1000);
        }
      });
    }
  };

  this.setActiveIsland = async function(currentPoint, speed = 0, delay = 0) {
    if (Number(currentPoint.path_number) !== 0) this.hideClouds(3000);
    const index = currentPoint.path_number;

    if (index > 0) {
      // set images for previous and current islands
      for (let i=index; i>0; i--) {
        const island = elements.draw.find(`#island_${i}`);
        if (island) island.animate(speed, delay).attr({ opacity: 0 }).css({ display: 'none' });

        const islandActive = elements.draw.find(`#island_${i}_active`);
        if (islandActive) {
          islandActive.css({ display: 'block' }).animate(speed, delay).attr({ opacity: 1 });
        }

        const way = elements.draw.find(`#way_${i}`);
        if (way) way.animate(speed, delay).attr({ opacity: 0 }).css({ display: 'none' });

        const wayActive = elements.draw.find(`#way_${i}_active`);
        if (wayActive) wayActive.css({ display: 'block' }).animate(speed, delay).attr({ opacity: 1 });
      }
    }

    if (!elements.childs) elements.childs = await this.appendElem(`/images/game/Kids_idle.svg`, [70, 70], 'childs');
    if (!elements.person) elements.person = await this.appendElem(`/images/game/legends_${options.game.city.uid}_idle.svg`, [70, 70], 'person');

    const island = elements.draw.find(`#island_${index}_active`);
    if (island) {
      if (elements.childs) this.moveElem(elements.childs, island, currentPoint.orientation, [9, -36], [9, -36]);

      setTimeout(() => {
        if (elements.person) this.moveElem(elements.person, island, currentPoint.orientation, [-60, -39], [90, -39]);
        this.addPointInfo(currentPoint, true);

        if (currentPoint.rewards) {
          setTimeout(() => {
            this.addChest(currentPoint.rewards.chest, currentPoint.rewards);
          }, 1500);
        }
      }, 100);
    }
  };

  this.addPointInfo = async function(currentPoint, hightligt) {
    elements.person.off('click');
    let infoParams = null;

    if (elements.info) {
      infoParams = elements.info.attr();
      elements.info.remove();
    }

    if (
      elements.person
      && options.game.dialogs
      && options.game.dialogs[currentPoint.uid]
    ) {
      const pointDialogs = options.game.dialogs[currentPoint.uid];

      if (pointDialogs.monolog && Object.keys(pointDialogs.monolog).length) {
        const monolog = pointDialogs.monolog;
        const dialog = pointDialogs.dialog;
        const mess_reaction = pointDialogs.mess_reaction;

        if (monolog && monolog.length) {
          let icon = hightligt ? `/images/game/person_info_orange.svg` : `/images/game/person_info.svg`;
          if (currentPoint.legend_icon && currentPoint.legend_icon === 2)
            icon = hightligt ? `/images/game/person_build_orange.svg` : `/images/game/person_build.svg`;

          elements.info = await this.appendElem(icon, [56, 56], 'info');
          if (elements.info) {
            elements.info.attr(infoParams);
            this.moveElem(elements.info, elements.person, currentPoint.orientation, [4, -54], [-8, -54]);
            elements.info.on('click', () => this.onClickInfo(monolog, mess_reaction, dialog, currentPoint));
            elements.person.on('click', () => this.onClickInfo(monolog, mess_reaction, dialog, currentPoint));
          }
        }
      } else {
        this.selectHeaderMessage(pointDialogs.mess_reaction, pointDialogs.dialog);
      }
    }
  };

  this.onClickInfo = function(monolog, mess_reaction, dialog, currentPoint) {
    elements.info.remove();

    this.addMonolog(elements.person, monolog, currentPoint, 0, async () => {
      this.selectHeaderMessage(mess_reaction, dialog);
      this.addPointInfo(currentPoint);
    });
  };

  this.selectHeaderMessage = function(mess_reaction, dialog) {
    if (mess_reaction && mess_reaction.text) {
      let persons = [];
      if (mess_reaction.left_person) persons.push(mess_reaction.left_person);
      if (mess_reaction.right_person) persons.push(mess_reaction.right_person);
      this.createHeaderMessage(mess_reaction.text, persons);
    // } else if (dialog && dialog.length) {
      // this.createHeaderMessage('Нам нужно с тобой поговорить!', [ '/images/game/timur-wow.svg', '/images/game/alisa-wow.svg' ]);
    }

    this.highlightHeaderButton('game_chat');
  };

  this.addMonolog = async function(person, monolog, currentPoint, index, exitFunction) {
    const button = index >= monolog.length-1 ? 'завершить' : 'продолжить';
    const currentPhrase = monolog[index];

    if (currentPhrase.pers_type && currentPhrase.pers_type === 4) {
      const island = elements.draw.find(`#island_${index}_active`);


      this.addOverlay('/images/game/red_bg.png');

      const virus = await this.appendElem('/images/game/Virus_idle.svg', [62, 62], 'virus');
      // todo - что делать если картинка не загрузилась
      // const virus = await this.appendElem(currentPhrase.avatar, [62, 62]);
      if (virus) this.moveVirus(virus, island);

      this.createMessage(virus, currentPhrase.text, currentPhrase.audio, currentPhrase.galery, button, 'R', 'danger', () => {
        virus.remove();
        this.removeOverlay();

        if (index < monolog.length-1) {
          this.addMonolog(person, monolog, currentPoint, index+1, exitFunction);
        } else {
          exitFunction();
        }
      });
    } else {
      this.createMessage(person, currentPhrase.text, currentPhrase.audio, currentPhrase.galery, button, currentPoint.orientation, 'default', () => {
        if (index < monolog.length-1) {
          this.addMonolog(person, monolog, currentPoint, index+1, exitFunction);
        } else {
          exitFunction();
        }
      });
    }
  };

  this.addOverlay = function(url) {
    elements.overlay = elements.draw.foreignObject(elements.draw.width(), elements.draw.height());
    elements.overlay.move(0, 0);
    elements.overlay.node.innerHTML = `<img src="${url}" style="width: 100%; height: 100%" id="overlay" />`;
  };

  this.removeOverlay = function() {
    elements.overlay.remove();
  };

  this.appendElem = async function(url, size, id) {
    const res = await axios.get(url);
    if (res.data) {
      const group = elements.draw.group();

      let data = res.data;

      const unique = Math.random().toString(16).slice(2);
      const ids = data.match(/id="[a-zA-Z0-9_]*"/gm);
      if (ids && ids.length) {
        ids.map(item => {
          const id = item.replace('id="', '').replace('"', '');
          data = data.replaceAll(`${id}'`, `${id}_${unique}'`);
          data = data.replaceAll(`${id}"`, `${id}_${unique}"`);
          data = data.replaceAll(`${id})`, `${id}_${unique})`);

          return null;
        });
      }

      group.id(id);
      group.put(data).size(size[0], size[1]);

      return group;
    };
  };

  this.moveVirus = function(virus, island) { // deprecated - change to moveElem
    const svg = virus.children()[0];
    const positions = {
      x: island.x()[0] + island.width()[0]/7,
      y: island.y()[0] - svg.height() - 35
    };
    svg.move(positions.x.toFixed(3), positions.y.toFixed(3));
  };

  this.moveElem = function(elem, targetElem, orientation, shift = [0, 0], inverceShift = [0, 0]) {
    elem.attr('transform', '');

    const svg = elem.children()[0];
    const targetElemX = typeof(targetElem.x()) === 'object' ? targetElem.x()[0] : targetElem.x();
    const targetElemY = typeof(targetElem.y()) === 'object' ? targetElem.y()[0] : targetElem.y();

    const x = orientation === 'R' ? (targetElemX + inverceShift[0]) : (targetElemX + shift[0]);
    const y = orientation === 'R' ? (targetElemY + inverceShift[1]) : (targetElemY + shift[1]);

    svg.move(x.toFixed(3), y.toFixed(3));

    if (orientation === 'R') elem.flip('x');
  };

  this.hideClouds = function(speed = 0) {
    const clouds = elements.draw.find('[id^=clouds_]');

    clouds.each(elem => {
      const id = elem.attr('id');

      if (id != null) {
        if (id.match(/^clouds_top_top*/i))
          elem.animate(speed).move(0, -300).animate().attr({ opacity: 0 });

        if (id.match(/^clouds_top_left*/i))
          elem.animate(speed).move(-300, -300).animate().attr({ opacity: 0 });

        if (id.match(/^clouds_top_right*/i))
          elem.animate(speed).move(300, -300).animate().attr({ opacity: 0 });

        if (id.match(/^clouds_left*/i))
          elem.animate(speed).move(-300, 0).animate().attr({ opacity: 0 });

        if (id.match(/^clouds_right*/i))
          elem.animate(speed).move(300, 0).animate().attr({ opacity: 0 });

        if (id.match(/^clouds_bottom_left*/i))
          elem.animate(speed).move(-300, 300).animate().attr({ opacity: 0 });

        if (id.match(/^clouds_bottom_right*/i))
          elem.animate(speed).move(300, 300).animate().attr({ opacity: 0 });

        if (id.match(/^clouds_bottom_bottom*/i))
          elem.animate(speed).move(0, 300).animate().attr({ opacity: 0 });
      }
    });
  };

  this.createMessage = function(person, content, audio, gallery, button, orientation, type, exitFunction) {
    if (state.isMonologOpen) return null;
    state.isMonologOpen = true;

    const sizes = {
      width: elements.draw.width() - 40,
      height: person.y() - 75,
    };

    const foreignObject = elements.draw.foreignObject(sizes.width, sizes.height);
    foreignObject.move(20, 80);

    const arrowPositions = {
      top: '100%',
      left: orientation === 'R' ? person.x() - 52 : person.x() - 35,
    };

    options.reactMessage(foreignObject.node, content, audio, gallery, button, arrowPositions, type, () => {
      state.isMonologOpen = false;
      foreignObject.remove();
      exitFunction && exitFunction();
    });
  };

  this.createHeaderMessage = function(content, icons) {
    if (elements.headerMessage) elements.headerMessage.remove();

    const sizes = {
      width: elements.draw.width() - 40,
      height: 200,
    };

    elements.headerMessage = elements.draw.foreignObject(sizes.width, sizes.height);
    elements.headerMessage.move(20, 65);

    options.reactHeaderMessage(elements.headerMessage.node, content, 'right', icons);

    elements.draw.on('click.headerMessage', function me(e) {
      elements.draw.off('click.headerMessage', me, false);
      const isOutside = !elements.headerMessage.node.children[0].contains(e.target);
      if (isOutside) elements.headerMessage.remove();
    }, false);
  };

  this.highlightHeaderButton = function(id) {
    document.getElementById(id).classList.add('highlight');
  };

  this.addChest = async function(chest_type, rewards) {
    // todo: типы сундуков

    this.addOverlay('/images/game/white_bg.png');
    const chestAppend = await this.appendElem(`/images/game/chest-append-G.svg`, [390, 520], `chest`);
    if (chestAppend) {
      chestAppend.move(0, 0);
      chestAppend.find('animate, animateMotion, animateTransform').each(elem => {
        // elem.attr({ repeatCount: '1' });
        const dur = elem.attr('dur');
        elem.attr({ end: dur });
      });

      chestAppend.find('animate')[0].node.addEventListener('endEvent', async (e) => {
        const chestIdle = await this.appendElem(`/images/game/chest-idle-G.svg`, [390, 520], `chest`);
        chestIdle.move(0, 0);
        chestAppend.remove();

        chestIdle.click(async () => {
          const chestOpen = await this.appendElem(`/images/game/chest-open-G.svg`, [390, 520], `chest`);
          chestOpen.move(0, 0);
          chestIdle.remove();
          chestOpen.find('animate, animateMotion, animateTransform').each(elem => {
            // elem.attr({ repeatCount: '1' });
            const dur = elem.attr('dur');
            elem.attr({ end: dur });
          });

          chestOpen.find('animate')[0].node.addEventListener('endEvent', async (e) => {
            this.addRewards(rewards);
            chestOpen.remove();
          });
        })
      });
    }
  };

  this.addRewards = async function(rewards) {
    rewards = Object.entries(rewards).reduce((prev, current) =>
      ((current[0] !== 'chest' && Object.keys(current[1]).length) ? [ ...prev, { id: current[0], ...current[1] } ] : prev), []);
    const layout = rewardLayouts[rewards.length-1];

    for (const index in rewards) {
      const { id, type_name, value } = rewards[index];
      let name = value && value.name ? value.name : type_name;
      let image = value && value.url ? value.url : '';
      if (id === 'crystall') {
        name = name + '' + value;
        image = '/images/game/rewards/crystalls.svg';
      }

      // todo: разбить строку на подстроки
      // const content = name.match(/.{18}/g);
      const content = [ name ];

      const { width, height, top, left } = layout[index];
      // const bg = index > 0 ? '/images/game/reward_simple.svg' : '/images/game/reward.svg';
      const bg = '/images/game/reward_simple.svg';
      const reward = await this.addReward(bg, image, content, [width, height], [top, left], index);
      if (reward) {
        reward.click(e => this.onClickReward(reward));
      }
    }
  };

  this.addReward = async function(bg, image, content, size, position, index) {
    const reward = await this.appendElem(bg, [0, 0], `reward_${index}`);

    if (reward) {
      const svg = reward.children()[0];
      const rect = svg.find('rect');

      if (rect[0]) {
        const rectX = rect[0].x();
        const rectY = rect[0].y();
        const rectWidth = rect[0].width();
        const rectHeight = rect[0].height();

        const text = svg.text(function(add) {
          content.forEach(function(row) { add.tspan(row).fill('#AE6541').newLine() });
        });

        text
          .move(svg.viewbox().width / 2, rectY + rectHeight - 18 - 33)
          .font({ family: 'Roboto', size: 14, anchor: 'middle', 'alignment-baseline': 'middle', weight: 700 });

        const rewardIcon = svg.foreignObject(rectWidth-84, rectHeight-84);
        rewardIcon.node.innerHTML = `<img src="${image}" style="width: 100%; height: 100%" />`;
        rewardIcon.move(rectX + 42, rectY + 24);

        svg.center(position[1], position[0]);
        svg.animate(500).attr({ width: size[0], height: size[1] }).center(position[1], position[0]);

        return reward;
      }
    }

    return null;
  };

  this.onClickReward = async function(reward) {
    reward.find('rect').animate(300).attr({ opacity: 0 });
    reward.find('text').animate(300).attr({ opacity: 0 });
    reward.children()[0].animate(700, 300).attr({ width: 0, height: 0 }).move(315, 0)

    setTimeout(() => {
      reward.remove();

      const rewards = elements.draw.find('[id^=reward_]');
      const layout = rewardLayouts[rewards.length-1];

      rewards.each(function(item, index) {
        const { width, height, top, left } = layout[index];
        const svg = item.children()[0];
        svg.animate(500).attr({ width: width, height: height }).center(left, top);
      });

      if (rewards.length === 0) this.removeOverlay();

      if (state.backpackTimeout) clearTimeout(state.backpackTimeout);

      document.getElementById('game_backpack').classList.add('highlight');

      state.backpackTimeout = setTimeout(() => {
        document.getElementById('game_backpack').classList.remove('highlight');
      }, 3000);
    }, 1000);
  };

  this.createCache = function() {
    axios.get('/images/game/person_info_orange.svg')
    axios.get('/images/game/person_build_orange.svg')
    axios.get('/images/game/person_info.svg')
    axios.get('/images/game/person_build.svg')

    axios.get('/images/game/message_tick.svg')
    axios.get('/images/game/message_tick_danger.svg')
    axios.get('/images/game/message_tick_edge.svg')

    axios.get('/images/game/timur-wow.svg')
    axios.get('/images/game/alisa-wow.svg')
    axios.get('/images/game/kids_teleport.svg')
    axios.get('/images/game/island-transformation.svg')
    axios.get('/images/game/gates_open.svg')
    axios.get('/images/game/reward.svg')
    axios.get('/images/game/reward_simple.svg')

    axios.get('/images/game/chest-append-G.svg')
    axios.get('/images/game/chest-idle-G.svg')
    axios.get('/images/game/chest-open-G.svg')

    axios.get('/images/game/Virus_idle.svg')

    axios.get('/images/train.svg')
    axios.get('/images/bus.svg')
    axios.get('/images/airplane.svg')
  };

  this.init();
};
