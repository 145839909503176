import { useSelector } from 'react-redux';
import { ReactSVG } from 'react-svg';
import { MainLayout } from '../../../../components/Layouts';
import Block from '../../../../components/Block';
import AboutBlocks from '../../../../components/homepage/AboutBlocks';
import { selectItem } from '../../../../store/app/getters';

import { backpack_items } from '../../../../data/mock';

import styles from './backpack.module.css';

const Backpack = () => {
  const user = useSelector(selectItem(['user']));
  const currentPlayer = user.current_player;
  const crystalls = currentPlayer.info ? currentPlayer.info.crystalls : currentPlayer.crystalls;

  if (!user) return null;

  return (
    <MainLayout>
      <Block title="Рюкзак" headerAlign="center" showBackButton={true}>
        <div className={styles.header}>
          <div className={styles.balance}>
            <ReactSVG src="/images/crystal.svg" className={styles.balanceIcon} wrapper="span" />
            {crystalls} кристалов
          </div>
        </div>

        <AboutBlocks items={backpack_items} />
      </Block>
    </MainLayout>
  );
};

export default Backpack;
