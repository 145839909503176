import { useEffect } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RegisterLayout } from '../../components/Layouts';
import Form from '../../components/Form';
// import Modal from '../../components/Modal';
// import Video from '../../components/Video';
import PhoneCode from '../../components/fields/PhoneCode';
import Button from '../../components/Button';
import { setItem } from '../../store/app/actions';
import { selectItem } from '../../store/app/getters';
import standartPhone from '../../helpers/standartPhone';
import parseSearchParams from '../../helpers/parseSearchParams';
import * as api from '../../helpers/api';

import styles from './account.module.css';
import classNames from 'classnames';

// const videoUrl = 'https://play.skillary.club/intro.mp4';

const Register = () => {
  let { search } = useLocation();
  const { phone } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector(selectItem(['user']));
  const verification = useSelector(selectItem(['verification']));

  useEffect(() => {
    if (!search) return null;
    let params = parseSearchParams(search);

    if (!params) return null;
    if (params.key) {
      api.get(`/api/keypost/${params.key.value}/`);
      delete params.key;
    }
    if (Object.keys(params).length) {
      dispatch(setItem(['tokens'], params));
    }

    // eslint-disable-next-line
  }, [search]);

  useEffect(() => {
    if (!search || !verification.checkingPhone || !verification.isCodeSended) return null;
    const params = parseSearchParams(search);

    if (!params || !params.key) return null;
    if (verification.isCodeValid)
      api.get(`/api/keypost/${params.key.value}/${verification.checkingPhone.replace(/\s+/g, '')}/${verification.code}/`);
    else if (!verification.code)
      api.get(`/api/keypost/${params.key.value}/${verification.checkingPhone.replace(/\s+/g, '')}/`);
    // eslint-disable-next-line
  }, [verification]);

  const onPhoneSubmit = (result) => {
    dispatch(setItem(['user'], result.user));
  };

  const inputs = [
    { id: 'phone', name: 'phone', component: PhoneCode, validation: 'phone',
      props: { label: 'Введите ваш номер телефона', placeholder: '+7', fullWidth: true, mask: '+7 ### ### ## ##', type: 'tel', value: standartPhone(phone),
        onPhoneSubmit: onPhoneSubmit, path: '/auth'
      }
    }
  ];

  useEffect(() => {
    if (user && user.uid) {
      if (!user.name) navigate('/create');
      else navigate('/');
    }

    // eslint-disable-next-line
  }, [user]);

  const onFormSubmit = (data) => {
    if (!verification.isCodeSended) dispatch(setItem(['verification', 'sendCode'], true));
    if (!verification.isCodeValid) return null;
  };

  // const howToControl = forwardRef(({ onClick }, ref) => (
  //   <Button
  //     fullWidth={true}
  //     butStyle="primary"
  //     onClick={onClick}
  //     ref={ref}
  //   >
  //     Как пользоваться сервисом?
  //   </Button>
  // ));

  const buttons = (
    <>
      <Button
        type="submit"
        fullWidth={true}
        butStyle="primary"
      >
        Получить код
      </Button>
      <Button
        to="/login"
        fullWidth={true}
        butStyle="secondary"
      >
        У меня уже есть профиль
      </Button>

      <div className={styles.terms}>
        Нажимая кнопку ”<span className="accent-text">Получить код</span>” вы соглашаетесь
        с <a href="https://skillary.club/docs/license.pdf">политикой обработки персональных данных</a>
        и принимаете <a href="https://skillary.club/docs/oferta.pdf">условия оферты сервиса</a>
      </div>

      {/* <Modal
        viewStyle="bottom"
        buttons={[
          { text: 'Закрыть', action: 'close', style: 'primary' }
        ]}
        control={howToControl}
      >
        <Video src={videoUrl} autoPlay={true} controls={true} />
      </Modal> */}
    </>
  );

  return (
    <RegisterLayout>
      <div className={styles.labels}>
        <span className={classNames(styles.label, styles.green)}>Skillary</span>
        <span className={classNames(styles.label, styles.white)}>-</span>
        <span className={classNames(styles.label, styles.blue)}>эффективное решение</span>
        <span className={classNames(styles.label, styles.orange)}>гаджетозависимости</span>
      </div>
      <h1 className={styles.title}>Регистрация</h1>
      <div className={styles.subtitle}>
        {verification.isCodeSended ? (
          <>
            Проверяйте код из смс, он прийдёт на номер
            <div><b>{verification.checkingPhone}</b></div>
          </>
        ) : (
          <>
            Введите номер телефона, а затем код из смс
          </>
        )}
      </div>
      <div className={styles.content}>
        <Form inputs={inputs} onSubmit={onFormSubmit} buttons={!verification.isCodeSended && buttons} />
      </div>
    </RegisterLayout>
  );
}

export default Register;
