import Modal from '../../../Modal';
import Radio from '../../Radio';
import classnames from 'classnames';

import styles from '../../fields.module.css';

const SelectPopup = ({ label, title, isPopupShow, buttons, isSearchable, searchPlaceholder,
  onChange, id, name, options, tempValue, errorText, setTempValue, onClose }) => {
  return (
    <Modal
      title={label ? label : title}
      viewStyle="bottom"
      open={isPopupShow}
      onClose={() => onClose()}
      buttons={buttons}
    >
      {isSearchable &&
        <input
          type="text"
          placeholder={searchPlaceholder}
          className={classnames(styles.input, 'dark')}
          onChange={e => onChange(e.target.value)}
          style={{ marginBottom: '20px' }}
        />
      }
      <Radio
        id={id + '_popup'}
        name={name + '_popup'}
        options={options}
        value={tempValue}
        onChange={e => setTempValue(e.target.value)}
      />
      {errorText && errorText}
    </Modal>
  )
};

export default SelectPopup;
