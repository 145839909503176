import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { MainLayout } from '../../../components/Layouts';
import Block from '../../../components/Block';
import Audio from '../../../components/Audio';
import useApiQuery from '../../../hooks/useApiQuery';
import CoursesQuests from '../../../components/courses/CoursesQuests';
import { selectItem } from '../../../store/app/getters';

import styles from './courses.module.css';

const GroupQuests = () => {
  const { gid } = useParams();
  const user = useSelector(selectItem(['user']));

  const { data: group } = useApiQuery({
    name: ['quest_groups', gid],
    path: `/getquestgroup/${gid}/0/${user.uid}/`,
    skip: !user,
    mapper: (data = []) => {
      if (data && data.length) return data[0];
      return data;
    }
  });

  return (
    <MainLayout>
      <Block title={group ? group.name : <span>&nbsp;</span>} headerAlign="center" showBackButton={true}>
        {(group && group.description) &&
          <div className={styles.headerDescr} dangerouslySetInnerHTML={{ __html: group.description }} />
        }
        {(group && group.audio) &&
          <div className={styles.audio}>
            <Audio src={group.audio} />
          </div>
        }
        <CoursesQuests items={group && group.quests} />
      </Block>
    </MainLayout>
  );
};

export default GroupQuests;
