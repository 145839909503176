import { useSelector } from 'react-redux';
import RewardsList from '../../../components/RewardsList';
import { MainLayout } from '../../../components/Layouts';
import Block from '../../../components/Block';
import useApiQuery from '../../../hooks/useApiQuery';
import { selectItem } from '../../../store/app/getters';

const Rewards = () => {
  const user = useSelector(selectItem(['user']));
  const currentPlayer = user.current_player;

  const { data: rewards } = useApiQuery({
    name: ['rewards_mentor', currentPlayer.uid_gamer],
    path: `/playerrewardsfrommentor/${currentPlayer.uid_gamer}/`,
    skip: !user || !currentPlayer,
    mapper: (data = {}) => {
      return Object.values(data).reduce((prev, current) => {
        if (current) return ([ ...prev, ...current ]);
        return prev;
      }, []);
    }
  });

  if (!user) return null;

  return (
    <MainLayout>
      <Block title="Награды для игрока" headerAlign="center" showBackButton={true}>
        <RewardsList items={rewards} />
      </Block>
    </MainLayout>
  );
};

export default Rewards;
