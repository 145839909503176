import { useSelector } from 'react-redux';
import { MainLayout } from '../../../../components/Layouts';
import Block from '../../../../components/Block';
import ArtefactsList from '../../../../components/ArtefactsList';
import { selectItem } from '../../../../store/app/getters';
import useApiQuery from '../../../../hooks/useApiQuery';

const Artefacts = () => {
  const user = useSelector(selectItem(['user']));

  const { data: artefacts } = useApiQuery({
    name: ['artefacts'],
    path: `/playerrartefacts/${user.uid}/`,
    skip: !user
  });

  if (!user) return null;

  return (
    <MainLayout>
      <Block title="Артефакты" headerAlign="center" showBackButton={true}>
        <ArtefactsList items={artefacts} />
      </Block>
    </MainLayout>
  );
};

export default Artefacts;
