import { useState } from 'react';
import { ReactSVG } from 'react-svg';
import classnames from 'classnames';

import styles from './rating.module.css';

const Rating = ({ defaultValue = 0, onChange, children, disable }) => {
  const [ rating, setRating ] = useState(defaultValue);
  const [ hover, setHover ] = useState(0);

  const onClickStar = (value) => {
    setRating(value);
    if (onChange) onChange(value);
  }

  return (
    <div className={styles.root}>
      <div className={styles.list}>
        {[...Array(5)].map((star, index) => {
          index += 1;
          return (
            <ReactSVG
              key={index}
              src="/images/icon-star-fill.svg"
              className={classnames(styles.item, index <= (hover || rating) ? styles.on : styles.off)}
              onClick={() => !disable && onClickStar(index)}
              onMouseEnter={() => !disable && setHover(index)}
              onMouseLeave={() => !disable && setHover(rating)}
              wrapper="span"
            >
            </ReactSVG>
          );
        })}
      </div>
      {children &&
        <div className={styles.description}>{children}</div>
      }
    </div>
  );
};

export default Rating;
