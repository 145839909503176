import { useSelector, useDispatch } from 'react-redux';
import { MainLayout } from '../../../components/Layouts';
import Block from '../../../components/Block';
import ButtonsGrid from '../../../components/ButtonsGrid';
import ButtonsContainer from '../../../components/ButtonsContainer';
import Button from '../../../components/Button';
import InfoButton from '../../../components/Block/InfoButton';
import copyToClipboard from '../../../helpers/copyToClipboard';
import { selectItem } from '../../../store/app/getters';
import { setItem } from '../../../store/app/actions';

import styles from './services.module.css';

import { services } from '../../../data/mock';

const Services = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectItem(['user']));

  const handleClickShare = () => {
    const result = copyToClipboard(user.referal_url);
    if (result) dispatch(setItem(['notifications', 'copy_link'], { type: 'success', content: 'Ссылка для приглашения скопирована' }));
    else dispatch(setItem(['notifications', 'copy_link'], { type: 'error', content: 'Невозможно скопировать ссылку' }));
  }

  return (
    <MainLayout>
      <Block title="Сервисы" headerAlign="center" showBackButton={true}>
        <ButtonsGrid buttons={services} />
      </Block>
      <Block>
        {user.referal_url &&
          <p className={styles.shareTitle}>
            Пригласи друзей и получи баллы
            <InfoButton slug="referal_p" />
          </p>
        }
        <ButtonsContainer className={styles.shareButtons}>
          {user.referal_url &&
            <>
              <div className={styles.shareLink}>
                {user.referal_url}
              </div>
              <Button
                butStyle="primary"
                fullWidth="true"
                onClick={() => handleClickShare()}
                size="medium"
              >
                Копировать ссылку
              </Button>
            </>
          }
        </ButtonsContainer>
      </Block>
    </MainLayout>
  );
};

export default Services;
