import ruPlural from '../../../helpers/ruPlural';

import styles from './reward.module.css';

const Reward = ({ points, need = 1000 }) => {
  if (!points) return null;

  return (
    <div className={styles.root}>
      <div className={styles.bg} style={{ backgroundImage: 'url(/images/game/reward_green_bg.svg)' }} />
      <div className={styles.content}>
        <div className={styles.title}>Ура! <br /> Вы заработали:</div>
        <div className={styles.energy}>
          <span><span className={styles.value}>+{points}</span>энергии </span>
          <img className={styles.icon} src="/images/game/energy_white.svg" alt="" />
        </div>
        <div className={styles.description}>
          {need-points <= 0 ?
            'Теперь вы можете перейти на следующий островок!'
            :
            `Тебе нужно ещё ${need-points} ${ruPlural(need-points, ['балл', 'балла', 'баллов'])}, чтобы перейти на следующий островок!`}</div>
      </div>
    </div>
  )
};

export default Reward;
