import { forwardRef } from 'react';
import Button from '../../Button';

import styles from './file-input.module.css';

const FileInput = forwardRef(({ id, name, onChange }, ref) => {
  return (
    <div className={styles.root}>
      <Button
        butStyle="primary"
        size="medium"
        fullWidth={true}
      >
        Загрузить фото / видео
      </Button>
      <input
        id={id}
        className={styles.input}
        type="file"
        name={name}
        accept="image/*,video/*"
        onChange={onChange}
        ref={ref}
      />
    </div>
  );
});

export default FileInput;
