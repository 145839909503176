import { useDispatch, useSelector } from 'react-redux';
import { MainLayout } from '../../components/Layouts';
import ReferalList from '../../components/ReferalList';
import Block from '../../components/Block';
import Modal from '../../components/Modal';
import useApiQuery from '../../hooks/useApiQuery';
import { selectItem } from '../../store/app/getters';
import { setItem } from '../../store/app/actions';
import * as api from '../../helpers/api';
import { useState } from 'react';

const InvitedUsers = () => {
  const dispatch = useDispatch();
  const [ isShowSuccess, setShowSuccess ] = useState(false);
  const user = useSelector(selectItem(['user']));

  const { data: refs } = useApiQuery({
    name: ['getMyReferals'],
    path: `/getmyreferals/${user.uid}/`,
    skip: !user
  });

  const onResendInvite = (uid) => {
    api
      .get(`/sendpushreferal/${uid}/`)
      .then(response => {
        const result = response.data.response;

        if (result.main && result.main.ok) {
          setShowSuccess(true);
          dispatch(setItem(['user', 'sendpushreferal', uid], true));
        } else {
          const error = response.data.error ? response.data.error : 'Неизвестная ошибка';
          dispatch(setItem(['notifications', 'sendpushreferal'], { type: 'error', title: 'Ошибка', content: error }));
        }
      })
      .catch(error => {
        dispatch(setItem(['notifications', 'request'], { type: 'error', content: 'Ошибка обработки запроса. Перезагрузите страницу и попробуйте позже' }));
      });
  }

  if (!user) return null;

  return (
    <MainLayout>
      <Block title="Мои приглашенные" headerAlign="center" showBackButton={true}>
        {refs &&
          <ReferalList active={refs.active} complete={refs.complete} sended={user.sendpushreferal} onResendInvite={onResendInvite} />
        }

        <Modal
          title="Успех"
          viewStyle="bottom"
          open={isShowSuccess}
          onClose={() => setShowSuccess(false)}
          buttons={[
            { text: 'Отлично', action: 'close', style: 'primary' }
          ]}
        >
          <div className="text-center">
            Напоминание успешно отправлено!
          </div>
        </Modal>
      </Block>
    </MainLayout>
  );
};

export default InvitedUsers;
