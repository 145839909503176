import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useApiQuery from '../../../../hooks/useApiQuery';
import { MainLayout } from '../../../../components/Layouts';
import Block from '../../../../components/Block';
import Switch from '../../../../components/fields/Switch';
import ButtonsContainer from '../../../../components/ButtonsContainer';
import Button from '../../../../components/Button';
import Modal from '../../../../components/Modal';
import { Ambitions } from '../../../../components/Skills';
// import InfoBlock from '../../../../components/InfoBlock';
import { setItem, deleteItem } from '../../../../store/app/actions';
import { selectItem } from '../../../../store/app/getters';
import * as api from '../../../../helpers/api';

import { ambitionsTabs } from '../../../../data/mock';

const AmbitionsPage = () => {
  const dispatch = useDispatch();
  const [ type, setType ] = useState(ambitionsTabs[0].value);
  const [ cancelSkillUid, setCancelSkillUid ] = useState(null);
  const user = useSelector(selectItem(['user']));
  const currentPlayer = user.current_player;
  const uid = currentPlayer?.uid_gamer;
  const ambitions = useSelector(selectItem(['user_ambitions', uid]));

  useApiQuery({
    name: ['user_ambitions', uid],
    path: `/getPlayerAmbitions/${uid}/`,
    skip: !uid,
    mapper: (data = {}, prev) => {
      let next = {};

      for (let key in data) {
        next[key] = (prev && prev[key]) ? {...prev[key]} : {};

        if (data[key] && data[key].length) {
          for (let ambKey in data[key]) {
            const amb = data[key][ambKey];
            next[key][amb.skill_uid] = amb;
          }
        }
      }

      return next;
    }
  });

  if (!user) return null;

  const onChangeTab = (e) => {
    setType(e.target.value);
  };

  const cancelAmbition = (cancelSkillUid) => {
    if (!cancelSkillUid) return null;

    const fd = new FormData();
    fd.append('player_uid', uid);
    fd.append('skill_uid', cancelSkillUid);

    api
      .post(`/cancelPlayerAmbition/`, fd)
      .then(response => {
        const result = response.data.response;
        if (response.data.success) {
          dispatch(deleteItem(['user_ambitions', uid, 'active'], result.skill_uid));
          dispatch(setItem(['notifications', 'cancelAmbition'], { type: 'success', content: response.data.success }));
        } else {
          const error = response.data.error ? response.data.error : 'Неизвестная ошибка';
          dispatch(setItem(['notifications', 'cancelAmbition'], { type: 'error', title: 'Ошибка', content: error }));
        }
      })
      .catch(error => {
        dispatch(setItem(['notifications', 'request'], { type: 'error', content: 'Ошибка обработки запроса. Перезагрузите страницу и попробуйте позже' }));
      });

    setCancelSkillUid(null);
  };

  return (
    <MainLayout>
      <Block
        title="Цели игрока"
        headerAlign="center"
        showBackButton={true}
        infoSlug="ambitions"
      >
        <ButtonsContainer>
          <Button
            fullWidth={true}
            butStyle="primary"
            to="/create-ambition"
          >
            Поставить новую цель
          </Button>
          <Switch
            id="type"
            name="type"
            options={ambitionsTabs}
            value={type}
            view="radio"
            onChange={(e) => onChangeTab(e)}
          />
        </ButtonsContainer>
      </Block>
      {/* <Block>
        <InfoBlock color="orange">
          Цели ставятся на навык, который вы желаете развить в ребенке. <br /><br />
          При выполнении квестов, направленных на развитие этого навыка, ребенок паралельно приобретает опыт в других навыках.
        </InfoBlock>
      </Block> */}
      {ambitions &&
        <Block>
          {type === 'active' ? (
            <>
              <Ambitions
                items={ambitions && Object.values(ambitions[type])}
                onClickCancel={(ambition) => setCancelSkillUid(ambition.skill_uid)}
                showDivider={true}
              />
              <Modal
                title="Отменить"
                viewStyle="bottom"
                open={!!cancelSkillUid}
                onClose={() => setCancelSkillUid(null)}
                buttons={[
                  { text: 'Подтвердить', action: 'click', style: 'primary', onClick: () => cancelAmbition(cancelSkillUid) },
                  { text: 'Закрыть', action: 'close', style: 'secondary' }
                ]}
              >
                Вы уверены, что хотите отменить цель?
              </Modal>
            </>
          ) : (
            <Ambitions items={ambitions && Object.values(ambitions[type])} showDivider={true} />
          )}
        </Block>
      }
    </MainLayout>
  );
};

export default AmbitionsPage;
