import { useRef, useEffect } from 'react';
import classnames from 'classnames';
import { scrollToX } from '../../helpers/scroll';
import Scores from './blocks/Scores';
import Grants from './blocks/Grants';
import Ambitions from './blocks/Ambitions';
import Rewards from './blocks/Rewards';

import styles from './balance.module.css';

const Balance = ({ user, score, ambitions, stipendion, size, rewards, showRewards }) => {
  const list = useRef(null);
  const loaded = !!ambitions && !!stipendion;

  useEffect(() => {
    if (list.current && loaded && (!Object.keys(ambitions).length || !Object.keys(stipendion).length)) {
      const childs = list.current.children;
      if (childs.length) {
        const left = childs[childs.length - 1].getBoundingClientRect().left;

        setTimeout(() => {
          scrollToX(list.current, left, 800);
          setTimeout(() => scrollToX(list.current, 0, 800), 1300);
        }, 1000);
      }
    }

    // eslint-disable-next-line
  }, [loaded]);

  return (
    <div className={classnames(styles.list, 'scroll-list', size && styles[size])} ref={list}>
      {score && <Scores score={score} />}
      {showRewards && <Rewards user={user} items={rewards} />}
      <Grants user={user} items={stipendion} />
      <Ambitions user={user} items={ambitions} />
    </div>
  );
};

export default Balance;
