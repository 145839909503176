import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { MainLayout } from '../../../../components/Layouts';
import Block from '../../../../components/Block';
import AvatarConstructor from '../../../../components/AvatarConstructor';
import useApiQuery from '../../../../hooks/useApiQuery';
import { setItem } from '../../../../store/app/actions';
import { selectItem } from '../../../../store/app/getters';
import * as api from '../../../../helpers/api';

const Store = () => {
  const user = useSelector(selectItem(['user']));
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const { data: constructor } = useApiQuery({
    name: ['avatar_structure', user],
    path: `/getavatarstructure/${user.gender}/`,
    skip: !user || !user.gender
  });

  const saveUserAvatar = (values_string) => {
    if (!values_string) {
      dispatch(setItem(['notifications', 'generateAvatar'], { type: 'error', content: 'Выберите компоненты' }));
      return null;
    }

    api
      .get(`/generateAvatar/${values_string}/${user.uid}/`)
      .then(response => {
        if (response.data.url) {
          dispatch(setItem(['user', 'avatar'], response.data.url));
          dispatch(setItem(['notifications', 'generateAvatar'], { type: 'success', content: 'Аватар обновлен' }));
          navigate('/update');
        } else {
          const error = response.data.error ? response.data.error : 'Неизвестная ошибка';
          dispatch(setItem(['notifications', 'generateAvatar'], { type: 'error', title: 'Ошибка', content: error }));
        }
      })
      .catch(error => {
        dispatch(setItem(['notifications', 'request'], { type: 'error', content: 'Ошибка обработки запроса. Перезагрузите страницу и попробуйте позже' }));
      });
  }

  if (!user || !constructor) return null;

  return (
    <MainLayout>
      <Block title="Магазин" headerAlign="center" showBackButton={true}>
        <AvatarConstructor user={user} constructor={constructor} onClickSave={(values_string) => saveUserAvatar(values_string)} />
      </Block>
    </MainLayout>
  );
};

export default Store;
