import classnames from 'classnames';
import { ReactSVG } from 'react-svg';
import { Link } from 'react-router-dom';
import Rating from '../../Rating';
import ruPlural from '../../../helpers/ruPlural';
import getDuration from '../../../helpers/getDuration';

import styles from './quest-item.module.css';

const QuestItemComplete = ({ quest, showRate }) => {
  const { uid, info, name, rating, start, end } = quest;

  const duration = (start && end) && new Date(end.replace(' ', 'T')).getTime() / 1000 - new Date(start.replace(' ', 'T')).getTime() / 1000;
  const durationText = duration ? getDuration(duration) : '0 минут';

  if (!uid) return (
    <div className={styles.quest}>
      <div className={classnames(styles.container, styles.complete, 'skeleton-loader')} />
    </div>
  );

  return (
    <Link to={`/user-quest/${uid}`} className={styles.block}>
      <div className={classnames(styles.container, styles.complete)}>
        <div className={styles.titleContainer}>
          <div className={styles.title}>{info ? info.name : name}</div>
        </div>
        <div className={styles.rating}>
          <Rating defaultValue={rating} disable={true} />
        </div>
        <div className={styles.row}>
          <div className={styles.label}>Пройдено за:</div>
          <div className={styles.value} dangerouslySetInnerHTML={{ __html: durationText }} />
        </div>
        <div className={styles.row}>
          <div className={styles.label}>Награда:</div>
          <div className={styles.value}>{quest.reward} {ruPlural(quest.reward, ['балл', 'балла', 'баллов'])}</div>
        </div>
      </div>
      {!rating && showRate &&
        <div className={classnames(styles.bottom)}>
          <div className={styles.bottomText}>
            <ReactSVG src='/images/icon-info-gray.svg' className={styles.info} />
            Нужно оценить квест!
          </div>
        </div>
      }
    </Link>
  );
};

export default QuestItemComplete;