import classnames from 'classnames';
import BalanceItem from '../BalanceItem';
import PointsProgress from '../../PointsProgress';

import styles from '../balance.module.css';

const Scores = ({ score }) => {
  return (
    <BalanceItem>
      <div className={classnames(styles.row, styles.title)}>
        Баланс <span className={styles.value}>{score.now || 0}</span>
      </div>
      <div className={styles.row}>
        На этой неделе <span className={styles.value}>{score.week || 0}</span>
      </div>
      <div className={styles.row}>
        На прошлой неделе <span className={styles.value}>{score.prevWeek || 0}</span>
      </div>

      <div className={styles.progress}>
        <div className={styles.progressTitle}>
          Лимит баллов в день
        </div>
        <PointsProgress current={score.day || 0} total={score.total || 100} />
      </div>
    </BalanceItem>
  );
};

export default Scores;
