import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { MainLayout } from '../../../components/Layouts';
import ButtonsContainer from '../../../components/ButtonsContainer';
import Button from '../../../components/Button';
import Block from '../../../components/Block';
import TariffPlayers from '../../../components/tariffs/TariffPlayers';
import Tariffs from '../../../components/tariffs/Tariffs';
import { selectItem } from '../../../store/app/getters';
import { setItem } from '../../../store/app/actions';
import * as api from '../../../helpers/api';

import styles from './Tariffs.module.css';

const SelectTariff = () => {
  const dispatch = useDispatch();
  let [searchParams, ] = useSearchParams();
  const playerFromGet = searchParams.get('player');

  const user = useSelector(selectItem(['user']));
  const tariff_types = useSelector(selectItem(['settings', 'tariff_types']));
  const current_tariff = useSelector(selectItem(['current_tariff']));

  const [ selectedTariff, setSelectedTariff ] = useState(null);
  const [ selectedUsers, setSelectedUsers ] = useState(null);

  useEffect(() => {
    if (playerFromGet && user.player) {
      setSelectedUsers([ playerFromGet ]);
    } else if (!selectedUsers && user.player) {
      const withTariff = user.player.filter(({ info }) => !!(info && info.tariff && info.tariff.type > 0));
      if (withTariff.length) setSelectedUsers(withTariff.map(({ uid_gamer }) => uid_gamer));
      else setSelectedUsers(user.player?.map(({ uid_gamer }) => uid_gamer));
    }
    // eslint-disable-next-line
  }, [playerFromGet, user]);

  // useEffect(() => {
  //   if (!selectedTariff && tariff_types) setSelectedTariff(tariff_types[0].month);
  //   // eslint-disable-next-line
  // }, [tariff_types]);

  const requestTariff = (user, selectedTariff, selectedUsers, placeOrder = false) => {
    const fd = new FormData();

    for (let player_uid of selectedUsers) {
      fd.append(`players[]`, player_uid);
    }
    fd.append('mentor_uid', user.uid);
    fd.append('month', selectedTariff);
    fd.append('create_order', placeOrder);

    api
      .post(`/tariffConstructor/`, fd)
      .then(response => {
        const result = response.data.response;
        if (result) {
          dispatch(setItem(['current_tariff'], result));
          if (placeOrder && result.pay_url) window.location.replace(result.pay_url) //result.create_order
        }

        if (response.data.error) {
          dispatch(setItem(['notifications', 'tariffConstructor'], { type: 'error', content: response.data.error }));
        }
      })
      .catch(error => {
        dispatch(setItem(['notifications', 'request'], { type: 'error', content: 'Ошибка обработки запроса. Перезагрузите страницу и попробуйте позже' }));
      });
  };

  useEffect(() => {
    if (!user || !selectedTariff || !selectedUsers) return null;
    requestTariff(user, selectedTariff, selectedUsers);
      // eslint-disable-next-line
  }, [selectedTariff, selectedUsers]);

  const handleChangePlayers = (selected) => {
    setSelectedUsers(selected);
  };

  const handleSelectTariff = (month) => {
    setSelectedTariff(month);
  };

  const handleClickSubmit = () => {
    requestTariff(user, selectedTariff, selectedUsers, true);
  };

  return (
    <MainLayout>
      <Block title="Выбор тарифа" headerAlign="center" showBackButton={true}>
        {(user.player && selectedUsers) &&
          <TariffPlayers
            players={user.player}
            selected={selectedUsers}
            onChange={handleChangePlayers}
          />
        }
      </Block>
      <Block>
        {tariff_types &&
          <Tariffs tariffs={tariff_types} players={selectedUsers} onSelect={handleSelectTariff} selected={selectedTariff} />
        }
        {(selectedUsers && selectedTariff && current_tariff && current_tariff.summ) &&
          <ButtonsContainer>
            <Button butStyle="primary" onClick={handleClickSubmit}>Оплатить {current_tariff.summ} ₽</Button>
          </ButtonsContainer>
        }
      </Block>
      {searchParams.get('from') === 'approve' &&
        <Block>
          <div className={styles.tariffMessage}>
            <div className={styles.tariffMessageText}>
              Невозможно подтвердить выполнение квеста! <br />
              У этого игрока закончился бесплатный пробный период.
            </div>
          </div>
        </Block>
      }
    </MainLayout>
  );
};

export default SelectTariff;
