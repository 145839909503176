import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { ReactSVG } from 'react-svg';
import classnames from 'classnames';
import { selectItem } from '../../store/app/getters';
import { deleteItem } from '../../store/app/actions';

import styles from './notifications.module.css';

const notification_timeout = 5;

const Notifications = () => {
  const dispatch = useDispatch();
  const [ old, setOld ] = useState([]);
  const notifications = useSelector(selectItem(['notifications']));

  const removeNotification = (id) => {
    const _notifications = {...notifications};

    if (_notifications[id]) {
      setTimeout(() => {
        dispatch(deleteItem(['notifications'], id));
      }, 300);
    }
  };

  useEffect(() => {
    if (!notifications) return null;

    const notNull = Object.keys(notifications).reduce((acc, key) => {
      if (!old.includes(key)) {
        setTimeout(() => {
          removeNotification(key);
        }, notification_timeout * 1000);
      }

      if (notifications[key]) acc.push(key);
      return acc;
    }, []);

    setOld(notNull);

    //eslint-disable-next-line
  }, [notifications]);

  if (!notifications) return null;

  return (
    <div className={styles.root}>
      <div className={classnames(styles.wrapper, 'container', !Object.keys(notifications).length && styles.empty)}>
        {Object.keys(notifications).map(key => {
          if (!notifications[key]) return null;
          const { type, title, content, remove } = notifications[key];

          return (
            <div className={classnames(styles.item, type && styles[type], remove && styles.remove)} key={key}>
              <div className={styles.content}>
                {title && <div className={styles.title}>{title}</div>}
                {content && content}
              </div>
              {/* <div className={styles.buttons}>
                <ReactSVG className={styles.close} onClick={() => removeNotification(key)} src="/images/icon-close.svg" />
              </div> */}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Notifications;