import { useState } from 'react';
import SkillBar from '../../Skills/SkillBar';

import styles from './quests.module.css';

const QuestSkills = ({ quest }) => {
  const [ selected, setSelected ] = useState(null);
  const { reward, reward_std, skills } = quest;

  return (
    <div className={styles.list}>
      <div className={styles.skills}>
        {Object.values(skills).map(({ uid, value, name, description }) => {
          if (!uid) return null;
          return (
            <div className={styles.skill} key={uid} onClick={() => setSelected(uid)}>
              {selected === uid ? (
                <div className={styles.skillTitle} style={{ color: name }}>{description}</div>
              ) : (
                <SkillBar value={value} max={reward_std ? reward_std : reward} color={name} />
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default QuestSkills;
