import { useState, useRef } from 'react';
import classnames from 'classnames';
import Slider from 'react-slick';
import { ReactSVG } from 'react-svg';
import { TailSpin } from 'react-loader-spinner';
import Modal from '../Modal';
import Video from '../Video';

import styles from './gallery.module.css';

const NextArrow = ({ className, style, onClick }) => (
  <ReactSVG
    src="/images/icon-arrow-right.svg"
    className={classnames(className, styles.arrow)}
    style={style}
    onClick={onClick}
  />
);

const PrevArrow = ({ className, style, onClick }) => (
  <ReactSVG
    src="/images/icon-arrow-left.svg"
    className={classnames(className, styles.arrow)}
    style={style}
    onClick={onClick}
  />
);

const Slide = ({ index, is_video, link, enableOnclick, handleMouseDown, handleMouseMove, handleMouseUp }) => {
  const [ isLoad, setIsLoad ] = useState(false);

  return (
    <div
      className     = {classnames(styles.item, 'slide-item')}
      onMouseDown   = {() => {enableOnclick && handleMouseDown(index)}}
      onMouseMove   = {() => {enableOnclick && handleMouseMove()}}
      onMouseUp     = {() => {enableOnclick && handleMouseUp()}}
      // onTouchStart  = {() => {enableOnclick && handleMouseDown(index)}}
      // onTouchMove   = {() => {enableOnclick && handleMouseMove()}}
      // onTouchCancel = {() => {enableOnclick && handleMouseMove()}}
      // onTouchEnd    = {() => {enableOnclick && handleMouseUp()}}
      key = {link}
    >
      {!isLoad &&
        <div className={styles.loader}>
          <TailSpin color="var(--brand-color)" height="64" width="64" />
        </div>
      }
      {is_video ? (
        <Video src={link} controls={!enableOnclick} onReady={() => setIsLoad(true)} />
      ) : (
        <img src={link} alt="" onLoad={() => setIsLoad(true)} />
      )}
    </div>
  );
};

const Gallery = ({ items }) => {
  const settings = {
    dots: false,
    arrows: true,
    infinite: false,
    lazyLoad: 'progressive',
    lazyIndicator: <TailSpin color="var(--brand-color)" height="64" width="64" />,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    adaptiveHeight: true
  };

  const [ isOpenPreview, setOpenPreview ] = useState(null);
  const [ clickedIndex, setClickedIndex ] = useState(null);
  const slider = useRef(null);

  const handleMouseDown = (index) => { setClickedIndex(index); };
  const handleMouseUp = () => {
    if (clickedIndex || clickedIndex === 0) {
      setOpenPreview(true);

      setTimeout(() => {
        slider.current.slickGoTo(clickedIndex);
        setClickedIndex(null);
      }, 0);
    }
  };
  const handleMouseMove = () => { setClickedIndex(null); };

  const renderSlides = (enableOnclick) => (
    items.map(({ is_video, link }, index) =>
      <Slide
        index={index}
        link={link}
        is_video={is_video}
        enableOnclick={enableOnclick}
        handleMouseDown={handleMouseDown}
        handleMouseMove={handleMouseMove}
        handleMouseUp={handleMouseUp}
        key={index}
      />
    )
  );

  return (
    <div className={styles.root}>
      <div className={styles.scroller}>
        {renderSlides(true)}
      </div>
      <Modal viewStyle="no-overflow" open={isOpenPreview} onClose={() => setOpenPreview(false)} showClose={true}>
        <Slider {...settings} ref={slider}>
          {renderSlides(false)}
        </Slider>
      </Modal>
    </div>
  );
};

export default Gallery;