import classnames from 'classnames';
import BackButton from './BackButton';
import InfoButton from './InfoButton';

import styles from './block.module.css';

const Block = ({ title, children, headerAlign, align, color, showBackButton, backButtonUrl,
  rightButton, infoSlug, style }) => {

  const showAllButtons = (headerAlign === 'center');

  if (!title && !children) return null;

  return (
    <div className={classnames(styles.root, color && styles[color])} style={style}>
      {(title || showBackButton || rightButton || infoSlug) && (
        <div className={classnames(styles.header, headerAlign && styles[headerAlign])}>
          {(showBackButton || showAllButtons) &&
            <span className={styles.leftButton}>
              {showBackButton && <BackButton returnUrl={backButtonUrl} />}
            </span>
          }
          <h2 className={styles.title}>
            {title && title}
          </h2>
          {(rightButton || infoSlug || showAllButtons) &&
            <span className={styles.rightButton}>
              {rightButton && rightButton}
              {infoSlug && <InfoButton slug={infoSlug} />}
            </span>
          }
        </div>
      )}
      {children && <div className={classnames(styles.content, align && styles[align])}> {children} </div>}
    </div>
  );
};

export default Block;